import { Component } from 'react';
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import _ from 'lodash';

// Assets
import Theme from '../../Builder/Library/Text/Rich/Theme.js';

// Components
import Text from './utilities/Text';
import DraftText from './DraftText';

// Helpers
import DeltaRenderer from '../../../helpers/delta_renderer';

// Styled Components
const Wrapper = styled.div`
  ${ (props) => props.inline ? css` p { margin: 0; } ` : ( props.singular ? css`` : css` margin: 0.96em 0; ` ) }

  .ql-container {
    background-color: transparent !important;
    border: none !important;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ql-editor {
    font-size: inherit !important;
    line-height: inherit !important;
    max-height: none;
    overflow-y: visible !important;
    padding: 0 !important;
  }

  .ql-editor > * {
    cursor: default;
  }
`;

const isDeltaEmpty = (delta) => typeof(delta) === 'undefined' ? true : delta?.ops?.reduce((empty, op) => (
  ( _.isString(op?.insert) && op.insert.trim().replace('↵', '').replace('\n', '').replace('\\n', '') != '' ) ||
  ( !_.isString(op?.insert) && !op?.insert?.breaker )
    ? false : empty
), true);

class RichText extends Component {
  constructor(props) {
    super(props);
    this.delta = this.delta.bind(this);
    this.draft = this.draft.bind(this);
    this.html = this.html.bind(this);
  }

  get content() { return this.props.content; }
  get inline() { return this.props?.inline; }
  get singular() { return this.props?.singular; }

  delta(content) {
    if (!_.isPlainObject(content)) return false;
    if (isDeltaEmpty(content)) return <></>;
    return <div css={ Theme.base } >
      <Wrapper singular={ this.singular } inline={ this.inline } >
        <Text content={ new DeltaRenderer(content).render() } />
      </Wrapper>
    </div>;
  }

  draft(content) {
    try {
      const parsedContent = JSON.parse(content);
      if (_.isPlainObject(parsedContent)) { return <DraftText content={ content } inline={ this.inline } />; }
    } catch(e) { }
    return false;
  }

  html(content) {
    return (
      <Wrapper singular={ this.singular } inline={ this.inline } css={ Theme.base } >
        <Text content={ (content || '').match(/<body[^>]*?>([\s\S]*)<\/body>/)?.[1] || content || '' } />
      </Wrapper>
    );
  }

  render() {
    return _.reduce(
      [ this.delta, this.draft, this.html ],
      (result, format) => {
        if (result) return result;
        return format(this.content);
      },
    false) || null;
  }
}

export default RichText;

import { ui, request } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import _ from 'lodash';
import qs from 'qs';

import { getReactSelectOptions, getOptions, getParentReactSelectOption } from '../../../helpers/react-select';
import Select, { components } from 'react-select';

import ChevronDown from './Images/ChevronDown.png';

const Wrapper = styled.div`
  display: flex;
  ${
    ({ mode }) => ({
      [Choices.Mode.Vertical]: css` flex-direction: column; `,
      [Choices.Mode.Horizontal]: css` flex-direction: row; `
    }[mode])
  }
`;

const Group = styled.ul`
  cursor: default;
  list-style: none;
  margin-top: 0px;
  margin: 0;
  padding-left: 0px;

  label {
    display: grid;
    position: relative;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
`;

const Checkboxes = ({ name, value={}, onChange, children, subchecks, isDisabled }) => {
  const anyChildren = _.some(children, (value) => value.children);
  return <Group>
    { _.map(children, (option) => {
      let { label, value: key, disabled } = option;
      const field = `${ name }[${ key }]`;

      const contains = option.children;

      if (isDisabled) disabled = true;

      const checkbox = <UI.Checkbox
        key={ field }
        name={ field }
        value={ _.get(value, key) }
        onChange={ (checked) => onChange(_.set(value, key, checked)) }
        label={ label }
      />;

      return <li css={ [
        ( ( anyChildren || subchecks ) && !contains ) && css` margin-left: 24px; `,
        css` margin-bottom: 16px; `
      ] } >
        {
          contains
            ? <UI.Dropdown.Small title={ checkbox } >
                <div css={ css` margin-left: 32px; ` } >
                  <Choices
                    UI={ UI }
                    name={ key }
                    contents={ contains }
                    multiple
                    mode={ Choices.Mode.Vertical }
                    format={ Choices.Format.Object }
                    subchecks
                    value={ _.get(value, key) }
                    onChange={ (change) => {
                      const object = _.merge({}, _.get(value, key), change);
                      onChange(_.set(value, key, _.isEmpty(object) ? false : object));
                    } }
                  />
                </div>
              </UI.Dropdown.Small>
            : checkbox
        }
      </li>;
    }) }
  </Group>;
};

export const Radios = ({ name, value, onChange, mode, children, isDisabled }) => (
  <Group css={ css` margin-bottom: 0; ` }>
    { _.map(children, (option) => {
      let { label, value: key, disabled } = option;
      const field = `${ name }[${ key }]`;

      if (isDisabled) disabled = true;

      return <li key={ key } css={ css`
        ${
          {
            [Choices.Mode.Vertical]: css` padding-bottom: 0px; &:last-child { padding-bottom: 0px; } `,
            [Choices.Mode.Horizontal]: css` padding-right: 16px; `
          }[mode]
        }

        ${ disabled && css` opacity: 0.5; ` }

        align-items: first baseline;
        display: flex;

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #D1D1D1;
          border-radius: 10px;
          flex-shrink: 0;
          height: 20px;
          margin-left: 0px;
          margin-right: 10px;
          width: 20px;

          &:checked {
            background: radial-gradient(circle at center, ${Theme.colors.brand} 40%, #FFFFFF 40%, #FFFFFF 60%);
            border-color: ${Theme.colors.brand};
            height: 20px;
            width: 20px;
          }
        }

        label {
          position: relative;
          top: -4px;
        }

      ` }>
        <input
          id={ field }
          type="radio"
          name={ name }
          key={ field }
          value={ key }
          checked={ value == key }
          disabled={ disabled }
          title={ label }
          onChange={ () => onChange(key) }
        />
        <label htmlFor={ field } dangerouslySetInnerHTML={{ __html: label }}></label>
      </li>
    }) }
  </Group>
);

const MultiValueGroupComponent = (props) => {
  const parent = getParentReactSelectOption(props.selectProps.options, props.data);
  return <div className={ props.innerProps.className } >
    { parent && <strong>{ parent.label }: </strong> }{ props.data.label }
  </div>;
};

const Option = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data
  } = props;
  return (
    <div
      ref={ innerRef }
      css={ getStyles('option', props) }
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
      { ...innerProps }
      title={ data?.title }
    >
      { children }
    </div>
  );
};

const formatValues = (value, content) => {
  const entries = content.map(c => {
    let result;

    if (c.children) {
      result = formatValues(value, c.children);
    } else {
      if (typeof value === 'string' || Array.isArray(value)) {
        result = Array.isArray(value) ? value.includes(c.value) : value === c.value;
      } else {
        result = false;
      }
    }

    return [c.value, result];
  });

  return Object.fromEntries(entries);
}

const extractValues = (content, includeParent) => {
  let response = [];
  Object.keys(content).map(key => {
    if (_.isPlainObject(content[key])) {
      const vals = extractValues(content[key])
      if (vals.length > 0) {
        if(includeParent)//This flag is true for selecting attributes in settings
        {
          response = response.concat([...vals, key])
        }
        else{ //This flag is false for filtering the results
          response = response.concat(vals)
        }
      }
    } else {
      content[key] ? response.push(key) : null
    }
  })
  return response;
}

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img
      alt=""
      src={ChevronDown}
      css={css`
        margin: auto;
        width: 17px;
      `}
    />
  </components.DropdownIndicator>
);

export const SelectDropDown = ({
  name,
  value,
  onChange,
  options,
  disabled,
  placeholder,
  multiple,
  validated,
}) => (
  <Select
    key={name}
    isMulti={multiple}
    isClearable={multiple}
    isDisabled={disabled}
    placeholder={placeholder ? placeholder : undefined}
    styles={{
      control: (provided, state) => {
        let borderColor = state.isFocused ? Theme.colors.brand : "#585858";
        if (!validated) borderColor = "#940420";

        return ({
          ...provided,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `${borderColor} !important`,
          boxShadow: 'none',
          borderRadius: 0
        })
      },
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '10px 8px 9px',
        color: '#585858',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        width: 48,
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        marginTop: 0,
        borderWidth: 0,
        boxShadow: 'none !important',
        filter: 'drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.2))',
        zIndex: 9999999,
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        opacity: state.isDisabled ? 0.5 : 1,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        padding: '12px 11px',
        backgroundColor: state.isSelected ? '#E6E6E6' : '#FFF',
        transition: 'all 0.2s',
        ':hover': {
          backgroundColor: '#f0f0f0',
        },
      }),
      noOptionsMessage: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        padding: '14px 12px',
      }),
    }}
    components={{
      Option,
      MultiValueLabel: MultiValueGroupComponent,
      DropdownIndicator,
    }}
    value={value}
    onChange={onChange}
    options={options}
    isOptionDisabled={option => option.disabled}
  />
);

const arrayUnwrap = (value) => {
  if (typeof value === 'undefined') {
    return value;
  }
  if (value instanceof Array) {
    return value[0];
  }
  return value;
}

const Choices = (props) => {
  const {
    UI, name, onChange, value,
    multiple=false, validated=true, disabled=false,
    placeholder=false, autoDropdown=4, subchecks=false,
    responseHandler=null,
    query={}
  } = props;

  let { contents, mode=undefined, format=undefined, includeParent= true } = props;

  if (_.isFunction(contents)) {
    contents = contents();
  }

  if (_.isEmpty(contents)) contents = {};

  if (_.isString(contents)) {
    let [url, subquery = {}] = contents.split('?')
    subquery = qs.stringify(
      _.merge(qs.parse(subquery, { arrayFormat: 'brackets' }), query || {}), { arrayFormat: 'brackets' }
    )
    url = [url, subquery].filter(_.identity).join('?')
    return <Subschema>{[
      ui`Choices`(_.omit(props, ['Board', 'Contents', 'Subschema', 'UI', 'contents'])),
      request(url)
        .during({ disabled: true, placeholder: 'Loading options...' })
        .failure((error) => ({ disabled: true, placeholder: 'Error loading! Please try again' }))
        .success(({ data }) => ({ contents: responseHandler ? responseHandler(data) : data }))
    ]}</Subschema>;
  }

  if (typeof mode === 'undefined' && autoDropdown && Object.keys(contents).length >= autoDropdown) mode = Choices.Mode.Dropdown;
  if (typeof mode === 'undefined') mode = Choices.Mode.Vertical;
  if (typeof format === 'undefined') format = Choices.Format.Array

  contents = getOptions(contents)

  const scan = (key, options) => _.reduce(options, (result, option) => {
    if (result) return result;
    if (option.value == key) return option;
    if (option.children) return scan(key, option.children);
    return result;
  }, false);

  // eslint-disable-next-line default-case
  switch (mode) {
    case Choices.Mode.Vertical:
    case Choices.Mode.Horizontal:
      const formattedValue = format === Choices.Format.Object ? value : formatValues(value||[], contents)
      const formattedOnChange = format === Choices.Format.Object ? onChange : (newValue) => onChange(extractValues(newValue, includeParent))
      if (multiple && !value) formattedOnChange(formatValues([], contents));
      return <Wrapper mode={ mode } key={ name } >{
        multiple
          ? <Checkboxes name={ name } value={ formattedValue } onChange={ formattedOnChange } isDisabled={ disabled } subchecks={ subchecks } >{ contents }</Checkboxes>
          : <Radios name={ name } value={ value } onChange={ onChange } mode={ mode } isDisabled={ disabled } >{ contents }</Radios>
      }</Wrapper>;
    case Choices.Mode.Dropdown:
      if (format === Choices.Format.Object) {
        console.error("Object format not valid (yet) for dropdowns")
      }
      return (
        <SelectDropDown
          name={name}
          value={
            multiple
              ? (Array.wrap(value) || [])?.map(key => scan(key, contents))
              : scan(arrayUnwrap(value), contents)
          }
          onChange={choice =>
            onChange(
              multiple ? choice.map(option => option.value) : choice.value
            )
          }
          options={getReactSelectOptions(contents)}
          disabled={disabled}
          placeholder={placeholder}
          multiple={multiple}
          validated={validated}
        />
      );
  }

  return null;
};

Choices.Mode = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
  Dropdown: 'dropdown'
};

Choices.Format = {
  Object: 'object',
  Array: 'array'
}

export default Choices;

import { ui, transform, select } from '@owenscorning/pcb.alpha';
import Modular from './Modular';
import _ from 'lodash';

const Settings = {
  details: ui`Form`.of({
    breadcrumbs: ui`Switch`({ label: 'Show Breadcrumbs', default: true }),
  })({
    label: transform`~definition`(
      definition => `${definition.displayName || definition.type} Details`
    ),
  }),
  meta: ui`Meta`({
    label: transform`~definition`(
      definition => `${definition.displayName || definition.type} Metadata`
    ),
  }),
  open_graph_settings: ui`OpenGraph`({
    label: 'Open Graph Settings'
  }),
}

const Page = Modular('Page', 'Page', {
  displayName: 'Page',
  layout: { Header: ['Hero', 'StickyNav'] },
  list: UI => ({
    'layout/Header': Modular.Layout.List(UI, 'Header', ['Hero', 'StickyNav']),
    sections: ui`List`.of({ modules: Modular.Module.List(UI) })({
      title: 'details/name',
      singular: 'Section',
      actions: [
        {
          label: 'Settings',
          icon: 'cogs',
          onClick: Modular.Section,
        },
      ],
      startOpened: select`~lastOpenedSection`,
    }),
  }),
  settings: Settings,
  //read migration
  read: ({ contents, metadata, ...rest }) => {
    if (metadata?.version) return { contents, metadata, ...rest };

    if (_.isEmpty(contents)) {
      contents = [];
    }
    // we may have to move this later, but after PAR-516 first golive, we may have items saved in localStorage
    // that were saved directly, but we are trying to reload through the "read" method
    if (!Array.isArray(contents)) {
      return { contents, metadata };
    }

    // get rid of uuids
    const newMeta = _.cloneDeep(metadata) || {};
    newMeta.layouts = newMeta.layouts || {};
    newMeta.layouts.Header = newMeta.layouts.Header || {};
    newMeta.layouts.Header.modules = newMeta.layouts.Header.modules || {};
    if (newMeta.layouts.Header.modules.Hero) {
      delete newMeta.layouts.Header.modules.Hero.__uuid;
    }
    if (newMeta.layouts.Header.modules.StickyNav) {
      delete newMeta.layouts.Header.modules.StickyNav.__uuid;
      // figure out if sticky should be shown or not
      // if it's explicitly set, leave it
      // if undefined, it should be shown if any content, else hidden
      if (
        typeof newMeta.layouts.Header.modules.StickyNav.__hidden === 'undefined'
      ) {
        const shownUrl =
          (newMeta.layouts.Header.modules.StickyNav.cta?.url || '#') !== '#';
        const ctaIsModal =
          (newMeta.layouts.Header.modules.StickyNav.cta?.destination ||
            'link') === 'modal';
        const shownSections =
          (contents || []).filter(
            section => section?.sticky?.name && section?.sticky?.show
          ).length > 0;
        newMeta.layouts.Header.modules.StickyNav.__hidden = !(
          shownUrl ||
          ctaIsModal ||
          shownSections
        );
      }
    } else {
      newMeta.layouts.Header.modules.StickyNav = { __hidden: true };
    }

    return {
      contents: {
        layout: { Header: newMeta?.layouts?.Header?.modules },
        sections: (contents || []).map(section => ({
          ..._.omit(section, ['children', '__uuid']),
          modules: (section.children || []).map(module => ({
            type: module.component,
            __hidden: module.__hidden,
            __visibility: module.__visibility,
            data: _.omit(module, [
              'component',
              '__uuid',
              '__hidden',
              '__visibility',
            ]),
          })),
        })),
      },
      metadata: {
        ..._.omit(newMeta, 'layouts'),
      },
    };
  },
  //write migration
  //keep writing uuids until after we deploy
  write: ({ contents, metadata }) => {
    return metadata?.version
      ? { contents, metadata }
      : {
          contents: (contents.sections || []).map((section, sectionIndex) => ({
            ..._.omit(section, 'modules'),
            __uuid: `sections/${sectionIndex}`,
            component: 'Section',
            children: (section.modules || []).map((module, index) => ({
              component: module.type,
              ...module.data,
              ..._.omit(module, ['type', 'data']),
              __uuid: `sections/${sectionIndex}/modules/${index}`,
            })),
          })),
          metadata: {
            layouts: {
              Header: {
                modules: _.mapValues(
                  contents?.layout?.Header,
                  (module, name) => ({
                    ...module,
                    __uuid: `layout/Header/${name}`,
                  })
                ),
              },
            },
            ...metadata,
          },
        };
  },
  modals: (UI) => ({
    module: {
      body: ui`Tabs`.of(
        _.mapValues(UI.Modules.Categories.Page, (category) => ({
          label: category,
          pane: ui`Grid`.of(
            _.mapValues(
              _.pickBy(_.omit(UI.Modules.Page, 'Thumbnails'), (module) => {
                return module.category
                  ? _.get(UI, module.category.path.split('/')) == category
                  : category == UI.Modules.Categories.Page.Customizable
              }),
              (module, type) => ui`Thumbnail`({
                image: module.thumbnail,
                subtitle: module.displayName,
                onClick: () => {
                  Board.modal.reference({ type, data: Board.Evaluate(module.modes.edit) });
                  Board.modal.close();
                }
              })
            )
          )
        }))
      )
    }
  })
});

Page.Settings = Settings;

export default Page;

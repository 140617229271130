import { ui, s, when, select, transform } from '@owenscorning/pcb.alpha';
import { CloudinaryDefaultConfig } from '../../../../helpers/cloudfront_helper';
import { Cloudinary } from 'cloudinary-core';
import Content from './Content';
import styled from "@emotion/styled";
import { unwrapRef } from '../../../../data';

const getAltMeta = () => ({
  en: ui`Text`({
    label: 'English Alt Text',
    placeholder: 'alt text e.g. TruDefinition Duration Amber shingles ha...',
  }),
  es: ui`Text`({
    label: 'Spanish Alt Text',
  }),
  fr: ui`Text`({
    label: 'French Alt Text',
  }),
})

const ShingleColor = Content(
  'ShingleColor',
  (UI) => ({
    version: '0.2',
    isGlobal: true,
    displayName: 'Shingle Color',
    paths: ['roofing/shingles'],
    read: ({contents, metadata}) => {
      let { masterImage, transform, shingleImages } = contents;

      if (!shingleImages) {
        shingleImages = {
          masterSwatch: {
            image: masterImage,
            crops: {
              primarySquare: {
                gravity: transform?.swatch_768x768?.crop?.gravity,
                height: transform?.swatch_768x768?.crop?.height,
                width: transform?.swatch_768x768?.crop?.width,
                x: transform?.swatch_768x768?.crop?.x,
                y: transform?.swatch_768x768?.crop?.y,
              },
              rectangular: {
                gravity: transform?.swatch_768x485?.crop?.gravity,
                height: transform?.swatch_768x485?.crop?.height,
                width: transform?.swatch_768x485?.crop?.width,
                x: transform?.swatch_768x485?.crop?.x,
                y: transform?.swatch_768x485?.crop?.y,
              }
            }
          }
        };
      }

      return {
        contents: {
          shingleImages
        },
        metadata
      };
    },
    write: ({contents, metadata}) => {
      return {
        contents,
        metadata
      };
    },
    sidebar: {
      builder: ui`Form`.of({
        shingleImages: ui`Form`.of({
          masterSwatch: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            }),
            [s._]: ui`Tip`.of('Swatch should be 6300x7400 pixels and is equivalent to a physical dimension of roughly 57.4” x 67.5” (roughly 12 courses of shingles for Duration / Oakridge).'),
            alt: ui`Form`.of(getAltMeta()),
            crops: ui`Form`.of({
              primarySquare: ui`List/Item`.of({
                [s._]: ui`Tip`.of('This is the main swatch used on owenscorning.com. This crop should show 6 “courses” (rows) of shingles for architectural shingles (Duration, Oakridge, etc.). This is used to create the “768x768” and “160x160” swatches in the API.'),
                crop: ui`Cropper`({
                  image: select`../../../image`,
                  startAspectLocked: true,
                  default: { x: 0, y: 0, width: 3700, height: 3700 }
                })
              })({
                title: 'Primary Square Swatch<br/>(6 Course)',
                unwrapped: true,
                standalone: true,
                visible: when`../../image`.is.present.then(true).otherwise(false),
              }),
              rectangular: ui`List/Item`.of({
                [s._]: ui`Tip`.of('This is another highly used swatch for owenscorning.com. Ideally this should be a “center cut” of the Primary Square Swatch (e.g. the middle 4 courses for Duration/Oakridge). This is used to create the 768x485 swatch.'),
                crop: ui`Cropper`({
                  image: select`../../../image`,
                  startAspectLocked: true,
                  default: { x: 0, y: 617, width: 3700, height: 2466 }
                })
              })({
                title: 'Rectangular Swatch<br/>(4 Course)',
                unwrapped: true,
                standalone: true,
                visible: when`../../image`.is.present.then(true).otherwise(false),
              }),
              detail: ui`List/Item`.of({
                [s._]: ui`Tip`.of('This swatch is currently used for homecenters and shows significant detail of our shingle granules. This crop should show 5 “courses” (rows) of shingles for architectural shingles (Duration, Oakridge, etc.). This is used to create the “3000x3000” or “3k” swatch.'),
                crop: ui`Cropper`({
                  image: select`../../../image`,
                  startAspectLocked: true,
                  default: { x: 0, y: 0, width: 3083, height: 3083 }
                })
              })({
                title: 'Detail Square Swatch<br/>(5 Course)',
                unwrapped: true,
                standalone: true,
                visible: when`../../image`.is.present.then(true).otherwise(false),
              }),
            })({
              label: 'Crops'
            })
          })({
            title: 'Master Swatch',
            unwrapped: true,
            standalone: true,
          }),
          tileableSwatch: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            }),
            [s._]: ui`Tip`.of('Swatch should be 6300x7400 pixels and is This is approximately 24 “courses” of shingle (for Duration/Oakridge) and is tileable'),
          })({
            title: 'Tileable Swatch<br/>(includes Landscape Swatch)',
            unwrapped: true,
            standalone: true,
          }),
          houseWithSky: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            }),
            alt: ui`Form`.of(getAltMeta()),
          })({
            title: 'House Image (With Sky)',
            unwrapped: true,
            standalone: true,
          }),
          houseNoSky: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            }),
          })({
            title: 'House Image (No Sky)',
            unwrapped: true,
            standalone: true,
          }),
          galleryImages: ui`List`.of(ui`Form`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            }),
            crop: ui`Cropper`({
              image: select`../image`,
              startAspectLocked: true,
              default: { x: 0, y: 0, width: 1438, height: 900 }
            }),
            alt: ui`Form`.of(getAltMeta()),
          }))({
            label: 'Gallery Images',
            hideable: false,
          }),
          alternateHouseImages: ui`List/Item`.of({
            ranch_house_image: ui`Form`.of({
              image: ui`Upload/Image`({
                label: 'Ranch House Image',
                maximum: 100
              }),
              alt: ui`Form`.of(getAltMeta()),
            })({
              label: 'Ranch House Image'
            }),
            two_story_house_v1_image: ui`Form`.of({
              image: ui`Upload/Image`({
                label: '2 Story v1 Image',
                maximum: 100
              }),
              alt: ui`Form`.of(getAltMeta()),
            })({
              label: '2 Story House Image (v1)'
            }),
            two_story_house_v2_image: ui`Form`.of({
              image: ui`Upload/Image`({
                label: '2 Story v2 Image',
                maximum: 100
              }),
              alt: ui`Form`.of(getAltMeta()),
            })({
              label: '2 Story House Image (v2)'
            }),
          })({
            title: 'Alternate House Images<br/>(For Shingle Widget)',
            unwrapped: true,
            standalone: true,
          }),
          // BYR 1.0 images (new model is not compatible with old model's images)
          byrShingle: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            })
          })({
            title: 'Build Your Roof® Shingle Texture',
            unwrapped: true,
            standalone: true,
          }),
          byrHipRidge: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            })
          })({
            title: 'Build Your Roof® Hip & Ridge Texture',
            unwrapped: true,
            standalone: true,
          }),
          // BYR 2.0 images
          byr2Shingle: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            })
          })({
            title: 'Build Your Roof® (2.0) Shingle Texture',
            unwrapped: true,
            standalone: true,
          }),
          byr2HipRidge: ui`List/Item`.of({
            image: ui`Upload/Image`({
              label: 'Image',
              maximum: 100
            })
          })({
            title: 'Build Your Roof® (2.0) Hip & Ridge Texture',
            unwrapped: true,
            standalone: true,
          }),
        })({
          label: 'Shingle Images',
          unwrapped: true,
          standalone: true,
        })
      }),
      settings: {
        general: ui`Form`.of({
          product_swatch_id: ui`Text`({
            label: 'Product Swatch ID',
            placeholder: 'e.g. RFG-400001',
          }),
          [s._]: ui`Tip`.of('Optional.  Used to be RFG-{sapcode}.  We had kept it as a unique id for older DEQ tools.'),
          abc_item_sku: ui`Text`({
            label: 'ABC Supply Item SKU',
            placeholder: 'e.g. 02OCTWHPAS',
          }),
          [s._]: ui`Tip`.of('Optional.  ABC Supply was given our API and this field helped them map product to their system.'),
          description_en: ui`Text`({
            label: 'Description (English)',
          }),
          description_es: ui`Text`({
            label: 'Description (Spanish)',
          }),
          description_fr: ui`Text`({
            label: 'Description (French)',
          }),
        })({
          label: 'General'
        }),
        attributes: ui`Form`.of({
          colors: ui`Choices`.of({
            black: 'Blacks',
            blue: 'Blues',
            brown: 'Browns',
            gray: 'Grays',
            green: 'Greens',
            red: 'Reds',
            white: 'Whites',
            purple: 'Purples',
          })({
            label: 'Colors',
            multiple: true
          }),
          scoty: ui`Choices`.of(`${MDMS_API_CDN_HOST_AND_PROTOCOL}/api/v2/cms/sites/${PB_SITE}/published`)({
            responseHandler: data => {
              return data?.data?.[0]?.contents?.items || []
            },
            query: {
              filter: {
                type: 'Cms::Content::Attribute',
                language_iso_code: select`~build/language`,
                name: 'Shingle Color of the Year (SCOTY) Badges',
                route: '/roofing',
                published: true,
              },
              fields: {
                '*': 'contents'
              },
              page: {
                number: 1,
                size: 1,
              }
            },
            label: 'Shingle Color of the Year',
            mode: ui`Choices/Mode/Dropdown`
          }),
          coolcolor: ui`List/Item`.of(ui`Form`.of({
            value: ui`Switch`({ label: '"Cool" Color?' })
          }))({
            label: 'Cool Color',
            hideable: true,
            unwrapped: true,
            standalone: true,
          }),
          sri: ui`List/Item`.of(ui`Form`.of({
            value: ui`Number`({ label: 'SRI' })
          }))({
            label: 'SRI',
            hideable: true,
            unwrapped: true,
            standalone: true,
          }),
          newcolor: ui`List/Item`.of(ui`Form`.of({
            value: ui`Switch`({ label: 'New Color?' })
          }))({
            label: 'New Color',
            hideable: true,
            unwrapped: true,
            standalone: true,
          }),
          premium_color_blend: ui`List/Item`.of(ui`Form`.of({
            value: ui`Switch`({ label: 'Premium Color Blend?' })
          }))({
            label: 'Premium Color Blend',
            hideable: true,
            unwrapped: true,
            standalone: true,
          }),
        })({
          label: 'Attributes'
        }),
      }
    },
    view: ShingleColor.Renderer(UI),
  })
)

const getTransformedUrl = (cloudinary, path, transforms) => (
  cloudinary?.url(path, { transformation: transforms, type: 'upload' })
)

const PREVIEW_TRANSFORMS = [
  {
    crop: 'scale',
    width: 400
  }
]

const QUALITY_TRANSFORMS = [
  {
    quality: 'auto',
    fetchFormat: 'auto'
  }
]

const getUrl = (rawUrl, transforms) => {
  //console.log('getUrl', {rawUrl,transforms})
  const [root, path] = rawUrl?.split('/image/upload/') || []
  const [cloudname, unused] = root?.split('/').reverse() || []

  if (transforms) {
    const cloudinary = cloudname ? new Cloudinary({
      ...CloudinaryDefaultConfig,
      cloud_name: cloudname,
    }) : null;
    return getTransformedUrl(cloudinary, path, transforms.concat(QUALITY_TRANSFORMS))
  }

  return [root, 'image/upload', path].join('/')
}

const Disclaimer = styled.div`
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 16px;
`

const UrlBlock = styled.div`
  padding-bottom: 32px;
`

const Url = ({ label, url, noPreview, transforms = [] }) => {
  //console.log('Url', { label, url, transforms })
  const fullResolutionUrl = getUrl(url, transforms)
  return (
    url ?
      <UrlBlock>
        <img
          onClick={ () => window.open(fullResolutionUrl, '_blank') }
          style={{ cursor: 'pointer', display: 'block', paddingBottom: '4px' }}
          src={ getUrl(url, transforms.concat(noPreview ? [] : PREVIEW_TRANSFORMS)) }
          alt={ label } />
        <Disclaimer>Note, this is a scaled preview. Click to view full size.</Disclaimer>
        <strong>{ label }: </strong><br/>
        <a href={ fullResolutionUrl } target="_blank">{ fullResolutionUrl }</a>
      </UrlBlock> : null
  )
}

const Swatch = ({ label, h2, h4, url, transforms = [], urls = [], noTransforms }) => {
  return noTransforms || transforms.length > 0 ? (
    <div>
      { label && (h2 ? <h2>{ label }</h2> : (h4 ? <h4>{ label }</h4> : <h3>{ label }</h3>)) }
      { url &&
          <Url
            url={ url }
            label={ label }
            transforms={ transforms }
          />
      }
      {
        urls.map(other =>
          <Url
            url={ url }
            label={ `${ label } ${ other.label }` }
            transforms={ transforms.concat(other.transforms) }
            noPreview={ other.noPreview }
          />
        )
      }
    </div>
  ) : null
}

ShingleColor.Renderer = (UI, parameters={}) => ({
  contents: ({ value }) => {
    const masterSwatch = value?.shingleImages?.masterSwatch?.image;
    return (
      <div>
        <div>
          <Swatch
            h2 label="Master Swatch"
            url={ masterSwatch }
            noTransforms />
          <Swatch
            label="Primary Square Swatch (6 Course)"
            url={ masterSwatch }
            transforms={
              value?.shingleImages?.masterSwatch?.crops?.primarySquare?.crop ? [{
                crop: 'crop',
                ...value?.shingleImages?.masterSwatch?.crops?.primarySquare?.crop
              }] : []
            }
            urls={[
              {
                label: "(768x768)",
                transforms: [{ crop: 'scale', width: 768 }]
              },
              {
                label: "(160x160)",
                transforms: [{ crop: 'scale', width: 160 }],
                noPreview: true
              }
            ]} />
          <Swatch
            label="Rectangular swatch (4 Course)"
            url={ masterSwatch }
            transforms={
              value?.shingleImages?.masterSwatch?.crops?.rectangular?.crop ? [{
                crop: 'crop',
                ...value?.shingleImages?.masterSwatch?.crops?.rectangular?.crop
              }] : []
            }
            urls={[
              {
                label: "(768x485)",
                transforms: [{ crop: 'scale', width: 768, height: 485 }]
              }
            ]} />
          <Swatch
            label="Detail Square Swatch (5 Course)"
            url={ masterSwatch }
            transforms={
              value?.shingleImages?.masterSwatch?.crops?.detail?.crop ? [{
                crop: 'crop',
                ...value?.shingleImages?.masterSwatch?.crops?.detail?.crop
              }] : []
            }
            urls={[
              {
                label: "3x (3000x3000)",
                transforms: [{ crop: 'scale', width: 3000 }]
              }
            ]} />
          <Swatch
            label="Tileable Swatch"
            url={ value?.shingleImages.tileableSwatch?.image }
            noTransforms
            urls={[
              {
                label: 'Tile Image',
                transforms: [{ crop: 'scale', height: 4650 }]
              },
              {
                label: "Landscape Tile Image",
                transforms: [{ crop: 'crop', gravity: 'north_west', height: 3533, width: 4713 }, { crop: 'scale', width: 2732 }]
              }
            ]} />
          <Swatch
            label="House Image (with Sky)"
            noTransforms
            url={ value?.shingleImages.houseWithSky?.image }
            urls={[
              {
                label: 'Scaled',
                transforms: [{ crop: 'scale', width: 1229 }]
              }
            ]} />
          <Swatch
            label="House Image (no Sky)"
            noTransforms
            url={ value?.shingleImages.houseNoSky?.image }
            urls={[
              {
                label: 'Square (768x768)',
                transforms: [{ crop: 'crop', gravity: 'north_west', height: 1219, width: 1219, x: 2770, y: 650 }, { crop: 'scale', width: 768 }]
              },
              {
                label: 'Rectangular (768x485)',
                transforms: [{ crop: 'crop', gravity: 'north_west', height: 1400, width: 2217, x: 2070, y: 470 }, { crop: 'scale', width: 768 }]
              },
              {
                label: 'Thumb (768x485)',
                transforms: [{ crop: 'crop', gravity: 'north_west', height: 1220, width: 2217, x: 2070, y: 650 }, { crop: 'scale', width: 768 }]
              }
            ]} />
          <h3>Gallery Images</h3>
          {
            (value?.shingleImages?.galleryImages || []).map(galleryImage =>
              <Swatch
                label="image"
                h4
                url={ galleryImage?.image }
                transforms={ galleryImage?.crop ? [{
                  crop: 'crop',
                  ...galleryImage?.crop
                }] : [] }
              />
            )
          }
          <Swatch
            label="Alternate House (Ranch)"
            noTransforms
            url={ value?.shingleImages?.alternateHouseImages?.ranch_house_image?.image } />
          <Swatch
            label="Alternate House (Two-Story 1)"
            noTransforms
            url={ value?.shingleImages?.alternateHouseImages?.two_story_house_v1_image?.image } />
          <Swatch
            label="Alternate House (Two-Story 2)"
            noTransforms
            url={ value?.shingleImages?.alternateHouseImages?.two_story_house_v2_image?.image } />
          <Swatch
            label="BYR (Shingle)"
            noTransforms
            url={ value?.shingleImages?.byrShingle?.image } />
          <Swatch
            label="BYR (Hip & Ridge)"
            noTransforms
            url={ value?.shingleImages?.byrHipRidge?.image } />
        </div>
      </div>
    )
  }
});

export default ShingleColor;

 import { useState } from 'react';
import { css } from '@emotion/react';
import { ScrollHook } from '../Utilities';
import { connectLocation } from '../location/LocationConnectors';
import { Model3D, apiChangeStep } from '../BuildYourRoof/model3d';
import Link from '../OC/oc-link';
import RichText from '../../components/OC/PageBuilder/RichText';
import SealDefendBreathe from'../ComponentLibrary/icons/roofing/SealDefendBreathe';

const scrollerContainer = css`
  display: flex;
  flex-direction: column-reverse;

  @media(min-width: 768px) {
    flex-direction: row;
  }
`

const descriptionColumn = css`
  width: 100%;
  border-left: 1px solid #BCBCBC;
  font-size: 14px;
  z-index: 5;
  position: relative;

  @media(min-width: 768px) {
    width: 45%;
    border-right: 1px solid #BCBCBC;
    border-left: 0;
    font-size: 16px;
    z-index: 10;
    padding-bottom: 110px;
  }

  @media(min-width: 1200px) {
    padding-bottom: 250px;
  }

  .scroller-container {
    position: absolute;
    height: 100%;
    width: 3px;
    right: auto;
    left: -2px;
    top: 0;

    @media(min-width: 768px) {
      right: -1px;
      left: auto;
    }

    .scroller {
      position: sticky;
      right: 0;
      top: 190px;
      border-right: 2px solid #d40f7d;
      height: 0;
      width: 100%;
      transition: height .75s;

      @media(min-width: 768px) {
        top: 0px;
      }

      &.active, &.visited {
        height: 135px;

        @media(min-width: 768px) {
          height: 235px;
        }

        @media(min-width: 1200px) {
          height: 210px;
        }
      }
    }
  }

  .scroll-section-container {
    margin-bottom: 56px;
    padding: 0 0 0 30px;

    @media(min-width: 768px) {
      padding: 0 32px 0 0;
    }

    @media(min-width: 1200px) {
      padding: 0 64px 0 0;
    }

    img {
      margin-bottom: 16px;
      width: 100%;
    }

    .scroll-section {
      position: relative;
      transition: all .5s;
      margin: 20px 0;
      display: inline-block;
      width: 100%;

      .section-scroll-indicator {
        background: #bcbcbc;
        width: 16px;
        height: 16px;
        border-radius: 30px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: auto;
        left: -39px;
        z-index: 1;
        transform: translateY(-50%);
        transition: all .5s;

        &:hover {
          background: #d40f7d;
          cursor: pointer;
        }

        @media(min-width: 768px) {
          right: -40px;
          left: auto;
        }

        @media(min-width: 1200px) {
          right: -73px;
          left: auto;
        }
      }

      ul {
        padding-left: 20px;
      }

      &.visited {
        .section-scroll-indicator {
          background: #d40f7d;
        }
      }

      &.active {
        border-left: 2px solid #d40f7d;
        padding-left: 16px;

        .section-scroll-indicator {
          background: #d40f7d;
          width: 14px;
          height: 14px;
          border: 3px solid white;
          box-shadow: 0 0 0 2px #d40f7d;
          left: -39px;
          right: auto;

          @media(min-width: 768px) {
            right: -44px;
            left: auto;
            width: 22px;
            height: 22px;
            border: 5px solid white;
          }

          @media(min-width: 1200px) {
            right: -75px;
          }
        }

        h3 {
          color: #d40f7d;
        }
      }
    }
  }
`

const modelColumn = css`
  width: 100%;
  padding: 0;
  border-bottom: 2px solid black;
  margin-bottom: 16px;
  z-index: 10;
  background: white;
  position: sticky;
  top: -50px;

  &:before {
    content: '';
    position: absolute;
    background: white;
    width: 20px;
    height: 100%;
    left: -20px;
    top: 0;
  }

  @media(min-width: 768px) {
    width: 55%;
    padding: 0 16px;
    border: none;
    margin-bottom: 0;
    z-index: 5;
    position: relative;

    &:before {
      display: none;
    }
  }

  .mobile-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .model-crop {
    position: sticky;
    top: 0;
    height: 275px;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media(min-width: 768px) {
      overflow: visible;
      top: 70px;
      height: 340px;
    }

    @media(min-width: 1200px) {
      top: 70px;
      height: 480px;
    }
  }

  canvas {
    border: none;
  }

  .next-step {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    background: #d40f7d;
    color: white;
    font-family: OCRoboto, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding: 0 1rem;
    min-height: 35px;
    font-size: 18px;
    border: 1px solid #d40f7d;
    overflow: hidden;
    z-index: 100;
    transition: 0.25s ease-in-out;
    bottom: 0;

    // Prevent transition on touch devices
    @media (hover: none), (-moz-touch-enabled: 1) {
      transition: none;
    }

    @media(min-width: 768px) {
      display: inline-flex;
    }

    &:before {
      content: '';
      position: absolute  ;
      top: 0;
      right: 0;
      bottom: 0;
      left: -1px;
      background-color: white;
      transform: translateX(-100%);
      z-index: -1;
      transition: 0.25s ease-in-out;

      /* Prevent transition on touch devices*/
      @media (hover: none), (-moz-touch-enabled: 1) {
        transition: none;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: #d40f7d;
      outline: 0;
      cursor: pointer;

      &:before {
        /* display: block; */
        right: 0;
        transform: translateX(0);
        transition: 0.25s ease-in-out;


        /* Prevent transition on touch devices */
        @media (hover: none), (-moz-touch-enabled: 1) {
          transition: none;
        }
      }
    }
  }
`;

// IDS to use when traversing through next/back
const TPRSOrder = [
  'seal', 'ice-water', 'underlayment', 'defend', 'starter-shingles', 'shingles',
  'hip-ridge', 'breathe', 'intake-vent', 'exhaust-vent', 'comfort', 'blown-in'
]

const scrollToNext = (current) => {
  let nextElement = TPRSOrder[TPRSOrder.indexOf(current) + 1] || 'seal';
  window.scrollTo({ top: window.scrollY + document.getElementById(nextElement).getBoundingClientRect().top - 145, behavior: 'smooth' });
}

const scrollTarget = (target) => {
  if (window.innerWidth <= 767) {
    window.scrollTo({ top: window.scrollY + document.getElementById(target).getBoundingClientRect().top - 250, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: window.scrollY + document.getElementById(target).getBoundingClientRect().top - 145, behavior: 'smooth' });
  }
}

export const TPRSScroller = connectLocation(({ t, editable, sectionContent }) => {
  const [active, setActive] = useState('');
  const [pageStep, changeStep] = useState('');

  const animationChanger = (step, active, activeSection) => () => {
    apiChangeStep(step);
    setActive(activeSection || step);
    changeStep(activeSection || step);
  }

  const activeOrVisited = (position) => {
    let classnames = [];

    if (position === active) {
      classnames.push('active');
    }
    if (TPRSOrder.indexOf(position) <= TPRSOrder.indexOf(pageStep)) {
      classnames.push('visited');
    }
    return classnames.join(" ");
  }

  return (
    <section>
      <div css={scrollerContainer}>
        <div css={descriptionColumn}>
          <div className="scroller-container">
            <div className={`scroller ${activeOrVisited('ice-water')}`}></div>
          </div>

          <Seal
            activeOrVisited={activeOrVisited}
            content={sectionContent?.seal}
            editable={editable}
            triggerAnimation={animationChanger}
          />
          <Defend
            activeOrVisited={activeOrVisited}
            content={sectionContent?.defend}
            editable={editable}
            triggerAnimation={animationChanger}
          />
          <Breathe
            activeOrVisited={activeOrVisited}
            content={sectionContent?.breathe}
            editable={editable}
            triggerAnimation={animationChanger}
          />
          <Comfort
            activeOrVisited={activeOrVisited}
            content={sectionContent?.comfort}
            editable={editable}
            triggerAnimation={animationChanger}
          />
        </div>

        <div css={modelColumn}>
          <div className="model-crop">
            <span className="mobile-cover"></span>
            <Model3D disableControls={1} modelCSS={'margin-bottom: 0!important; width: 100%;'} />

            <span className={`next-step ${active === 'blow-in' ? 'disabled' : ''}`} onClick={() => scrollToNext(pageStep)} tabIndex="0">{t('tprs.next_button')}</span>
          </div>
        </div>
      </div>
    </section>
  )
});

export const Seal = connectLocation(({ t, activeOrVisited, triggerAnimation, editable, content }) => (
  <div id="seal" className="scroll-section-container">
    <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 135 }]} trigger={triggerAnimation('seal', 'default')} />
    <SealDefendBreathe name="seal" display="headingLg" />

    <h2 className="h3 body-font">
      {editable ? content?.descriptionHeading : t('tprs.seal_scroll_description_heading')}
    </h2>
    {editable ? <RichText content={content?.description} /> : <p>{t('tprs.seal_scroll_description')}</p>}

    <div id="ice-water" className={`scroll-section ${activeOrVisited('ice-water')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('ice-water')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('ice-water')} />
      <h3 className="body-font">{editable ? content?.bullet1Heading : t('tprs.ice_water_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet1Content} />
      :
        <>
          <ul>
            <li>{t('tprs.ice_water_item1')}</li>
            <li>{t('tprs.ice_water_item2')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=ice-water-barrier" chevron={true} analytics={{ track: 'learn-more', destination: 'Ice and Water', 'element-location': 'main section CTA' }}>
            {t('tprs.ice_water_cta_text')}
          </Link>
        </>

      }
    </div>

    <div id="underlayment" className={`scroll-section ${activeOrVisited('underlayment')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('underlayment')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('underlayment')} />
      <h3 className="body-font">{editable ? content?.bullet2Heading : t('tprs.unerlayment_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet2Content} />
      :
        <>
          <ul>
            <li>{t('tprs.underlayment_item1')}</li>
            <li>{t('tprs.underlayment_item2')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=underlayment" chevron={true} analytics={{ track: 'learn-more', destination: 'Underlayment', 'element-location': 'main section CTA' }}>
            {t('tprs.underlayment_cta_text')}
          </Link>
        </>
      }
    </div>
  </div>
));

export const Defend = connectLocation(({ t, activeOrVisited, triggerAnimation, editable, content }) => (
  <div id="defend" className="scroll-section-container">
    <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 135 }]} trigger={triggerAnimation('defend', 'default')} />
    <SealDefendBreathe name="defend" display="headingLg" />

    <h2 className="h3 body-font">
      {editable ? content?.descriptionHeading : t('tprs.defend_scroll_description_heading')}
    </h2>
    {editable ? <RichText content={content?.description} /> : <p>{t('tprs.defend_scroll_description')}</p>}

    <div id="starter-shingles" className={`scroll-section ${activeOrVisited('starter-shingles')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('starter-shingles')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('starter-shingles')} />
      <h3 className="body-font">{editable ? content?.bullet1Heading : t('tprs.starter_shingles_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet1Content} />
      :
        <>
          <ul>
            <li>{t('tprs.starter_shingles_item1')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=starter-shingles" chevron={true} analytics={{ track: 'learn-more', destination: 'Starter Shingles', 'element-location': 'main section CTA' }}>
            {t('tprs.starter_shingles_cta_text')}
          </Link>
        </>
      }
    </div>

    <div id="shingles" className={`scroll-section ${activeOrVisited('shingles')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('shingles')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('shingles')} />
      <h3 className="body-font">{editable ? content?.bullet2Heading : t('tprs.shingles_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet2Content} />
      :
        <>
          <ul>
            <li>{t('tprs.shingles_item1')}</li>
            <li>{t('tprs.shingles_item2')}</li>
          </ul>

          <Link href="/roofing/shingles" chevron={true} analytics={{ track: 'learn-more', destination: 'Shingles', 'element-location': 'main section CTA' }}>
            {t('tprs.shingles_cta_text')}
          </Link>
        </>
      }
    </div>

    <div id="hip-ridge" className={`scroll-section ${activeOrVisited('hip-ridge')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('hip-ridge')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('hip-ridge-no-vent', '', 'hip-ridge')} />
      <h3 className="body-font">{editable ? content?.bullet3Heading : t('tprs.hip_ridge_heading')}</h3>

      {editable ?
        <RichText content={content?.bullet3Content} />
      :
        <>
          <ul>
            <li>{t('tprs.hip_ridge_item1')}</li>
            <li>{t('tprs.hip_ridge_item2')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=hip-ridge-shingles&metadata.settings.attributes.product_type[]=hip-ridge-shingles" chevron={true} analytics={{ track: 'learn-more', destination: 'Hip and Ridge', 'element-location': 'main section CTA' }}>
            {t('tprs.hip_ridge_cta_text')}
          </Link>
        </>
      }
    </div>
  </div>
));

export const Breathe = connectLocation(({ t, activeOrVisited, triggerAnimation, editable, content }) => (
  <div id="breathe" className="scroll-section-container">
    <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 135 }]} trigger={triggerAnimation('breathe', 'default')} />
    <SealDefendBreathe name="breathe" display="headingLg"/>

    <h2 className="h3 body-font">
      {editable ? content?.descriptionHeading : t('tprs.breathe_scroll_description_heading')}
    </h2>
    {editable ? <RichText content={content?.description} /> : <p>{t('tprs.breathe_scroll_description')}</p>}

    <div id="intake-vent" className={`scroll-section ${activeOrVisited('intake-vent')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('intake-vent')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('intake-vent', 'ventilation')} />
      <h3 className="body-font">{editable ? content?.bullet1Heading : t('tprs.intake_vent_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet1Content} />
      :
        <>
          <ul>
            <li>{t('tprs.intake_vent_item1')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=hip-ridge-shingles&metadata.settings.attributes.product_type[]=intake-ventilation" chevron={true} analytics={{ track: 'learn-more', destination: 'Intake Ventilation', 'element-location': 'main section CTA' }}>
            {t('tprs.intake_vent_cta_text')}
          </Link>
        </>
      }
    </div>

    <div id="exhaust-vent" className={`scroll-section ${activeOrVisited('exhaust-vent')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('exhaust-vent')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('exhaust-vent', 'ventilation')} />
      <h3 className="body-font">{editable ? content?.bullet2Heading : t('tprs.exhaust_vent_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet2Content} />
      :
        <>
          <ul>
            <li>{t('tprs.exhaust_vent_item1')}</li>
          </ul>

          <Link href="/roofing/components/products?category[]=hip-ridge-shingles&metadata.settings.attributes.product_type[]=exhaust-ventilation" chevron={true} analytics={{ track: 'learn-more', destination: 'Exhaust Ventilation', 'element-location': 'main section CTA' }}>
            {t('tprs.exhaust_vent_cta_text')}
          </Link>
        </>
      }
    </div>
  </div>
));

export const Comfort = connectLocation(({ t, activeOrVisited, triggerAnimation, editable, content }) => (
  <div id="comfort" className={`scroll-section-container ${activeOrVisited('comfort')}`}>
    <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 135 }]} trigger={triggerAnimation('comfort', 'default')} />
    <SealDefendBreathe name="comfort" display="headingLg"/>

    <h3 className="body-font">
      {editable ? content?.descriptionHeading : t('tprs.comfort_scroll_description_heading')}
    </h3>
    {editable ? <RichText content={content?.description} /> : <p>{t('tprs.comfort_scroll_description')}</p>}

    <div id="blown-in" className={`scroll-section ${activeOrVisited('blown-in')}`}>
      <a className="section-scroll-indicator" onClick={() => scrollTarget('blown-in')}></a>
      <ScrollHook target={[{ maxWidth: 767, target: 210 }, { minWidth: 768, target: 110 }]} trigger={triggerAnimation('blown-in', 'default')} />
      <h3 className="body-font">{editable ? content?.bullet1Heading : t('tprs.blow_in_heading')}</h3>
      {editable ?
        <RichText content={content?.bullet1Content} />
      :
        <>
          <ul>
            <li>{t('tprs.blow_in_item1')}</li>
          </ul>

          <Link href="/insulation/residential/products/attics?category[]=fiberglass-insulation&producttype[]=insulation" chevron={true} analytics={{ track: 'learn-more', destination: 'Blown-In Insulation', 'element-location': 'main section CTA' }}>
            {t('tprs.blow_in_cta_text')}
          </Link>
        </>
      }
    </div>
  </div>
));

export default TPRSScroller;

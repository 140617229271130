import { define } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

import CMSHero from './../../../../../../OC/PageBuilder/Hero.jsx';

export default define`ProductHero`('0.0.1')({
  gapless: true,
  gutterless: true,
  view: ({ value }) => {
    let props = _.cloneDeep(value);
    props = {
      ...( props || {} ),
      copy: {
        prehead: Board.settings.general?.prehead,
        mainHeading: Board.settings.general?.proper_name,
        posthead: Board.settings.general?.subheading,
        body: Board.settings.general?.body_copy,
      },
      images: {
        bgDesktop: Board.settings.images?.images?.[Board.settings.images?.hero_image],
        bgTablet: Board.settings.images?.images?.[Board.settings.images?.hero_image],
      },
      preset: 'custom',
      type: 'tier_3'
    }

    return <CMSHero { ...props } />;
  }
});

import { css } from '@emotion/react';
import Theme from '../../../../../themes';

export default {
  base: css`
    .ql-container {
      font-family: "OCRoboto", sans-serif;
    }

    .ql-toolbar button {
      background: transparent !important;
      box-shadow: 0px 0px 0px transparent !important;
      text-shadow: 0px 0px 0px transparent !important;
      outline: none !important;
      padding: 0px !important;
      border: 0 !important;
      -webkit-appearance: none;
      appearance: none;
    }

    .ql-editor {
      font-size: 16px;
      outline: none;
    }

    .ql-editor p:first-child {
      margin-top: 0;
    }

    .ql-editor p:last-child {
      margin-bottom: 0;
    }

    .ql-editor a:not(:hover) {
      color: inherit;
    }

    .ql-clipboard {
      left: -100000px;
      height: 1px;
      overflow-y: hidden;
      position: absolute;
      top: 50%;
    }

    .ql-clipboard p {
      margin: 0;
      padding: 0;
    }

    mark {
      background-color: transparent !important;
      color: inherit;
    }

    mark.highlight {
      font-style: inherit;
      color: ${ Theme.colors.brand };
    }

    div.disclaimer {
      font-size: 12px;
    }

    div.smaller {
      font-size: 14px;
    }

    div.document {
      margin-top: 16px;
    }

    div.document a {
      text-decoration: none;
    }

    div.document a:hover .document-text {
      text-decoration: underline;
    }

    div.document .document-icon {
      text-decoration: none;
      padding-right: 10px;
    }

    div.document .document-size {
      text-decoration: none;
      font-weight: 300;
      font-size: 12px;
      padding-left: 8px;
    }

    div + p br {
     display: none;
    }
  `,
  full: css`
    .ql-container {
      border: 1px solid #585858;
      background-color: #FFF;
      color: #333;
    }
    .ql-editor {
      padding: 12px 8px;
    }
  `
}

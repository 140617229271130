import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ProductItem from './helpers/ProductItem';
import ProductItemList from './helpers/ProductItemList';
import { connectLocation } from '../../../location/LocationConnectors';
import Checkbox from '../../../ComponentLibrary/input-elements/oc-checkbox';
import InputList from '../../../ComponentLibrary/input-elements/oc-input-list';
import SealDefendBreathe from '../../../ComponentLibrary/icons/roofing/SealDefendBreathe';
import cms_api from '../../../../cms_api';
import retrieveImage from './helpers/retrieveImage';
import pathToContent from './helpers/path_to_content';

const getProductTypesData = _.memoize(
  async () => {
    const response = await cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: Board.build.language,
        route: '/roofing',
        name: 'Product Type',
      },
      fields: {
        '*': 'contents',
      },
    });
    return _.keyBy(response?.items, r => r.value);
  }
);

const GroupContainer = styled.div`
  ${props => props.border ? 'border-top: 1px solid #000000' : ''};
  display: inline-block;
  padding-bottom: 2em;
  padding-top: 2em;
  position: relative;
  width: 100%;
`;

const nameFormatter = (name) => name.replace('_', ' ').replace(/(^|\s)\S/g, function(letter) { return letter.toUpperCase() })
const getOption = (options, slug) => options?.filter(x => x.value === slug)[0]
const RoofingCheckbox = ({ defaultValue, filterAttribute, onChange, option, loading, count }) => (
  <>
    {
      option && count > 0 && <Checkbox
        data-track={ (defaultValue || []).includes(option.value) ? "remove-filter" : "filter" }
        data-track-filter-name={ nameFormatter(filterAttribute) }
        data-track-filter-option={ option.displayName }
        checked={ (defaultValue || []).includes(option.value) }
        disabled={ count === 0 && !(defaultValue || []).includes(option.value) || loading } // disabled if count = 0 and not checked
        onClick={ () => {
          onChange(filterAttribute, option.value)
        } }
      >
        { `${ option.displayName } (${ count })` }
      </Checkbox>
    }
  </>
)
const RoofingComponentFilter = ({ options, defaultValue, onChange, filterAttribute, loading, filterCounts }) => {
  const iceOption = getOption(options, 'ice-water-barrier')
  const underlaymentOption = getOption(options, 'underlayment')
  const iceCount = filterCounts['ice-water-barrier'];
  const underlaymentCount = filterCounts['underlayment'];
  const sealCount = (iceCount||0) + (underlaymentCount||0)
  const hipOption = getOption(options, 'hip-ridge-shingles')
  const starterOption = getOption(options, 'starter-shingles')
  const slopeOption = getOption(options, 'low-slope-products')
  const hipCount = filterCounts['hip-ridge-shingles'];
  const starterCount = filterCounts['starter-shingles'];
  const slopeCount = filterCounts['low-slope-products'];
  const defendCount = (hipCount||0) + (starterCount||0) + (slopeCount||0)
  const intakeOption = getOption(options, 'intake-ventilation')
  const exhaustOption = getOption(options, 'exhaust-ventilation')
  const intakeCount = filterCounts['intake-ventilation'];
  const exhaustCount = filterCounts['exhaust-ventilation'];
  const breatheCount = (intakeCount||0) + (exhaustCount||0)
  return (
    <>
      { sealCount > 0 &&
        <GroupContainer>
          <SealDefendBreathe name="seal" width="170" />
          <InputList>
            <RoofingCheckbox option={ iceOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ iceCount }/>
            <RoofingCheckbox option={ underlaymentOption } defaultValue={ defaultValue }
                             filterAttribute={ filterAttribute } onChange={ onChange } loading={ loading } count={ underlaymentCount }/>
          </InputList>
        </GroupContainer>
      }
      { defendCount > 0 &&
        <GroupContainer border={ sealCount > 0 }>
          <SealDefendBreathe name="defend" width="170" />
          <InputList>
            <RoofingCheckbox option={ hipOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ hipCount }/>
            <RoofingCheckbox option={ starterOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ starterCount }/>
            <RoofingCheckbox option={ slopeOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ slopeCount }/>
          </InputList>
        </GroupContainer>
      }
      { breatheCount > 0 &&
        <GroupContainer border={ sealCount > 0 || defendCount > 0 }>
          <SealDefendBreathe name="breathe" width="170" />
          <InputList>
            <RoofingCheckbox option={ intakeOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ intakeCount }/>
            <RoofingCheckbox option={ exhaustOption } defaultValue={ defaultValue } filterAttribute={ filterAttribute }
                             onChange={ onChange } loading={ loading } count={ exhaustCount }/>
          </InputList>
        </GroupContainer>
      }
    </>
  )
}

RoofingComponentFilter.filterValueType = 'multiple';
RoofingComponentFilter.getChipInfo = (filter, filterValues) => {
  if (Array.isArray(filterValues)){
    return filterValues.map(filterValue => ({ ...filter.options.filter(option => option.value === filterValue)[0] }))
  }
}

const getHeader = (category) => {
  switch (category) {
    case 'seal': return <SealDefendBreathe display='prehead' name="seal" />;
    case 'defend': return <SealDefendBreathe display='prehead' name="defend" />;
    case 'breathe': return <SealDefendBreathe display='prehead' name="breathe" />;
    default: return <></>;
  }
}

const ProductListItem = connectLocation(({ aspectRatio, locale: { code }, t, ...product }) => {
  //console.log('ProductListItem', product)
  const category = _.get(product, 'metadata.settings.attributes.tprs_area');
  const preheading = _.get(product, 'metadata.settings.attributes.product_type');
  const product_thumb = retrieveImage(product);
  const generalContent = product.metadata?.settings?.general;
  const teaser_copy = generalContent?.long_description || generalContent?.short_description;
  const product_url = product.metadata?.availability?.status === 'external' ? product.metadata?.availability?.externalUrl : pathToContent(product);
  //console.log('product_thumb', product_thumb)

  const [lookup, setLookup] = useState({});
  useEffect(() => {
    getProductTypesData().then(lookup => setLookup(lookup))
  }, [])

  if (!lookup) {
    return null;
  }

  return (
    <ProductItem
      aspectRatio={ aspectRatio }
      key={ product.uid }
      category={ getHeader(category) }
      preheading={ lookup[preheading]?.label }
      heading={ generalContent?.proper_name }
      img={ product_thumb }
      text={ teaser_copy }
      url={ product_url }
    />
  );
})

export default ProductItemList({
  common: {
    type: 'Product::Roofing'
  },
  search: {
    name: 'Roofing (Components) Products',
    preview: result => ({
      preheading: _.get(result, 'categories[0].proper_name'),
      heading: result.metadata?.settings?.general?.proper_name || result.name
    })
  },
  list: {
    availableIn: ['www.owenscorning.com'],
    name: 'Products - Roofing (Components)',
    attributeSet: {
      name: 'Roofing PDP Attributes',
      path: 'roofing'
    },
    dataset: 'products_roofing',
    view: ProductListItem,
    enableSearch: true,
    filterData: (v, filter) => {
      return v === 'metadata.settings.attributes.product_type' ? { component: RoofingComponentFilter } : {};
    }
  }
})

import { define, ui, s, when } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/RoofingWidgets';


export default define`Roofing Widgets`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/RoofingWidgets`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {

    arrangement: {
    widgetOption: ui`Choices`.of({
        'designAndInspire': 'Design and Inspire',
        'totalProtectionRoofingSys': 'Total Protection Roofing System',
        'warranty': 'Warranty',
        'designEyeQ': 'Design Eye Q'
    })({
        label: 'Widget Options',
        sublabel: 'Widget Type',
        default: 'designAndInspire'
    }),
    columns: ui`Choices`.of({
        'two': '2 Columns: 1/2 instructions 1/2 widget',
        'one': '1 Column'
    })({
        label: 'Layout',
        default: 'two',
    }),
    contentOrder: [
        when`../columns`.is.equal.to('two').then(ui`Choices`.of({
            'left': 'Left',
            'right': 'Right'
        })({
            default: 'left'
        })).otherwise(ui`Choices`.of({
            'top': 'Top',
            'bottom': 'Bottom'
        })({
            default: 'top'
        })),
    {
        label: 'Installation Instructions',
        sublabel: 'Position',
    }
    ], [s._]: ui`Tip`.of( 'Must select an instruction orientation.' ),
    },
},
});

import _ from 'lodash';
import { Quill } from 'react-quill';
import Linking from '../../../../PageBuilder/helpers/linking';
import { expandRef, unwrapRef, wrapRef } from '../../../../../data';
import getFormatFromMime from '../../../../helpers/get_format_from_mime';
import getFileSize from '../../../../helpers/get_file_size';
import escapeHtml from '../../../../../helpers/escape_html';
import pathToContent from '../../../Data/Providers/helpers/path_to_content';

/*
 *  to create a new blot:
 *    - add your class to inline/block/embed below and inherit from Quill base class
 *      (see Quill docs on exactly what options are present)
 *    - add renderToString(value, contents) static method.  this should be similar to `create` but
 *      instead of getting a `node` and using DOM, return an HTML string
 */

let Block = Quill.import('blots/block');
let Inline = Quill.import('blots/inline');
let Embed = Quill.import('blots/embed');
let BlockEmbed = Quill.import('blots/block/embed');

const getLinkAttributes = (value) => {
  let link = Linking.Parse(value);

  return _.merge({
      href: link.url,
      'data-track-element-location': 'main section text link',
    },
    link.relative ? { target: '_blank' } : {},
    link.analytics,
    !link.analytics && process.env.NODE_ENV === 'development' ? { style: 'outline: 1px dashed red', title: 'Missing analytics attributes', 'data-track': 'DATA TRACK MISSING' } : {}
  );
}

const convertAttributeObjectToString = (object) => (
  Object.entries(object).map(([k, v]) => `${k}="${escapeHtml(v)}"`).join(' ')
)

const setLinkAttributes = (node, value) => (
  Object.entries(getLinkAttributes(value)).forEach(([ k, v ]) => node.setAttribute(k, v))
)

const Blots = {
  Inlines: [
    class Pink extends Inline {
      static key = 'formats/pink';
      static tagName = 'mark';
      static blotName = 'pink';
      static className = 'highlight';
    },
    class Link extends Inline {
      static tagName = 'a';
      static blotName = 'link';

      static formats(node) { return node.getAttribute('href'); }

      static create(value) {
        const node = super.create();
        setLinkAttributes(node, value)
        return node;
      }
      // THIS METHOD EXISTS FOR RENDERING OUTSIDE OF QUILL (SSR, and non-edit views)
      static renderToString(value, contents) {
        return value ? `<a ${convertAttributeObjectToString(getLinkAttributes(value))}>${ contents }</a>` : contents
      }

    }
  ],
  Blocks: [
    class HeaderTwoOswald extends Block {
      static tagName = 'h2';
      static blotName = 'header_two_oswald';
    },
    class HeaderTwoRoboto extends Block {
      static tagName = 'h2';
      static blotName = 'header_two_roboto';
      static className = 'body-font';
    },
    class HeaderThreeOswald extends Block {
      static tagName = 'h3';
      static blotName = 'header_three_oswald';
    },
    class HeaderThreeRoboto extends Block {
      static tagName = 'h3';
      static blotName = 'header_three_roboto';
      static className = 'body-font';
    },
    class HeaderFourRoboto extends Block {
      static tagName = 'h4';
      static blotName = 'header_four_roboto';
    },
    class HeaderFiveRoboto extends Block {
      static tagName = 'h5';
      static blotName = 'header_five_roboto';
    },
    class HeaderSixRoboto extends Block {
      static tagName = 'h6';
      static blotName = 'header_six_roboto';
    },
    class Small extends Block {
      static tagName = 'div';
      static blotName = 'smaller';
      static className = 'smaller';
    },
    class Disclaimer extends Block {
      static tagName = 'div';
      static blotName = 'disclaimer';
      static className = 'disclaimer';
    }
  ],
  Embeds: [
    class Breaker extends Embed {
      static tagName = 'br';
      static blotName = 'breaker';

      // THIS METHOD EXISTS FOR RENDERING OUTSIDE OF QUILL (SSR, and non-edit views)
      static renderToString(value) {
        return value ? '<br>' : '';
      }
    }
  ],
  BlockEmbeds: [
    class Document extends BlockEmbed {
      static tagName = 'div';
      static blotName = 'document';
      static className = 'document';

      static value(node) { return JSON.parse(node.getAttribute('data')); }

      // THIS METHOD EXISTS FOR RENDERING OUTSIDE OF QUILL (SSR, and non-edit views)
      static renderToString(value) {
        // value is either a format like (old):
        // { size: "1.3 MB", text: "PINKBAR Project Highlight - Dairy Farm", type: "pdf", document: "10024401" }
        // or (new):
        // { document: Object { __ref: "Document|\"10024401\"" }, title: "PINKBAR Project Highlight - Dairy Farm" }
        // or (doc builder):
        // { document: Object { __ref: "Asset|\"b93df14d-b51e-4561-b306-047a415d96dc\"" }, title: "PINKBAR Project Highlight - Dairy Farm" }
        // { external: Object { url: "someurl", link_text: "text" }, title: "PINKBAR Project Highlight - Dairy Farm" }

        const tag = this.tagName.toLowerCase();
        const { document, external, format, type } = value || {};

        let href = '';
        let innerHTML = '';
        // PAR-450: Simulate doc structure for external PIM docs on Paroc PDPs while maintaining link URL
        if (format === "document" && type === "link") {
          href = external.url;
          const [pubId, data] = [document.data.publication_id, document.data];
          return `<${tag} class="${this.className}" data-value="${pubId}">${this.buildDocLink(type, data, value)}</${tag}>`;
        } else if (!_.isEmpty(external)) {
          // doc builder, external
          href = external.url;
          innerHTML = value.title || external.url;
          const attributes = _.merge({}, getLinkAttributes(href), { target: '_blank' });
          const link = `<a ${convertAttributeObjectToString(attributes)}>${ innerHTML }</a>`
          return `<${tag} class="${this.className}">${ link }</${tag}>`
        } else {
          // if document is string, "old", else it's either doc builder upload or "new", depending on ref type
          const ref = _.isString(document) ? wrapRef('Document', document) : document;
          const [type, pubId, data] = unwrapRef(ref);
          if (!data) {
            // call info API then update links later if data wasn't prefetched
            expandRef(ref).then(doc => {
              this.fillDocLinksOnPage(type, doc, value);
            });
          }
          return `<${tag} class="${this.className}" data-value="${pubId}">${(data || (value.text || value.size || value.type)) ? this.buildDocLink(type, data, value) : ''}</${tag}>`
        }
      }

      static fillDocLinksOnPage(type, doc, value) {
        if (typeof(document) !== 'undefined' && document) {
          const id = type === 'Document' ? doc.publication_id : doc.uuid;
          const containers = document.querySelectorAll(`${this.tagName}.${this.className}[data-value="${id}"]`)
          for (var i = 0; i < containers.length; i++) {
            containers[i].innerHTML = this.buildDocLink(type, doc, value);
          }
        }
      }

      static buildDocLink(type, doc, value) {
        let href = '';
        if (type === 'Document') {
          // "new"
          href = `/dms/${ doc?.publication_id || value.document }`
        } else {
          // doc builder upload
          href = doc.url;
        }
        const attributes = _.merge({}, getLinkAttributes(href), { target: '_blank' });
        return `<a ${convertAttributeObjectToString(attributes)}>${ this.buildDocLinkInnerHtml(type, doc, value) }</a>`
      }

      static buildDocLinkInnerHtml(type, doc, value) {
        if (type === 'Document') {
          // "new" format
          const mime_type = doc?.mime_type ? getFormatFromMime(doc.mime_type) : value.type;
          const size = doc?.size_bytes ? getFileSize(doc.size_bytes) : value.size;
          return `<span class="document-icon"><i class="fa fa-file${ mime_type ? `-${ mime_type }` : '' }-o"></i></span>
                <span class="document-text">${ escapeHtml(value.title || value.text || doc?.title) }</span>
                <span class="document-size">${ mime_type ? `${ mime_type.toUpperCase() } | ` : '' }${ size }</span>`;
        } else {
          // Cms::Asset (doc builder)
          const mime_type = getFormatFromMime(doc.mime_type);
          const size = getFileSize(doc.size_bytes);
          return `<span class="document-icon"><i class="fa fa-file${ mime_type ? `-${ mime_type }` : '' }-o"></i></span>
                <span class="document-text">${ escapeHtml(value.title || doc.original_file_name) }</span>
                <span class="document-size">${ mime_type ? `${ mime_type.toUpperCase() } | ` : '' }${ size }</span>`;
        }
      }

      static create(value) {
        // value is either a format like (old):
        // { size: "1.3 MB", text: "PINKBAR Project Highlight - Dairy Farm", type: "pdf", document: "10024401" }
        // or (new):
        // { document: Object { __ref: "Document|\"10024401\"" }, title: "PINKBAR Project Highlight - Dairy Farm" }
        // or (doc builder):
        // { document: Object { __ref: "Cms::Asset|\"b93df14d-b51e-4561-b306-047a415d96dc\"" }, title: "PINKBAR Project Highlight - Dairy Farm" }
        // { external: Object { url: "someurl", link_text: "text" }, title: "PINKBAR Project Highlight - Dairy Farm" }

        const node = super.create(value);
        node.setAttribute('data', JSON.stringify(value));

        const { document, external } = value || {};
        let href = '';
        let innerHTML = '';
        if (!_.isEmpty(external)) {
          // doc builder, external
          href = external.url;
          innerHTML = value.title || external.url;
        } else {
          // if document is string, "old", else it's either doc builder upload or "new", depending on ref type
          const ref = _.isString(document) ? wrapRef('Document', document) : document;
          const [type, pubId, data] = unwrapRef(ref);
          if (!data) {
            // call info API then update links later if data wasn't prefetched
            expandRef(ref).then(doc => {
              this.fillDocLinksOnPage(type, doc, value);
            });
          }
          node.setAttribute('data-value', pubId);
          innerHTML = this.buildDocLinkInnerHtml(type, data, value);
          if (type === 'Document') {
            // "new"
            href = `/dms/${pubId}`;
          } else {
            // doc builder upload
          }
        }

        const a = node.ownerDocument.createElement('a');
        setLinkAttributes(a, href);
        a.setAttribute('target', '_blank');
        a.innerHTML = innerHTML;
        node.appendChild(a);
        return node;
      }
    },
    class DocumentBuilder extends BlockEmbed {
      static tagName = 'div';
      static blotName = 'document_builder';
      static className = 'document';

      static value(node) { return JSON.parse(node.getAttribute('data')); }

      // THIS METHOD EXISTS FOR RENDERING OUTSIDE OF QUILL (SSR, and non-edit views)
      static renderToString(value) {
        //console.log('renderToString', {value})
        const tag = this.tagName.toLowerCase();

        const { document, type: valueType } = value || {};
        if (valueType === 'link') {
          // PIM mapping override
          return `<${tag} class="${this.className}">${this.buildDocLink('pim', document, value)}</${tag}>`
        }

        const ref = document;
        const [type, params, data] = unwrapRef(ref);
        if (!data) {
          // call info API then update links later if data wasn't prefetched
          expandRef(ref).then(doc => {
            this.fillDocLinksOnPage(type, doc, value);
          });
        }
        const id = `${params.id}/${params.language}`
        return `<${tag} class="${this.className}" data-value="${id}">${data ? this.buildDocLink(type, data, value) : ''}</${tag}>`
      }

      static fillDocLinksOnPage(type, doc, value) {
        if (typeof(document) !== 'undefined' && document && doc) {
          //console.log('fillDocLinksOnPage', {type, doc, value})
          const { document: ref } = value || {};
          const [_type, params, data] = unwrapRef(ref);
          const id = `${params.id}/${params.language}`
          const containers = document.querySelectorAll(`${this.tagName}.${this.className}[data-value="${id}"]`)
          for (let i = 0; i < containers.length; i++) {
            containers[i].innerHTML = this.buildDocLink(type, doc, value);
          }
        }
      }

      static buildDocLink(type, doc, value) {
        if (!doc) {
          return '';
        }
        //console.log('buildDocLink', {type, doc, value})
        let href = null;
        switch (type) {
          case 'pim': {
            href = doc.data?.url;
            break;
          }
          default: {
            href = pathToContent(doc);
          }
        }
        const attributes = _.merge({}, getLinkAttributes(href), { target: '_blank' });
        return `<a ${convertAttributeObjectToString(attributes)}>${ this.buildDocLinkInnerHtml(type, doc, value) }</a>`
      }

      static buildDocLinkInnerHtml(type, doc, value) {
        if (!doc) {
          return '';
        }
        //console.log('buildDocLinkInnerHtml', {type, doc, value})
        const mime_type = type === 'pim' ? doc.data?.mime_type : doc.contents?.type === 'upload' ? doc.contents?.document?.mime_type : null;
        const size_bytes = type === 'pim' ? doc.data?.size_bytes : doc.contents?.document?.size_bytes;
        const title = type === 'pim' ? doc.data?.title : doc.metadata?.settings?.attributes?.title;
        const format = getFormatFromMime(mime_type);
        const size = getFileSize(size_bytes);
        return `<span class="document-icon"><i class="fa fa-file${ format ? `-${ format }` : '' }-o"></i></span>
              <span class="document-text">${ escapeHtml(title) }</span>
              <span class="document-size">${ format ? `${ format.toUpperCase() } | ` : '' }${ size }</span>`;
      }

      static create(value) {
        // value is:
        //   {
        //     "document": {
        //       "__ref": "Cms::Content|{\"type\":\"Document\",\"id\":\"ea4e97ad-69ae-4985-974c-7999c6219a90\"}"
        //     }
        //   }
        //console.log('create', {value})

        const node = super.create(value);
        node.setAttribute('data', JSON.stringify(value));

        const { document } = value || {};
        let href = '';
        let innerHTML = '';

        const ref = document;
        const [type, params, data] = unwrapRef(ref);
        if (!data) {
          // call info API then update links later if data wasn't prefetched
          expandRef(ref).then(doc => {
            this.fillDocLinksOnPage(type, doc, value);
          });
        }
        node.setAttribute('data-value', `${params.id}/${params.language}`);
        innerHTML = this.buildDocLinkInnerHtml(type, data, value);

        const a = node.ownerDocument.createElement('a');
        setLinkAttributes(a, href);
        a.setAttribute('target', '_blank');
        a.innerHTML = innerHTML;
        node.appendChild(a);
        return node;
      }
    }
  ]
};

Blots.Inlines.map((blot) => blot.key ? Quill.register(blot.key, blot) : Quill.register(blot));
Blots.Blocks.map((blot) => Quill.register(blot));
Blots.Embeds.map((blot) => Quill.register(blot));
Blots.BlockEmbeds.map((blot) => Quill.register(blot));

// finder by `blotName`, e.g.: Blots.Inlines.named('link')
Blots.Inlines.named = (name) => _.first(Blots.Inlines.filter(blot => blot.blotName === name))
Blots.Blocks.named = (name) => _.first(Blots.Blocks.filter(blot => blot.blotName === name))
Blots.Embeds.named = (name) => _.first(Blots.Embeds.filter(blot => blot.blotName === name))
Blots.BlockEmbeds.named = (name) => _.first(Blots.BlockEmbeds.filter(blot => blot.blotName === name))

export default Blots;

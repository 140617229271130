import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import { isItemVisible } from '../../../PageBuilder/helpers/content';
import Content from './Content';
import _ from 'lodash';

import AlertMessage from '../../../OC/oc-alert-message';

const Modular = (type, moduleType, { list=(UI=>({})), settings={}, read, write, variables=()=>({}), selection=Modular.Selection, view, layout = {}, modals=(UI)=>({}), ...definition }) => Content(
  type,
  (UI) => ({
    version: '0.2',
    read: read || _.identity,
    write: write || _.identity,
    selection,
    variables: _.merge({}, {
      build: { moduleType },
      focus: { phase: 'list' },
      names: {
        modules: _.mapValues(UI.Modules[moduleType], (module) => module.displayName),
        layout: _.mapValues(UI.Modules.Layout, (area) => _.mapValues(area, (module) => module.displayName))
      }
    }, variables(UI)),
    sidebar: {
      builder: select`~focus/phase`.from({
        list: list(UI),
        layout: {
          heading: ui`Heading`({
            subheading: 'Layout Settings',
            heading: select`/~focus:layout:area/~focus:layout:name`.from`~names/layout`,
            onBack: Modular.Back
          }),
          'layout/~focus:layout:area/~focus:layout:name': ui`Form`.of(ui`Modules/Layout/~focus:layout:area/~focus:layout:name`)({ title: true })
        },
        section: {
          heading: ui`Heading`({ subheading: Modular.Section.Title, heading: 'Section Settings', onBack: Modular.Back }),
          'sections/~focus:section': ui`Form`.of({
            details: {
              name: ui`Text`({ label: 'Section Title' }),
              useNameAsHeading: ui`Switch`({ label: 'Use Section Title as Heading' }),
              initialHeadingLevel: ui`Choices`.of({
                h2: 'H2',
                h3: 'H3'
              })({
                mode: ui`Choices/Mode/Dropdown`,
                label: 'Initial Section Heading',
                visible: when`../useNameAsHeading`.is.equal.to(true),
                default: 'h2'
              }),
              bodyFont: ui`Switch`({
                label: 'Use Roboto for Heading Font',
                default: false
              }),
              headingAlignment: ui`Choices`.of({
                'left': 'Left',
                'center': 'Center'
              })({
                label: 'Heading Alignment',
                default: 'left'
              }),
              forceHeadingHierarchy: ui`Switch`({
                label: 'Force Heading Hierarchy',
                visible: when`../useNameAsHeading`.is.equal.to(true),
                default: true
              }),
              [s._]: ui`Tip`.of('By forcing hierarchy all headings inside modules within this section will follow a hierarchy starting from the initial section heading above.')({
                visible: when`../useNameAsHeading`.is.equal.to(true)
              }),
              background: ui`Choices`.of({
                white: 'White',
                grey: 'Grey'
              })({
                label: 'Background Color',
                default: ui`Section/Defaults/details/background`
              }),
              separator: ui`Choices`.of({
                auto: 'Auto',
                show: 'Show',
                hide: 'Hide'
              })({
                label: 'Horizontal Rule',
                default: ui`Section/Defaults/details/separator`
              })
            },
            sticky: {
              show: ui`Switch`({ label: 'Section Visibility in Sticky Nav' }),
              name: ui`Text`({ label: 'Sticky Nav Label' })
            }
          })
        },
        module: {
          heading: ui`Heading`({
            subheading: Modular.Section.Title,
            heading: select`../sections/~focus:section/modules/~focus:module/type`.from`~names/modules`,
            onBack: Modular.Back
          }),
          'sections/~focus:section/modules/~focus:module/data': ui`Form`.of(ui`Modules/~build:moduleType/@..:type`)({ title: true })
        },
        moduleList: {
          heading: ui`Heading`({
            heading: select`../sections/~focus:section/modules/~focus:module/data/moduleList/~focus:moduleList/`.from`~names/modules`,
            onBack: () => Board.select({ focus: {...Board.focus.last} })
          }),
          'sections/~focus:section/modules/~focus:module/data/moduleList/~focus:moduleList/data': ui`Form`.of(ui`Modules/~build:moduleType/@..:type`)({ title: true })
        },
        tabContent: {
          heading: ui`Heading`({
            heading: select`../sections/~focus:section/modules/~focus:module/data/tabs/~focus:tabs/tabContent/~focus:tabContent/`.from`~names/modules`,
            onBack: () => Board.select({ focus: {...Board.focus.last} })
          }),
          'sections/~focus:section/modules/~focus:module/data/tabs/~focus:tabs/tabContent/~focus:tabContent/data': ui`Form`.of(ui`Modules/~build:moduleType/@..:type`)({ title: true })
        },
        settings: {
          heading: ui`Heading`({ subheading: 'Module Settings', heading: Modular.ModuleSettings.Title , onBack: Modular.Back }),
          'sections/~focus:section/modules/~focus:module/data/settings': ui`Form`.of({
            title: ui`Text`({ label: 'Module Title' }),
          })
        },
      }).alongside(
        {
          [s._]: [
            ({ alertMessage }) => <AlertMessage title={'Warning'} message={alertMessage} dismissable={true} type='warning' alertIcon={true} textSize="13px" style={{ marginBottom: '12px' }} />,
            transform('./', '~focus')((value, focus) => {
              let alertMessage;

              if (focus && focus.phase === 'module') {
                let moduleIndex = focus.module;
                let sectionIndex = focus.section;

                if (value.sections[sectionIndex].__hidden === true && value.sections[sectionIndex].modules[moduleIndex].__hidden === true) {
                  alertMessage = 'You are editing a hidden module within a hidden section.';
                } else if (value.sections[sectionIndex].modules[moduleIndex].__hidden === true) {
                  alertMessage = 'You are editing a hidden module.';
                } else if (value.sections[sectionIndex].__hidden === true) {
                  alertMessage = 'You are editing a hidden section.';
                } else {
                  alertMessage = 'You are editing a hidden component';
                }

                if (value.sections[sectionIndex].__hidden === true || value.sections[sectionIndex].modules[moduleIndex].__hidden === true) {
                  return { visible: true, alertMessage: alertMessage };
                } else {
                  return { visible: false };
                }
              } else {
                return { visible: false };
              }
            }),
          ],
        }
      ),
      settings
    },
    modals: _.merge({}, {
      module: {
        title: 'Add Module',
        preface: 'Select a module type to add:',
        body: ui`Grid`.of(
          _.mapValues(
            _.omit(UI.Modules[moduleType], 'Thumbnails'),
            (module, type) => ui`Thumbnail`({
              image: module.thumbnail,
              subtitle: module.displayName,
              onClick: () => {
                Board.modal.reference({ type, data: Board.Evaluate(module.modes.edit) });
                Board.modal.close();
              }
            })
          )
        )
      }
    }, modals(UI)),
    view: view ? view(UI, layout) : Modular.Renderer(UI, layout),
    ...definition
  })
);


// Controls
Modular.Back = () => Board.select({ focus: { phase: 'list' }, lastOpenedSection: Board.focus.section });
Modular.Layout = (area, name) => Board.select({ focus: { phase: 'layout', layout: { area, name } } });
Modular.Section = (section) => Board.select({ focus: { phase: 'section', section } });
Modular.ModuleSettings = (module) => Board.select({ focus: { phase: 'settings', section: Board.Path.at(-2), module }});
Modular.Module = (module, props = {}) => Board.select({ focus: { phase: 'module', section: Board.Path.at(-2), module }, opts: {...props.opts}} );
Modular.ModuleList = (path) => Board.select({
  focus: {
    phase: 'moduleList',
    section: path[1],
    module: path[3],
    moduleList: path[6],
    last: _.cloneDeep(Board.focus)
  }
});
Modular.TabContent = (path) => Board.select({
  focus: {
    phase: 'tabContent',
    section: path[1],
    module: path[3],
    tabs: path[6],
    tabContent: path[8],
    last: _.cloneDeep(Board.focus)
  }
});

Modular.Section.Title = when`../sections/~focus:section/details/name`.is.present
                        .then(select`../sections/~focus:section/details/name`)
                        .otherwise(transform`~focus/section`((index) => `Section ${ parseInt(index) + 1 }`));

Modular.ModuleSettings.Title = when`../sections/~focus:section/modules/~focus:module/data/settings/title`.is.present
                        .then(select`../sections/~focus:section/modules/~focus:module/data/settings/title`)
                        .otherwise(select`../sections/~focus:section/modules/~focus:module/type`.from`~names/modules`);


Modular.Selection = {
  in: (selection) => {
    if (!selection) return { focus: { phase: 'list' }, sidebar: 'builder' };
    const parts = selection.split('/');
    if (parts[0] === 'layout') return { focus: { layout: { area: parts[1], name: parts[2] }, phase: 'layout' }, sidebar: 'builder' };
    return { focus: { section: parts[1], module: parts[3] || 0, phase: parts.length === 2 ? 'section' : 'module' }, sidebar: 'builder' };
  },
  out: (focus) => {
    if (focus?.phase == 'layout') return `layout/${ focus.layout.area }/${ focus.layout.name }`;
    if (focus?.phase == 'section') return `sections/${ focus.section }`;
    if (focus?.phase == 'module') return `sections/${ focus.section }/modules/${ focus.module }`;
    return null;
  }
};


// Lists
Modular.Layout.List = (UI, area, structure, params={}) => ui`List/Item`.of(
  _.mapValues(
    _.mapKeys(structure),
    (name) => ui`List/Item`({
      title: UI.Modules.Layout[area][name].displayName,
      onClick: () => Modular.Layout(area, name),
      minimal: true,
      hideable: true,
      actions: [
        {
          label: 'Settings',
          icon: 'cogs',
          onClick: () => Modular.Layout(area, name)
        }
      ],
      default: Board.Evaluate(UI.Modules.Layout[area][name].modes.edit)
    })
  )
)({
  title: area,
  ...params
});

const moduleGridDefinition = (UI, add, only, moduleType, category) => (
  ui`Grid`.of(
    _.mapValues(
      _.pickBy(
        _.omitBy(UI.Modules[moduleType],
          (module, name) => ['Thumbnails', 'Layout'].includes(name) || (only && !only.includes(name))),
          (module) => {
            return _.isEmpty(UI.Modules.Categories[moduleType]) || (module.category
              ? _.get(UI, module.category.path.split('/')) === category
              : category === _.keys(UI.Modules.Categories[moduleType])[0])
          }
        ),
      (module, type) => ui`Thumbnail`({
        image: module.thumbnail,
        subtitle: module.displayName,
        onClick: () => {
          add({ type, data: Board.Evaluate(module.modes.edit) });
          Board.modal.close();
        }
      })
    )
  )
);

Modular.Module.List = (UI, startOpened = null, only = null, onClick = Modular.Module, visible = true, opts = {} ) => ui`List`({
  title: (item) => {
    const defaultTitle = UI.Modules.Page[item.type]?.displayName || item.type;
    const customTitle = _.get(item, 'data.settings.title');
    if (customTitle) {
      return `${customTitle} - ${defaultTitle}`;
    }
    return  defaultTitle;
  },
  singular: 'Module',
  minimal: true,
  immutable: _.isArray(only) && _.isEmpty(only),
  onClick,
  visible,
  onAdd: (add) => Board.modal.open({
    title: 'Add Module',
    preface: 'Select a module type to add:',
    body:
      UI.Modules.Categories[Board.build.moduleType] ?
        // tabbed
        ui`Tabs`.of(
          _.mapValues(UI.Modules.Categories.Page, (category) => ({
            label: category,
            pane: moduleGridDefinition(UI, add, only, Board.build.moduleType, category)
          }))
        )
        // untabbed
        : moduleGridDefinition(UI, add, only, Board.build.moduleType)
  }),
  actions: [
    {
      label: 'Edit',
      icon: 'pencil-square-o',
      onClick: Modular.Module
    },
    {
      label: 'Settings',
      icon: 'cogs',
      onClick: Modular.ModuleSettings
    }
  ],
  startOpened,
  opts,
});


// Renderer
Modular.Renderer = (UI, layout, parameters={}) => ({
  contents: ({ value }) => <>
    <div css={css`
      padding: ${ Board.editable ? 32 : 0 }px 0px 0px 0px;

      @media ( max-width: ${ UI.Viewport.Width(UI.Viewport.Device.Desktop) - 1 }px ) {
        padding: ${ Board.editable ? 24 : 0 }px 0px 0px 0px;
      }
    `} >
      <Subschema>
        {{
          'layout/Header': _.mapValues(_.fromPairs(_.sortBy(_.toPairs(value?.layout?.Header), p => layout?.Header?.indexOf(p[0]))), () => Modular.Renderer.Layout),
          sections: () => <div>
            <Subschema>
              {
                ui`List|view`.of([
                  Modular.Renderer.Section,
                  _.merge({}, parameters.section || {}, { parameters: parameters.module || {} })
                ])
              }
            </Subschema>
          </div>,
          'layout/Additional': _.mapValues(_.fromPairs(_.sortBy(_.toPairs(value?.layout?.Additional), p => layout?.Additional?.indexOf(p[0]))), () => Modular.Renderer.Layout)
        }}
      </Subschema>
    </div>
  </>
});

const sectionHasModules = (obj) => !_.isEmpty(obj?.modules||[].filter(module => isItemVisible(module)));

const tapSectionIfVisible = (obj) => {
  if (obj && isItemVisible(obj) && sectionHasModules(obj)) {
    return obj;
  }
  return null;
}

const tapModuleIfVisible = (obj) => {
  if (obj && isItemVisible(obj)) {
    return obj;
  }
  return null;
}

Modular.Renderer.Section = ({ value, path, name, parameters, selectable=true }) => {
  const index = Number(_.last(path));
  const selector = `sections/${ index }`;

  let priorIndex = index;
  let prior = null;
  while (priorIndex > 0 && !(prior = tapSectionIfVisible(_.get(Board.Value, _.initial(path).concat(--priorIndex)))));
  prior = prior ? (prior.details || UI.Section.Defaults.details) : null;

  if (!sectionHasModules(value)) {
    return null;
  }

  return <UI.Section
    index={ index }
    details={ value.details }
    sticky={ value.sticky }
    prior={ prior }
    onClick={ selectable && Modular.Renderer.Select(selector) }
    selector={ selector }
  >
    <Subschema>{{ modules: ui`List|view`.of({ data: [ Modular.Renderer.Module, parameters ] }) }}</Subschema>
  </UI.Section>;
};

Modular.Renderer.Module = ({ path, name, selectable=true }) => {
  const index = Number(path.at(-2));
  const selector = `sections/${ path.at(-4) }/modules/${ index }`;
  const definition = UI.Modules?.[Board.build.moduleType]?.[_.get(Board.Value, _.initial(path))?.type];

  let priorIndex = index;
  let prior = null;
  while (priorIndex > 0 && !(prior = tapModuleIfVisible(_.get(Board.Value, _.initial(_.initial(path)).concat(--priorIndex).concat('data')))));

  return (
    <UI.Module
      prior={ prior }
      styles={ definition?.styles }
      gapless={ definition?.gapless }
      gutterless={ definition?.gutterless }
      selector={ selector }
      onClick={ selectable && Modular.Renderer.Select(selector) }
    >
      <Subschema>{ ui`Modules/~build:moduleType/@..:type|view` }</Subschema>
    </UI.Module>
  );
};

Modular.Renderer.Layout = ({ path, name, selectable=true, value }) => {
  const area = path.at(-2);
  const type = _.last(path);
  const selector = `layout/${ area }/${ type }`;

  let definitionPath = ['Modules', Board.build.moduleType, 'Layout', area, type];
  let definition = _.get(UI, definitionPath.join('.'));

  if (!definition) {
    definitionPath = ['Modules', 'Layout', area, type];
    definition = _.get(UI, definitionPath.join('.'));
  }

  if (!definition) {
    throw `Cannot find definition for ${Board.build.moduleType}, ${area}, ${type}`;
  }

  const present = _.get(Board.Value, path);
  if (!present && !Modular.Renderer.Layout.Defaults?.[area]?.[type]) _.set(
    Modular.Renderer.Layout,
    [ 'Defaults', area, type ],
    Board.Evaluate(definition.modes.edit)
  );

  return <UI.Module
    styles={ definition?.styles }
    gapless={ definition?.gapless }
    gutterless={ definition?.gutterless }
    selector={ selector }
    onClick={ selectable && Modular.Renderer.Select(selector) }
    data-am-region={ definition?.displayName || type }
  >
    <Subschema>{
      ui(`${ definitionPath.join('/') }|view`)({
        default: present ? {} : Modular.Renderer.Layout.Defaults[area][type]
      })
    }</Subschema>
  </UI.Module>;
};

Modular.Renderer.Select = (selector) => (event) => {
  event.stopPropagation();
  event.preventDefault();
  Board.select(selector);
  return false;
};

export default Modular;

import { Component } from "react";
import { connectLocation } from '../location/LocationConnectors';
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import getYouTubeID from 'get-youtube-id';
import Query from 'qs';
import _ from 'lodash';

// Assets
import Animations from './oc-animations.jsx';
import Icon from './oc-icon.jsx';

const STYLES = {
  CONTAINER: {
    BASE: css`
      width: 100%;
      position: relative;
      transition: opacity 0.4s;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
        background-color: #E1E1E1;
      }
    `,
    LOADING: css`
      iframe {
        pointer-events: none;
        opacity: 0.4;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -8px;
        margin-top: -8px;
        z-index: 10;
        animation: ${ Animations.spin } 1s linear infinite;
      }
    `
  },
  FRAME: css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  WARNING: css`
    background-color: #FFF1E5;
    width: 100%;
    padding: 12px 16px;
    margin-top: 8px;
    font-size: 15px;
    line-height: 20px;
    color: #7E3900;
  `
};

// Styled Components
const OuterVideoContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

let CACHE = {};

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = { identifier: null, loading: false, data: false };

    this.load = this.load.bind(this);
    this.apply = this.apply.bind(this);
    this.error = this.error.bind(this);
  }

  load(identifier) {
    const url = `/${ this.props.locale?.code?.toLowerCase?.() || 'en-us' }/video/metadata/${ identifier }`;
    if (CACHE[url]) return this.apply(CACHE[url], identifier);

    this.setState({ identifier, loading: url, data: false });

    fetch(url).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json().then(reply => {
          CACHE[url] = reply;
          this.apply(reply, identifier);
        });
      } else {
        this.error(response);
      }
    }).catch(exception => {
      this.error(exception);
    });
  }

  apply(meta, identifier) {
    this.setState({
      identifier,
      loading: false,
      data: meta
    });
  }

  error(message) {
    console.error("[VIDEO META] Error loading video meta:", message);
    this.setState({ loading: false, data: false });
  }

  render() {
    const editable = typeof(Board) === 'undefined' ? false : Board?.editable;

    const { src, parameters={}, t, className, css, ...props } = this.props;
    if (_.isEmpty(src)) return null;

    const parts = src.split('?');

    const identifier = parts[0].includes('youtube.com') ? getYouTubeID(parts[0]) : parts[0];
    if (!editable && !this.state.loading && this.state.identifier != identifier) this.load(identifier);

    return (
      <OuterVideoContainer className={ className } css={ css } >
        <div css={ [ STYLES.CONTAINER.BASE, this.state.loading ? STYLES.CONTAINER.LOADING : null ] }>
          { this.state.loading ? <Icon type="spinner" /> : null }
          <iframe
            css={ STYLES.FRAME }
            src={ `https://www.youtube.com/embed/${identifier}?${Query.stringify(parameters ? parameters : Query.parse(parts[1], { ignoreQueryPrefix: true }))}` }
            title={ _.get(this.state.data, 'title', 'loading video data...') }
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            data-track="view-video"
          ></iframe>
        </div>
        {(!editable && !this.state.loading && _.get(this.state.data, 'seizure_warning', true)) &&
          <div css={ STYLES.WARNING } >
            <b><Icon type="exclamation-triangle" /> { t('owenscorning.components.video.warning.preface') }</b> { t('owenscorning.components.video.warning.copy') }
          </div>
        }
      </OuterVideoContainer>
    );
  }
}

export default connectLocation(Video);

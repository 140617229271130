import {css} from '@emotion/react';

// Basic formCSS. I would recommend using this as a composite css, example:
// const basicInputField = css`
// input {
//   border: 1px solid pink;
// }
// `
// const basicFormCSS = css`
//   ${basicInputField}
// `

const fullBox = css`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const basicFormCSS = css`
  legend {
    display: none;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .form-group {
    ${fullBox}
    margin-bottom: 1em;
  }

  .horizontal-input > div:not([class]) {
    display: flex;
    gap: 30px;

    @media (max-width: 600px) {
      display: block;
      gap: 0;
    }
  }

  .horizontal-input > div:not([class]) > div[class] {
    display: none;
  }

  .horizontal-input > div:not([class]) > div:not([class]) {
    flex-grow: 1;
    flex-basis: 0;

    @media (max-width: 600px) {
      flex-grow: 0;
    }
  }

  .horizontal-radio .field-radio-group {
    display: flex;
    gap: 30px;
  }

  .horizontal-checkbox > .checkboxes {
    display: flex;
    gap: 30px;
  }

  label {
    ${fullBox}
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0.5em;

    &.checkbox-label {
      align-items: flex-start;
      display: inline-flex;
      font-weight: 400;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    ${fullBox}
    border: 1px solid #D1D1D1;
    font-family: 'OCRoboto', sans-serif;
    font-size: 1em;
    padding: 15px 10px;
  }

  textarea {
    min-height: 150px;
    resize: none;
  }

  .btn-group {
    display: block !important;

    .glyphicon-remove::after {
      content: "Remove";
    }

    .glyphicon-arrow-down::after {
      content: "Move down";
    }

    .glyphicon-arrow-up::after {
      content: "Move up";
    }
  }

  .glyphicon-plus::after {
    content: "Add";
  }

  .m-signature-pad {
    position: relative;
    font-size: 10px;
    width: 100%;
    height: 250px;
    top: 50%;
    left: 50%;
    margin-left: -350px;
    margin-top: -200px;
    border: 1px solid black;
    background-color: #f5f5f5;
    border-radius: 0;
  }

  .m-signature-pad--body {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 60px;
    border: 1px solid #ccc;
    background: white;
  }

  .m-signature-pad--body
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .m-signature-pad--footer {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 40px;
  }

  .m-signature-pad--footer
  .description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

  .m-signature-pad--footer
  .button {
    position: absolute;
    bottom: 0;

    &:after {
      display: none;
    }
  }

  .m-signature-pad--footer
  .button.clear {
    right: 0;
  }

  .m-signature-pad--footer
  .button.save {
    right: 0;
  }

  [type="radio"] {
    margin-top: 0;
  }

  .radio > label > span {
    display: flex;
  }

  .checkboxes label.checkbox-label > input[type="checkbox"],
  .checkboxes label.checkbox-label > div {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .checkboxes label.checkbox-label {
    display: flex;
    align-items: center;
  }

  //@media screen and (max-width: 1024px) {
	.m-signature-pad {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		min-width: 250px;
		min-height: 150px;
		margin: 0 0 25px;
	}
	#github {
		display: none;
	}
  // }

  //@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  //	.m-signature-pad {
  //		margin: 10%;
  //	}
  //}

  @media screen and (max-height: 320px) {
    .m-signature-pad--body {
      left: 0;
      right: 0;
      top: 0;
      bottom: 32px;
    }
    .m-signature-pad--footer {
      left: 20px;
      right: 20px;
      bottom: 4px;
      height: 28px;
    }
    .m-signature-pad--footer
    .description {
      font-size: 1em;
      margin-top: 1em;
    }
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea, select {
    border-color: #d1d1d1;
    width: 100%;
    padding 15px 10px;
  }

  select,
  [type='url'],
  [type='date'] {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }

  [type='radio'] {
    margin-right: 8px;
  }

  [type='checkbox'],
  [type='radio'] {
    color: #d40f7d;
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    --tw-ring-color: #d40f7d;
    border-color: #d40f7d;
  }

  [type='text']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  [type='url']:focus,
  [type='email']:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: #D40F7D;
    border-color: #D40F7D;
}
`

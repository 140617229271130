import _ from 'lodash';
import { ui, always, Scaffolding } from '@owenscorning/pcb.alpha';
import Modular from '../Modular';
import Page from '../Page';

const { Validate } = Scaffolding;

const Article = (type, dataset, additional, taxonomies, overrides) => Modular(
  type, 'Article',
  _.merge({},
    {
      // custom things for Articles here
      read: ({contents, metadata}) => {
        return {
          contents: _.merge({}, { layout: { Header: { Hero: {} }, Additional: {
            RelatedArticles: {
              type: {
                source: 'data'
              },
              data: {
                dataset: dataset,
                parameters: { source: "recent", category: "All", recentCount: "3" }
              },
              copy: {
                prehead: '',
                heading: 'Related Articles',
                bodyCopy: ''
              },
              settings: {
                itemsPerRow: 3,
                itemsPerRowTablet: 2,
                aspectRatio: {
                  selection: '2'
                }
              }
            },
            SocialMediaShareBanner: {
              channels: {
                facebook: true,
                twitter: true,
                pinterest: true,
                linkedin: true
              },
              background: {
                color: 'dark'
              },
              content: {
                text: 'Share this page on',
                shareMessage: 'Check out what I found on Owens Corning!'
              }
            }
          } } }, contents),
          metadata
        };
      },
      write: ({contents, metadata}) => {
        return {
          contents,
          metadata
        }
      },
      list: (UI) => ({
        sections: { '0': {
            details: { name: always`Body` },
            modules: ui`List/Item`.of(Modular.Module.List(UI))({
              title: 'Body',
              dropdown: false
            })
          } },
        'layout/Additional': Modular.Layout.List(UI, 'Additional', additional, { dropdown: false })
      }),
      settings: {
        details: ui`List/Item`.of(ui`Form`.of({
          information: ui`Form`.of({
            title: ui`Text`({ label: 'Title', maxLength: '100', flags: { Right: ['100 character limit'] } }),
            description: ui`Text`({ multiline: true, maxLength: '280', flags: { Right: ['280 character limit'] }, validation: Validate.Maximum(280), label: 'Description' }),
            published: ui`Date`({ label: 'Date Published', format: 'ISODateOnly' }),
            writer: ui`Text`({ label: 'Written By' })
          }),
          image: ui`Image`({
            label: 'Featured Image',
            crop: true,
            sublabel: 'This image will be used as a preview throughout blog list pages.'
          }),
          taxonomies
        }))({
          title: 'Article Information',
          startsOpen: true
        }),
        ..._.omit(Page.Settings, 'details')
      },
      variables: (UI) => ({
        Gap: {
          [UI.Viewport.Device.Desktop]: 32,
          [UI.Viewport.Device.Tablet]: 24
        }
      }),
      selection: {
        in: (selection) => selection == 'sections/0' ? false
          : selection == 'layout/Header/Hero'
            ? { ...Modular.Selection.in(), sidebar: 'settings' }
            : { ...Modular.Selection.in(selection), sidebar: 'builder' },
        out: (focus) => Board.sidebar == 'settings'
          ? 'layout/Header/Hero'
          : Modular.Selection.out(focus)
      },
      onTab: (tab) => Board.select(Board.definition.selection.in(tab == 'settings' ? 'layout/Header/Hero' : null)),
      layout: { Header: [ 'Hero' ], Additional: additional },
      view: (UI, layout) => Modular.Renderer(UI, layout, { section: { selectable: false } }),
    },
    overrides
  )
)

export default Article;

import { board, ui, select } from '@owenscorning/pcb.alpha';
import { useState, useEffect } from 'react';
import ReactOnRails from 'react-on-rails';
import Postie from './Postie';
import _ from 'lodash';

import ResizeObserver from 'resize-observer-polyfill';
import Library from './Library';
import Integration from './Redux';

import GlobalThemeStyles from '../../themes/globalThemeStyles';
// connecting to redux manually for now
import { cmsRenderConnector } from '../PageBuilder/redux/connector';
import { connectLocation } from '../location/LocationConnectors';
import { Provider } from 'react-redux';

import { navigate } from '../helpers/sticky_nav';
import joinPath from '../../helpers/joinPath';

const Renderer = board`Builder::Renderer`('0.0.1')(
  Library,
  ({ editable, contentType, content_info, isComparison }) => {
    const Type = _.get(UI.Types, contentType.replace('Cms::Content::','').split('::'))(UI);
    const Editor = {};

    if (editable) {
      Editor.bridge = Postie.Child();
      Editor.bridge.on('select', (selection) => Board.select?.(selection, false));
      Editor.bridge.on('data', (data) => Editor.receive?.(data));
      Editor.bridge.send('ready');
    } else if (isComparison) {
      Editor.bridge = Postie.Child();
      Editor.bridge.send('ready');
    }

    // TODO: use the localized name (from content_info.language_summary should be already passed)
    const Content = {
      name: content_info?.name,
      info: content_info,
    };

    return { Type, Content, Editor };
  },
  ({
    Type, Content, Editor,
    editable,
    locale: { code: language },
    contentType,
    displayContents,
    metadata,
    linkedObject,
    isComparison,
    zipFile
  }) => {
    const { view, variables, ...definition } = Type;
    const build = {
      type: contentType,
      name: Content.name,
      site: Content.info?.site,
      location: Content.info?.path,
      slug: Content.info?.slug,
      path: joinPath(Content.info?.path, Content.info?.slug),
      language
    };

    const pageContents = _.cloneDeep(displayContents) || {};
    const pageMetadata = _.cloneDeep(metadata) || {};

    const [ value, setValue ] = useState(Type.read({ build, contents: pageContents, metadata: pageMetadata, zipFile }));
    Editor.receive = setValue;

    if (editable || isComparison) {
       useEffect(() => {
        const observer = new ResizeObserver(([ body ]) => Editor.bridge.send('height', body.target.offsetHeight));
        observer.observe(document.getElementsByTagName('html')[0]);
        return () => observer.disconnect();
      }, []);
    } else {
      useEffect(() => {
        if (window.location.hash) {
          const hash = window.location.hash.substring(1);
          navigate(hash)
        }
      }, []);
    }

    return {
      value: Library.User.Filter(value),
      variables: _.merge({}, {
        editable,
        definition,
        language,
        bridge: Editor.bridge,
        settings: value.metadata?.settings || {},
        build,
        selection: null,
        select: (selection, push=true) => {
          if (!editable) return;
          if (push) Editor.bridge.send('select', selection);
          const element = document.getElementById(selection);
          if (element) Editor.bridge.send('position', element.getBoundingClientRect().top + window.scrollY);
          Board.Set({ selection });
        }
      }, variables),
      schematic: {
        modals: ui`Modals`({ open: select`~modal/stack` }),
        ...view
      }
    };
  }
);

const ConnectedRenderer = cmsRenderConnector(connectLocation(Renderer));
const ProvidedRenderer = ( props ) => (
  <Provider store={ ReactOnRails.getStore("CmsStore") }>
    <GlobalThemeStyles />
    <ConnectedRenderer { ...props } />
  </Provider>
);

export default ProvidedRenderer;

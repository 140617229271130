import styled from '@emotion/styled';
import React, {useState, useEffect, useRef} from 'react';

// Components
import CalculatorForm from './SprayFoamVentilationForm';
import CalculatorResults from './SprayFoamVentilationResults';

const CalculatorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const CalcFormContainer = styled.div`
  background: #e6e6e6;
	background-size: 250px;
	padding: 15px 15px 165px;
	width: 100%;

	@media(min-width: 768px) {
		width: 33.333%;
		background-size: 150px;
		padding: 15px 15px 100px;
	}

	@media(min-width: 1200px) {
		background-size: 250px;
	}
`;

const CalcResultsContainer = styled.div`
	width: 100%;

	@media(min-width: 768px) {
		width: 66.666%
	}
`;

const SprayFoamVentilationCalculator = ({airChangesPerHour='10'}) => {
	const [post, setPost] = useState({['shape']: 'triangle', ['airChangesPerHour']: airChangesPerHour});
	const [info, setInfo] = useState({});
	const [resultsVisible, setResultsVisible] = useState(false);
	const scrollToDiv = useRef();

	let estairChangesPerHour;

	useEffect(() => {
		estairChangesPerHour = post.airChangesPerHour ? post.airChangesPerHour : airChangesPerHour;
	}, [post]);

	useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const scrollHandler = () => {
    if(window.pageYOffset + window.innerHeight >= scrollToDiv.current.offsetTop) {
    	setResultsVisible(true);
    } else {
    	setResultsVisible(false);
    }
  }

	const inputChange = (e) => {
		const { name, value } = e.target;

    setPost(prevState => (
      { ...prevState, [name]: value }
    ));
	};

	const formSubmit = (e) => {
		e.preventDefault();

    const validLength = post.length && !isNaN(post.length) && post.length > 0;
    const validWidth = post.width && !isNaN(post.width) && post.width > 0;
    const validHeight = post.height && !isNaN(post.height) && post.height > 0;
    const validACH = post.airChangesPerHour && !isNaN(post.airChangesPerHour) && post.airChangesPerHour >= 10;

		if(validLength && validWidth && validHeight && validACH) {

      const fan12InchCFM = 2200;
      const fan10InchCFM = 1500;
      const fan8InchCFM = 600;

      let areaInSquare = post.length * post.width;
      let volInCubicFeet = areaInSquare * post.height
      if(post.shape == 'triangle') {
        volInCubicFeet = volInCubicFeet / 2;
      }
      let airChangesPerMinute = estairChangesPerHour / 60;
      let minFanSize = volInCubicFeet * airChangesPerMinute;

      let fansRequirement12Inch = Math.ceil(minFanSize / fan12InchCFM);
      let fansRequirement10Inch = Math.ceil(minFanSize / fan10InchCFM);
      let fansRequirement8Inch = Math.ceil(minFanSize / fan8InchCFM);

      if(fansRequirement12Inch < 1) {
        fansRequirement12Inch = 1;
      }
      if(fansRequirement10Inch < 1) {
        fansRequirement10Inch = 1;
      }
      if(fansRequirement8Inch < 1) {
        fansRequirement8Inch = 1;
      }

      setInfo(prevState => (
	      { ...prevState,
	      	['minFanSize']: minFanSize,
	      	['fansRequirement12Inch']: fansRequirement12Inch,
	      	['fansRequirement10Inch']: fansRequirement10Inch,
	      	['fansRequirement8Inch']: fansRequirement8Inch,
	      }
	    ));

	    setPost(prevState => ({
			  ...prevState,
			  ['lengthError']: null,
			  ['widthError']: null,
        ['heightError']: null,
        ['ACHError']: null
			}));

	    const element = scrollToDiv.current;
		  const headerOffset = 110;
		  const elementPosition = element.getBoundingClientRect().top;
		  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	    {!resultsVisible &&
		    window.scrollTo({
		      top: offsetPosition,
		      behavior: "smooth"
		    });
	    }

	  } else {
	  	setPost(prevState => ({
			  ...prevState,
			  ['lengthError']: validLength ? null : "Please enter a valid length",
			  ['widthError']: validWidth ? null : "Please enter a valid width",
			  ['heightError']: validHeight ? null : "Please enter a valid height",
        ['ACHError']: validACH ? null : "Please enter a valid ACH"
			}));
	  }
	};

	return (
    <>
      <p>Keep installers safe! Let us help you calculate how much ventilation is needed during a spray foam insulation project.</p>
      <CalculatorContainer>
        <CalcFormContainer>
          <CalculatorForm inputChange={inputChange} post={post} formSubmit={formSubmit}/>
        </CalcFormContainer>

        <CalcResultsContainer ref={scrollToDiv} >
          <CalculatorResults
            minFanSize={info.minFanSize}
            fansRequirement12Inch={info.fansRequirement12Inch}
            fansRequirement10Inch={info.fansRequirement10Inch}
            fansRequirement8Inch={info.fansRequirement8Inch}
          />
        </CalcResultsContainer>
      </CalculatorContainer>
    </>
	)
};

export default SprayFoamVentilationCalculator;

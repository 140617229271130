import { apiGet } from '../../../PageBuilder/helpers/api';
import { formatDatetime } from '../../../PageBuilder/helpers/time';
import joinPath from '../../../../helpers/joinPath';
import _ from 'lodash';
import qs from 'qs';

let searchAbortController;

export default {
  Search: {
    name: 'Attributes',
    meta: {},
    search: async ({ filter } = {}) => {
      // if (!filter) {
      //   return null;
      // }
      if (searchAbortController) searchAbortController.abort();
      searchAbortController = new AbortController();
      const language = Board.build.language;
      const query = {
        filter: {
          type: 'Cms::Content::Attribute',
          language_iso_code: language,
          published: true,
        },
        fields: {
          '*': 'name,language_summary',
        },
        q: filter,
        include: 'users'
      }
      const response = await apiGet(`/api/v2/cms/sites/${PB_SITE}/contents?${qs.stringify(query)}`, { signal: searchAbortController.signal });
      const userMap = _.keyBy(response.included, 'id');
      return response.data.map(r => (
        {
          content_uuid: r.id,
          'Content Name': r.name,
          'Last Published': <span>{ userMap[r.language_summary[language]?.published_by]?.name || 'Unknown' }<br/>{ formatDatetime(r.language_summary[language]?.published_at) }</span>,
          Location: r.language_summary[language]?.published_route
        }
      ))
    },
    preview: (result) => result && <p>{ result.name } | <a target="_blank" href={ `/en-us/cms/contents/edit/${ result.id }/${ Board.build.language.toLowerCase() }` }>Edit Master</a></p>
  }
}

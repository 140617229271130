/* eslint-disable react/prop-types */
import _ from 'lodash';

import { makeCountryOption, CountryPicker } from './CountryPicker';
import { InputWrapper } from './InputWrapper';
import { makeLanguageOption, LanguagePicker } from './LanguagePicker';
import { ZipPostalPicker } from './ZipPostalPicker';
import withDefaultPrevented from '../../../withDefaultPrevented';
import Button from '../../ComponentLibrary/input-elements/oc-form-button'
import Conditional from '../../Conditional';
import ChevronLink from '../../OC/oc-chevron-link';
import { makeT, getZipInfo, localeSort } from '../locales';

const BrowseAllLocalesLink = ({ baseUrl, text }) => (
  <ChevronLink
    target="_parent"
    analytics={{
      track: 'learn-more',
      destination: 'oc | locations-languages',
    }}
    url={`${baseUrl || ''}/locations-languages`}
  >
    {text}
  </ChevronLink>
);

const SubmitButton = ({ text }) => (
  <Button type="submit" data-track="change-zip">
    {text}
  </Button>
);

const TextContents = ({ mismatched, t }) => {
  if (mismatched) {
    return (
      <>
        <h2>{t('mismatched_locale_title')}</h2>
        <p>{t('mismatched_locale_text')}</p>
      </>
    );
  }
  return (
    <>
      <h2>{t('title')}</h2>
      <p>{t('text')}</p>
    </>
  );
};

const Contents = ({
  baseUrl,
  mismatched,
  onSubmit,
  onChangeZip,
  showLocale,
  showZip,
  translationPrefix,
  error,
  locale,
  t,
  availableLocales,
  zip,
  setLocaleCode,
}) => {
  const userT = mismatched ? makeT(locale.code) : t;
  const prefix = translationPrefix ? `.${translationPrefix}` : '';
  const prefixT = key =>
    userT(`owenscorning.components.region_selector${prefix}.${key}`);

  const localeCode = locale.code;
  const [languageCode, countryCode] = localeCode.split('-');
  const localesByCode = _.reduce(
    availableLocales,
    (acc, l) => {
      acc[l.code] = l;
      return acc;
    },
    {}
  );
  const setCountryCode = c => {
    let nextCode = `${languageCode}-${c}`;
    // if we can use the same language, do that
    if (localesByCode[nextCode]) {
      setLocaleCode(nextCode);
    } else {
      // have to find a valid language for the next country
      nextCode = Object.keys(localesByCode).find(k => k.split('-')[1] === c);
      setLocaleCode(nextCode);
    }
  };
  const setLanguageCode = c => setLocaleCode(`${c}-${countryCode}`);
  const countryOptions = {};
  const languageOptions = {};
  availableLocales.sort(localeSort).forEach(l => {
    const [lCode, cCode] = l.code.split('-');
    // prefer to show country name in the language of the current locale
    // if we don't have it in the current locale, the locale sort order will break the tie (since it's here first)
    // technically, each country has a preferred language, which would be ideal to use
    // still, we could define that preference via the locale sort order
    if (!countryOptions[cCode] || lCode === languageCode) {
      countryOptions[cCode] = makeCountryOption(l);
    }
    if (cCode === countryCode) {
      languageOptions[lCode] = makeLanguageOption(l);
    }
  });

  const zipInfo = getZipInfo(localeCode);
  // if no zip format, can't show zip, even if we want to
  if (!zipInfo) {
    showZip = false;
  }

  // if neither option is set (showLocale or showZip) must mean there is one locale and we don't want (or can't get) a location
  // we have to show locale in this case
  if (!showZip && !showLocale) {
    showLocale = true;
  }

  return (
    <form onSubmit={withDefaultPrevented(onSubmit)}>
      <TextContents mismatched={mismatched} t={prefixT} />
      <Conditional if={showLocale}>
        <CountryPicker
          options={Object.values(countryOptions)}
          value={countryOptions[countryCode]}
          setCountry={setCountryCode}
          label={prefixT('select_region')}
        />
      </Conditional>
      <Conditional if={showZip}>
        <ZipPostalPicker
          zipInfo={zipInfo}
          onChangeZip={onChangeZip}
          userT={userT}
          error={error}
          zip={zip}
        />
      </Conditional>
      <Conditional if={showLocale}>
        <LanguagePicker
          options={Object.values(languageOptions)}
          setLanguage={setLanguageCode}
          value={languageCode}
          label={prefixT('select_language')}
        />
      </Conditional>
      <InputWrapper>
        <BrowseAllLocalesLink baseUrl={baseUrl} text={prefixT('browse_all')} />
      </InputWrapper>
      <SubmitButton
        text={userT(`owenscorning.components.region_selector.submit`)}
      />
    </form>
  );
};

export default Contents;

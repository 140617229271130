import ResultBlock from '../../../../ComponentLibrary/filter-result-blocks/oc-result-block';
import _ from "lodash";
import HierarchicalFilter from "../../../../ComponentLibrary/filter-tree/oc-hierarchical-filter";
import CheckboxSelector from "../../../../ComponentLibrary/filter-list/oc-filters";

const itemListResponse = ({ items, filters, Component, searchFields, enableSearch, ...rest }) => (
  {
    lists: [{ name: 'results', data: items }],
    filters,
    Component: ({ aspectRatio, ...props }) => <ResultBlock><Component aspectRatio={ aspectRatio } { ...props } /></ResultBlock>,
    searchFields,
    enableSearch,
    ...rest
  }
)

const createFilterOptionsFromResponse = (response) => (
  (response||[]).map(option => _.merge({}, { value: option.slug || option.value, displayName: option.proper_name || option.label, name: option.proper_name || option.label }, option.children ? { children: createFilterOptionsFromResponse(option.children) } : {}))
)

export const generateFilterOptions = (showFilters, enabledFilters, allFilters, filterOverrideData) => {
  const filtersForUse = _.isArray(enabledFilters) ? enabledFilters : enabledFilters?.enabledFilters
  return (
    (showFilters && !_.isEmpty(filtersForUse) && !_.isEmpty(allFilters)) ? Object.fromEntries(
      filtersForUse.map(v => {
        const filterData = allFilters?.[v];
        if (filterData) {
          const { of, label, plural, singular } = filterData;

          const anyChildren = _.some(of, (value) => Array.isArray(value.children) ? value.children?.length : value.children);
          return (
            [v, _.merge({
                component: anyChildren ? HierarchicalFilter : CheckboxSelector,
                displayName: label || singular,
                options: createFilterOptionsFromResponse(of),
                displayLimit: 5,
                valueType: 'multiple',
                getter: (item, attribute) => _.get(item, attribute)
              },
              filterOverrideData ? filterOverrideData(v, of) : {},
              anyChildren ? { props: { root: { name: `All ${plural}` } } } : {}
            )
            ]
          )
        }
      }).filter(v => v)
    ) : null
  )
}
export default itemListResponse

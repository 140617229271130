import _ from "lodash";
import produce from "immer";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CMS_APPLY_CONFLICT,
  CMS_EDIT_CONTENT,
  CMS_EDIT_METADATA,
  CMS_SET_CONTENTS,
} from "../../Actions";
import { config } from "../config";
import { toast } from "../helpers/toast";
import { generatePatch, findConflicts } from "../helpers/patch";

import { fetchUsingJWT } from '../../../jwt';

const actionAllowlist = [CMS_SET_CONTENTS, CMS_EDIT_CONTENT, CMS_EDIT_METADATA];

const SAVE = 'CMS/saveStatus/SAVE';
const SAVE_COMPLETE = 'CMS/saveStatus/SAVE_COMPLETE';
const SAVE_CONFLICT = 'CMS/saveStatus/SAVE_CONFLICT';
const SAVE_FAILED = 'CMS/saveStatus/SAVE_FAILED';
const SAVE_FAILED_AUTH = 'CMS/saveStatus/SAVE_FAILED_AUTH';

const PUBLISH = 'CMS/saveStatus/PUBLISH';
const PUBLISH_COMPLETE = 'CMS/saveStatus/PUBLISH_COMPLETE';
const PUBLISH_FAILED = 'CMS/saveStatus/PUBLISH_FAILED';
const PUBLISH_FAILED_AUTH = 'CMS/saveStatus/PUBLISH_FAILED_AUTH';

// shape of redux cms state:
/*
{
  saveStatus: {
    dirty: true/false,
    saving: true/false,
    publishing: true/false,
    broken: true/false, // means page must be reloaded
    savingActions: [],  // actions that are currently on their way to server
    pendingActions: [], // actions waiting to send to server
    current_version_id: 1,
    currentVersionContent: // copy of content state at "current_version_id"
    timestamp: 1611286089242,
    updated_by: { id: 1, name: 'Test Admin' }
  }
}
 */

const beforeUnloadListener = (event) => {
  event.preventDefault();
  return event.returnValue = 'You have unsaved data, are you sure you want to leave?';
};

const manageDirtyWarning = (dirty) => {
  // disable the dirty test on dev because it stinks for HMR
  if (process.env.NODE_ENV !== 'development') {
    dirty ? addEventListener("beforeunload", beforeUnloadListener, {capture: true}) : removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
  }
}

const saveStatusReducer = {
  saveStatus: produce((draft, action) => {
    switch (action.type) {
      case PUBLISH: {
        draft.publishing = true;
        break;
      }
      case PUBLISH_COMPLETE: {
        draft.publishing = false;
        toast.success('Publish successful!');
        break;
      }
      case PUBLISH_FAILED: {
        draft.publishing = false;
        toast.error(`Publish failed. The message returned from the server was: ${action.message}.`, {toastId: action.message});
        break;
      }
      case PUBLISH_FAILED_AUTH: {
        draft.broken = true;
        draft.publishing = false;
        if (!draft.authToastId) {
          draft.authToastId = toast.warn(<div>Your session has been lost.  Changes cannot be saved.  Please click <a href="javascript:location.reload()">here</a> to reload and try again.</div>, {autoClose: false, closeButton: false});
        }
        break;
      }
      case SAVE: {
        draft.saving = true;
        draft.savingActions = [...draft.pendingActions];
        draft.pendingActions = [];
        break;
      }
      case SAVE_COMPLETE: {
        draft.dirty = false;
        manageDirtyWarning(false);
        draft.saving = false;
        draft.savingActions = [];
        draft.current_version_id = action.current_version_id;
        draft.currentVersionContent = action.currentVersionContent;
        draft.timestamp = action.timestamp;
        draft.updated_by = action.updated_by;
        toast.success('Save successful!');
        break;
      }
      case SAVE_CONFLICT: {
        if (action.conflicted) {
          draft.broken = true;
          draft.saving = false;
          draft.publishing = false;
          if (!draft.conflictToastId) {
            draft.conflictToastId = toast.warn(`${action.updated_by?.name || 'Another user'} has made changes to this content.  Changes cannot be made.  Please reload and try again.`, {autoClose: false, closeButton: false});
          }
        } else {
          draft.saving = false;
          draft.publishing = false;
          draft.pendingActions.unshift(...draft.savingActions);
          draft.savingActions = [];
          draft.current_version_id = action.current_version_id;
          draft.currentVersionContent = action.currentVersionContent;
          draft.timestamp = action.timestamp;
          draft.updated_by = action.updated_by;
        }
        break;
      }
      case SAVE_FAILED: {
        draft.saving = false;
        draft.pendingActions.unshift(...draft.savingActions);
        draft.savingActions = [];
        toast.error(`Save failed. The message returned from the server was: ${action.message}.`, {toastId: action.message});
        break;
      }
      case SAVE_FAILED_AUTH: {
        draft.broken = true;
        draft.saving = false;
        draft.pendingActions.unshift(...draft.savingActions);
        draft.savingActions = [];
        if (!draft.authToastId) {
          draft.authToastId = toast.warn(<div>Your session has been lost.  Changes cannot be saved.  Please click <a href="javascript:location.reload()">here</a> to reload and try again.</div>, {autoClose: false, closeButton: false});
        }
        break;
      }
      default: {
        if (actionAllowlist.indexOf(action.type) !== -1) {
          draft.dirty = true;
          manageDirtyWarning(true);
          draft.pendingActions.push(action);
        }
      }
    }
  }, null)
};

export default saveStatusReducer;

const sendPendingActions = (dispatch, getState) => {
  const { cms: { content, saveStatus: { currentVersionContent, current_version_id } } } = getState();
  const flattenedCurrentVersionContent = { metadata: currentVersionContent.metadata, contents: currentVersionContent.contents };
  const flattenedContent = { metadata: content.metadata, contents: content.contents };
  const body = {
    current_version_id,
    type: 'full',
  };
  if (body.type === 'patch') {
    const patch = generatePatch(flattenedCurrentVersionContent, flattenedContent)
    _.merge(body, { patch });
  } else {
    const full = flattenedContent;
    _.merge(body, { full });
  }
  return fetchUsingJWT(`${location.pathname}/save`, {
    unauthenticated: () => {
      dispatch({type: SAVE_FAILED_AUTH});
    },
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify(body)
  })
    .then(async response => {
      // ok or conflict
      if (response && (response.status === 200 || response.status === 409)) {
        const json = await response.json();
        const { current_version_id, content: newVersionContent, timestamp, updated_by } = json;       
        if (response.status === 409) {
          if (body.type === 'patch') {
            // TODO - get "currentVersionContent" to content state slice
            // patch is our diffs from last known version id to attempted save
            // currentVersionConflict as returned from json is new last known state
            const their_patch = generatePatch(flattenedCurrentVersionContent, newVersionContent);

            // simplistic conflict resolution, if any operations touch same path/tree raise error
            const conflicted = findConflicts(patch, their_patch);
            dispatch({type: SAVE_CONFLICT, current_version_id, currentVersionContent: newVersionContent, timestamp, updated_by, conflicted})
            if (!conflicted) {
              // no conflicts so we ought to be able to retry our save against the new content
              dispatch({type: CMS_APPLY_CONFLICT, their_patch})
              throttledSave(dispatch, getState);
            }
          } else {
            dispatch({type: SAVE_CONFLICT, current_version_id, currentVersionContent: newVersionContent, timestamp, updated_by, conflicted: true})
          }
        } else {
          dispatch({type: SAVE_COMPLETE, current_version_id, currentVersionContent: content, timestamp, updated_by });
        }
      } else {
        throw new Error('Network response was not ok');
      }
    })
    .catch(e => {
      dispatch({type: SAVE_FAILED, message: e.message});
    })
};

export const saveAction = (callback = null) => (dispatch, getState) => {
  const { saving } = _.get(getState(), 'cms.saveStatus')
  if (!saving) {
    dispatch({type: SAVE});
    return sendPendingActions(dispatch, getState).then(() => {
      const {pendingActions, broken} = _.get(getState(), 'cms.saveStatus');
      if (pendingActions.length > 0 && config.autoSave && !broken) {
        return setTimeout(() => throttledSave(dispatch, getState), 0);
      }
    }).then(() => {
      const {pendingActions, broken} = _.get(getState(), 'cms.saveStatus');
      if ( pendingActions.length === 0 && !!callback && !broken) {
        callback();
      }
    });
  }
}

const throttledSave = _.throttle((dispatch, getState) => {
  saveAction()(dispatch, getState);
}, 5000, {leading: false});

const sendPublish = (dispatch, getState) => {
  const { cms: { content, saveStatus: { currentVersionContent, current_version_id } } } = getState();
  const flattenedCurrentVersionContent = { metadata: currentVersionContent.metadata, contents: currentVersionContent.contents };
  return fetchUsingJWT(`${location.pathname}/publish`, {
    unauthenticated: () => {
      dispatch({type: PUBLISH_FAILED_AUTH});
    },
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      current_version_id
    })
  })
    .then(async response => {
      // ok or conflict
      if (response && (response.status === 200 || response.status === 409)) {
        const json = await response.json();
        const { current_version_id, content: newVersionContent, timestamp, updated_by } = json;

        if (response.status === 409) {
          dispatch({type: CMS_APPLY_CONFLICT, their_contents: newVersionContent})
          toast.warn(`${action.updated_by?.name || 'Another user'} has made changes to this content.  Please try again.`, {autoClose: false})
        } else {
          dispatch({type: PUBLISH_COMPLETE, current_version_id, currentVersionContent: content, timestamp, updated_by });
        }
      } else {
        throw new Error('Network response was not ok');
      }
    })
    .catch(e => {
      dispatch({type: PUBLISH_FAILED, message: e.message});
    })
};

export const publishAction = () => (dispatch, getState) => {
  const { publishing } = _.get(getState(), 'cms.saveStatus')
  if (!publishing) {
    dispatch({type: PUBLISH});
    return sendPublish(dispatch, getState);
  }
}
export const saveStatusConnector = connect(
  (state) => ({
    saveStatus: state.cms.saveStatus,
    saving: state.cms.saveStatus.saving,
    timestamp: state.cms.saveStatus.timestamp,
    updated_by: state.cms.saveStatus.updated_by,
  }),
  (dispatch) => bindActionCreators({ save: saveAction, publish: publishAction }, dispatch)
);

export const saveStatusMiddleware = store => {
  return next => action => {
    if (actionAllowlist.indexOf(action.type) !== -1) {
      const { savingActions, broken } = _.get(store.getState(), 'cms.saveStatus');
      if (savingActions.length === 0 && config.autoSave && !broken) {
        throttledSave(store.dispatch, store.getState)
      }
    }
    next(action);
  };
}

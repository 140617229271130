import styled from '@emotion/styled';
import { H2, H3, H4, H6 } from '../../../OC/oc-h';
import { useEffect, useMemo, useRef, useCallback, useState } from 'react';

import Button from '../../oc-button';
import Checkbox from '../../input-elements/oc-checkbox';

import CopyToClipboard from 'react-copy-to-clipboard';
import LocaleSpecific from '../../../LocaleSpecific';
import { connectLocation } from '../../../location/LocationConnectors';
import SelectLanguageField from './SelectLanguageField';
import useSelectLanguageField from './SelectLanguageField/use-select-language-field-hook';


const InstructionsContainer = styled.div`

.oc-form {
  margin-bottom: 32px;
}

fieldset {
  margin-top: 24px;
}

.select-container {
  width: 100%;
}

.code-container {
  background-color: #E6E6E6;
  overflow: 'hidden';
  padding: 8px;
  word-break: 'break-word'; 
  overflow-wrap: 'break-word'; 
}

.button-container {
  margin: 24px 0 24px 0;
}

a:link {
  border-style: 'none';
}

.checkbox {
  margin: 16px 0 16px 0;
}

.checked-item {
  background-color: 'red';
  border: 4px 'solid' 'red';
}

.indent-text {
  padding-left: 32px;
}

.warranty-selection {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 32px;
}

@media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .button-container {
    margin: 32px 0 32px 0;
  }
}
`;

const H3DefaultLanguageTitle = styled(H3)`
  margin-top: 32px;
`;

const ScriptCode = '<script src="https://apis.owenscorning.com/client/widget.js" async></script>'
const DEQCode = '<div id="visualizer" data-zip="90210"> </div>'
const DAICode = '<div class="oc_design_and_inspire"></div>'
const DAIModCode = '<div slide-delay="10000" class="oc_design_and_inspire"> </div>'
const TPRSCode = '<div class="total_protection_roofing_system"></div>'


export const DesignEyeQInstructions = () => {

  return (
    <InstructionsContainer>
      <H2>Website Installation Instruction</H2>
      <H3>Step 1 of 2 - Add Javascript</H3>
      <p>
        Include this javascript in the &#60;body&#62; section of your website. We recommend that it is placed right before the final &#60;/body&#62; tag. 
        <br/>
       <strong>Note:</strong> you only have to do this once for all widgets.
      </p>
      <div className='code-container'>
          <p>
            &#60;script src="https://apis.owenscorning.com/client/widget.js" async&#62;&#60;/script&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={ScriptCode}
        className='button-container'
      >
        <Button>Copy to Clipboard</Button>
      </CopyToClipboard>
      <H3>Step 2 of 2 - Instructions</H3>
      <p>
        Copy this div and place it in the corresponding place on your site where you would like the widget to appear.
        <br/>
        <strong>Note:</strong> The “data-zip” attribute changes the default ZIP Code used for showing off the widget. Change it to reflect your area.
      </p>
      <p><strong>Example :</strong></p>
      <div className='code-container'>
          <p>
            &#60;div id="visualizer"
            data-zip="90210"&#62;
            &#60;/div&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={DEQCode}
        className='button-container'
      >
        <Button>
            Copy to Clipboard
        </Button>
      </CopyToClipboard>
      <p><strong>Please Note!</strong></p>
      <ul>
        <li>
          We recommend only using 1-3 total widget lines per page.
        </li>
        <li>
          Adding all of the widgets below adds a LOT of content to your site, which will slow down the page it appears on.
        </li>
      </ul>
    </InstructionsContainer>
  )
}

export const DesignInspireInstructions = connectLocation(({ t, locale }) => {
  const { 
    defaultLanguage, 
    onWidgetDefaultLanguageChange
  } = useSelectLanguageField({ locale });

  return (
    <InstructionsContainer>
      <H2>{t('design_inspire.widget.website_installation_instructions')}</H2>
      <H3DefaultLanguageTitle>{t('design_inspire.widget.step')} 1 {t('design_inspire.widget.of')} 3 - {t('design_inspire.widget.choose_widget_language')}</H3DefaultLanguageTitle>
      <SelectLanguageField
        defaultLanguage={defaultLanguage}
        onWidgetDefaultLanguageChange={onWidgetDefaultLanguageChange}
      />
      <H3>{t('design_inspire.widget.step')} 2 {t('design_inspire.widget.of')} 3 - {t('design_inspire.widget.add_javascript.title')}</H3>
      <p>
        {t('design_inspire.widget.add_javascript.description')} 
        <br/>
        <strong>{t('design_inspire.widget.add_javascript.note')}:</strong> {t('design_inspire.widget.add_javascript.note_text')}.
      </p>
      <div className='code-container'>
          <p>
            &#60;script src={`https://apis.owenscorning.com/client/widget.js?lang=${defaultLanguage.toLowerCase()}`} async&#62;&#60;/script&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={ScriptCode}
        className='button-container'
      >
        <Button>
          {t('design_inspire.widget.copy_to_clipboard')}
        </Button>
      </CopyToClipboard>
      <H3>{t('design_inspire.widget.step')} 3 {t('design_inspire.widget.of')} 3 - {t('design_inspire.widget.instructions.title')}</H3>
      <p>
        {t('design_inspire.widget.instructions.description')}
      </p>
      <p><strong>{t('design_inspire.widget.example')}:</strong></p>
      <div className='code-container'>
          <p>
            &#60;div class="oc_design_and_inspire"&#62;&#60;/div&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={DAICode}
        className='button-container'
      >
        <Button>
          {t('design_inspire.widget.copy_to_clipboard')}
        </Button>
      </CopyToClipboard>
      <div>
      <H3>{t('design_inspire.widget.optional_modifier.title')}</H3>
      <H6>{t('design_inspire.widget.optional_modifier.subtitle')}</H6>
      <p>
        {t('design_inspire.widget.optional_modifier.description')}
      </p>
      <div className='code-container'>
          <p>
            &#60;div
              slide-delay="10000"
              class="oc_design_and_inspire"&#62;
            &#60;/div&#62;
          </p>
        </div>
        <CopyToClipboard 
        text={DAIModCode}
        className='button-container'
      >
        <Button>
          {t('design_inspire.widget.copy_to_clipboard')}
        </Button>
      </CopyToClipboard>
      </div>
      <p><strong>{t('design_inspire.widget.footnote.title')}</strong></p>
      <ul>
        <li>
          {t('design_inspire.widget.footnote.note_1')}
        </li>
        <li>
          {t('design_inspire.widget.footnote.note_2')}
        </li>
      </ul>
    </InstructionsContainer>
  )
});

const SelectLaunguage = styled.div`
  > div {
    padding: 15px 0 15px 0;

    > select {
      padding: 10px 180px 10px 10px;
    }
  }
`

export const TotalProtectionRoofingInstructions = () => {
  const currentUrl = window.location.href;
  const languageRegex = /^https?:\/\/[^/]+\/([^/]+)\//;
  const tprsLanguage = currentUrl.match(languageRegex)[1];

  const [selectedLanguage, setSelectedLanguage] = useState(tprsLanguage === "en-us" ? "en-us" : "es-us");
  const handleLanguageChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedLanguage(selectedValue);
    sessionStorage.setItem("widget-language", selectedValue);
  };

  const isMounted = useRef(true);
  const handleStorageChange = useCallback(() => {
    const sessionLanguage = sessionStorage.getItem('widget-language');
    if (sessionLanguage) {
      if (isMounted.current) {
        setSelectedLanguage(sessionLanguage);
      }
    }
  }, []);

  useEffect(() => {
    const handleUpdate = () => {
      handleStorageChange();
    };

    handleUpdate();

    window.addEventListener('storage', handleUpdate);

    return () => {
      isMounted.current = false;
      window.removeEventListener('storage', handleUpdate);
    };
  }, [handleStorageChange]);

  const widget = useMemo(() => {
    return selectedLanguage === "en-us"
      ? "https://apis.owenscorning.com/client/widget.js"
      : "https://apis.owenscorning.com/client/widget.js?lang=es-us";
  }, [selectedLanguage]);

  const ScriptCode = selectedLanguage === "en-us" ? '<script src="https://apis.owenscorning.com/client/widget.js" async></script>' : '<script src="https://apis.owenscorning.com/client/widget.js?lang=es-us" async></script>'
  const bodyTag = `<body>`;
	const closingBodyTag = `</body>`;
  return (
    <InstructionsContainer>
      <LocaleSpecific only={['en-US']}>
      <H2>Website Installation Instruction</H2>
      <H3>Step 1 OF 3 - Choose Widget Default Language</H3>
      <SelectLaunguage>
        <label htmlFor="language">
          <b>Select Default Widget Language </b>
          </label>
        <div>
          <select id="language" value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="en-us">English</option>
            <option value="es-us">Spanish</option>
          </select>
        </div>
      </SelectLaunguage>

      <H3>STEP 2 OF 3 - ADD JAVASCRIPT</H3>
      <p>
        Include this javascript in the &#60;body&#62; section of your website. We recommend that it is placed right before the final &#60;/body&#62; tag. 
        <br/>
        <strong>Note:</strong> you only have to do this once for all widgets.
      </p>
      <div className='code-container'>
          <p>
            &#60;script src={widget} async&#62;&#60;/script&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={ScriptCode}
        className='button-container'
      >
        <Button>
            Copy to Clipboard
        </Button>
      </CopyToClipboard>
      <H3>STEP 3 OF 3 - INSTRUCTIONS</H3>
      <p>
        Copy this div and place it in the corresponding place on your site where you would like the widget to appear.
      </p>
      <p><strong>Example:</strong></p>
      <div className='code-container'>
          <p>
            &#60;div class="total_protection_roofing_system"&#62;&#60;/div&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={TPRSCode}
        className='button-container'
      >
        <Button>
            Copy to Clipboard
        </Button>
      </CopyToClipboard>
      <p><strong>Please Note!</strong></p>
      <ul>
        <li>
          We recommend only using 1-3 total widget lines per page.
        </li>
        <li>
          Adding all of the widgets below adds a LOT of content to your site, which will slow down the page it appears on.
        </li>
      </ul>
    </LocaleSpecific>

    <LocaleSpecific only={['es-US']}>
      <H2>INSTRUCCIONES DE INSTALACIÓN DEL SITIO WEB</H2>
      <H3>PASO 1 DE 3 - ELEGIR IDIOMA PREDETERMINADO DEL WIDGET</H3>
      <SelectLaunguage>
        <label htmlFor="language">
          <b>Seleccionar idioma predeterminado del widget </b>
          </label>
        <div>
          <select id="language" value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="en-us">English</option>
            <option value="es-us">Spanish</option>
          </select>
        </div>
      </SelectLaunguage>

      <H3>PASO 2 DE 3 - AÑADIR JAVASCRIPT</H3>
      <p>
        Incluya este javascript en la sección {bodyTag} de su sitio web. Recomendamos colocarlo justo antes de la etiqueta final de {closingBodyTag}.
        <br/>
        <strong>Nota:</strong> solo tiene que hacerlo una vez para todos los widgets.
      </p>
      <div className='code-container'>
          <p>
            &#60;script src={widget} async&#62;&#60;/script&#62;
          </p>
      </div>
      <CopyToClipboard
        text={ScriptCode}
        className='button-container'
      >
        <Button>
          COPIAR EN EL PORTAPAPELES
        </Button>
      </CopyToClipboard>
      <H3>PASO 3 DE 3 - INSTRUCCIONES</H3>
      <p>
        Copie este div y colóquelo en el lugar correspondiente de su sitio donde desea que aparezca el widget.
      </p>
      <p><strong>Ejemplo:</strong></p>
      <div className='code-container'>
          <p>
            &#60;div class="total_protection_roofing_system"&#62;&#60;/div&#62;
          </p>
      </div>
      <CopyToClipboard
        text={TPRSCode}
        className='button-container'
      >
        <Button>
          COPIAR EN EL PORTAPAPELES
        </Button>
      </CopyToClipboard>
      <p><strong>Tenga en cuenta lo siguiente!</strong></p>
      <ul>
        <li>
          Recomendamos utilizar solo 1-3 líneas de widget en total por página.
        </li>
        <li>
          Añadir todos los widgets abajo añade MUCHO contenido a su sitio, lo que hará más lenta la página en la que aparece.
        </li>
      </ul>
    </LocaleSpecific>
    </InstructionsContainer>
  )
}


export const WarrantyInstructions = ({checked, handleClick, vertical}) => {
  const renderCodeSnippets = useMemo(() => {
    let checkedList = checked.filter(item => item.checked == true)
    return checkedList.map(item => {
      return `${item.url}="true"`
    })
  }, [checked]).join(' ')

  const WarrantyCode = `<div ${renderCodeSnippets} class="oc_warranty"></div>`

  const handleToggle = (e) => {
    handleClick(e?.target?.name)
  }


  return (
    <InstructionsContainer>
       <H2>Website Installation Instruction</H2>
      <H3>Step 1 of 2 - Add Javascript</H3>
      <p>
        Include this javascript in the &#60;body&#62; section of your website. We recommend that it is placed right before the final &#60;/body&#62; tag. 
        <br/>
       <strong>Note:</strong> you only have to do this once for all widgets.
      </p>
      <div className='code-container'>
        <p>
          &#60;script src="https://apis.owenscorning.com/client/widget.js" async&#62;&#60;/script&#62;
        </p>
      </div>
      <CopyToClipboard 
        text={ScriptCode}
        className='button-container'
      >
        <Button>Copy to Clipboard</Button>
      </CopyToClipboard>
      <H3>Step 2 of 2 - Instructions</H3>
      <p>
        <strong>1.</strong> Select the warranties you would like to be shown on your website using the check-boxes below.
      </p>
      {/* <p className="indent-text">
        <strong>a.</strong> Click "Preview Widget" to see what will be shown. That what you can make sure it is what you would like it to appear on your website.
      </p> */}
      <p>
        <strong>2.</strong> Copy the generated div and place it where you would like on your website. 
      </p>
      <p>
      <strong>Note:</strong> This should not be placed in a frame. Placing it within a frame or container narrower that full page width will result in the distortion of the content in the table.
      </p>
      <H4>Select Warranties</H4>
      <ul className='warranty-selection'>
        {checked.map((item, index) => (
            <Checkbox
            checked={item.checked}
            name={index}
            key={'input' + index}
            className='checkbox'
            onChange={handleToggle}
          >
            {item.label}
          </Checkbox>
        ))}
      </ul>
      <div className='code-container'>
          <p>
          &#60;div<br/> {renderCodeSnippets} <br/>class="oc_warranty"&#62;<br/>&#60;/div&#62;
          </p>
      </div>
      <CopyToClipboard 
        text={WarrantyCode}
        className='button-container'
      >
        <Button>
            Copy to Clipboard
        </Button>
      </CopyToClipboard>
    </InstructionsContainer>
  )
}

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const DisclaimerContainer = styled.div`
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.4em;
  position: relative;
  width: 100%;
`;

const Disclaimers = ({ children }) => {
  return (
    <DisclaimerContainer>
      {children}
    </DisclaimerContainer>
  )
}

export default Disclaimers;

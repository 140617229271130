import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Components
import { co2Calculator } from './calculations';
import Table from '../../ComponentLibrary/oc-table';
import CTAButton from '../../CTAButton';
import Text from '../../OC/oc-text.jsx';

const RedText = styled.span`
  color: #ce1126;
  font-size: 26px;
  font-weight: bold;
`;

const StyledTR = styled.tr`
  th {
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
  }
`;

const footnotes = css`
  list-style-type: none;
  padding-left: 0;
`;

const Heading = styled.h4`
  font-family: 'OCROBOTO';
  font-weight: bold;
  color: black;
`;

const CapitalizeH3 = styled.h3`
  font-family: 'OCROBOTO';
  font-weight: bold;
  color: black;
  text-transform: capitalize;
`;

const H6 = styled.h6`
  font-family: 'OCROBOTO';
  font-weight: bold;
  color: black;
  padding-right: 10px;
`;

const InputVal = styled.p`
  font-family: 'OCROBOTO';
  padding-right: 10px;
  color: #585858;
  margin: 0px 0px 25px 0px;
`;

const InputInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: fit-content;
`;

const EPD_BASE_URL = 'https://w-devel.paroc.com/en/documents/uploads/';
const EpdCta = ({ epd, t }) => {
  if (epd) {
    const epdSlug = epd?.toLowerCase()?.replaceAll(' ', '-');
    return (
      <CTAButton
        href={`${EPD_BASE_URL}${epdSlug}`}
        class="oc-cta-button transparent"
        target="_blank"
      >
        <i class="fa fa-file-pdf-o icon"></i>
        {t('co2.epd_button')}
      </CTAButton>
    );
  }
  return null;
};

const StyledTd = styled.td`
  padding: 20px 10px;
`;
const Unit = styled.span`
  font-size: 13px;
  white-space: nowrap;
`;
const TableData = ({ value, unit }) => (
  <StyledTd>
    <RedText>
      {!Number.isNaN(Number(value)) ? (
        <NumberFormat
          decimalScale={2}
          thousandSeparator
          displayType="text"
          value={value}
        />
      ) : (
        '---'
      )}
    </RedText>{' '}
    <Unit><Text content={unit} /></Unit>
  </StyledTd>
);

const InputValue = ({value}) => (
  <InputVal>
    {(value != null) ? (
      value
    ) : (
      '---'
    )}
  </InputVal>
)

const CalculatorResults = ({ t, displayedProduct }) => {
  const result = co2Calculator(displayedProduct);

  return (
    <>
      <CapitalizeH3>{t('co2.score')}</CapitalizeH3>
      <p>{t('co2.score_desc')}</p>

      <InputInfo>
        <H6>{t('co2.product_input')}:</H6>
        <InputValue value = {displayedProduct['sku']}/>
        <H6>{t('co2.thickness_input')}:</H6>
        <InputValue value = {displayedProduct['thickness']}/>
      </InputInfo>

      <Heading>{t('co2.table_1_heading')}</Heading>
      <Table bordered firstLeft={false} margin="10px 0px 30px">
        <thead>
          <StyledTR>
            <th><Text content={t('co2.gwpm2r1')} /></th>
            <th><Text content={t('co2.gwpm2')} /></th>
            <th><Text content={t('co2.gwpkg')} /></th>
            <th><Text content={t('co2.gwpm3')} /></th>
          </StyledTR>
        </thead>
        <tbody>
          <StyledTR>
            <TableData
              value={result.a1A3GWPM2R1}
              unit={t('co2.gwpm2r1_unit')}
            />
            <TableData value={result.a1A3GWPM2} unit={t('co2.gwpm2_unit')} />
            <TableData value={result.a1A3GWPKg} unit={t('co2.gwpkg_unit')} />
            <TableData value={result.a1A3GWPM3} unit={t('co2.gwpm3_unit')} />
          </StyledTR>
        </tbody>
      </Table>

      <Heading>{t('co2.table_2_heading')}</Heading>
      <Table bordered firstLeft={false} margin="10px 0px 30px">
        <thead>
          <StyledTR>
            <th><Text content={t('co2.gwpm2r1')} /></th>
            <th><Text content={t('co2.gwpm2')} /></th>
            <th><Text content={t('co2.gwpkg')} /></th>
            <th><Text content={t('co2.gwpm3')} /></th>
          </StyledTR>
        </thead>
        <tbody>
          <StyledTR>
            <TableData
              value={result.a1C4GWPM2R1}
              unit={t('co2.gwpm2r1_unit')}
            />
            <TableData value={result.a1C4GWPM2} unit={t('co2.gwpm2_unit')} />
            <TableData value={result.a1C4GWPKg} unit={t('co2.gwpkg_unit')} />
            <TableData value={result.a1C4GWPM3} unit={t('co2.gwpm3_unit')} />
          </StyledTR>
        </tbody>
      </Table>

      <Table bordered firstLeft={false} margin="10px 0px 30px">
        <thead>
          <StyledTR>
            <th>{t('co2.avg_density')}</th>
            <th>{t('co2.thermal_resistance')}</th>
          </StyledTR>
        </thead>
        <tbody>
          <StyledTR>
            <TableData
              value={result.a1C4GWPAvgDensity}
              unit={t('co2.avg_density_unit')}
            />
            <TableData
              value={result.a1C4GWPThermalResistance}
              unit={t('co2.thermal_resistance_unit')}
            />
          </StyledTR>
        </tbody>
      </Table>

      <ol css={footnotes}>
        <li>
          * {t('co2.result_footnotes_1')}
        </li>
        <li>
          ** {t('co2.result_footnotes_2')}
        </li>
      </ol>

      <EpdCta epd={displayedProduct.epd} t={t} />
    </>
  );
};

export default CalculatorResults;

CalculatorResults.propTypes = {
  t: PropTypes.func.isRequired,
  displayedProduct: PropTypes.shape({
    sku: PropTypes.string,
    epd: PropTypes.string,
    density: PropTypes.string,
    thickness: PropTypes.string,
    base_value_1: PropTypes.string,
    base_value_2: PropTypes.string,
    lambda_value: PropTypes.string,
    scaling_factor: PropTypes.string,
  }).isRequired,
};

TableData.propTypes = {
  value: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { define, ui, when, s, select, transform } from '@owenscorning/pcb.alpha';

import Form from '../../../OC/PageBuilder/Form';
import PriceSpider from '../../../PriceSpider';
import Reusable from '../../../OC/PageBuilder/Reusable';

const Colors = {
  black: css`
    background: black;
    border-color: white;
    color: white;

    &:before { background-color: white; }
    &:hover, &:focus { color: ${() => UI.Theme.colors.brand}!important; }
  `,
  white: css`
    background: white;
    border-color: black;
    color: black;

    &:before { background-color: black; }
    &:hover, &:focus { color: white!important; }
  `,
  grey: css`
    background: #ddd;
    border-color: #333;
    color: black;

    &:before { background-color: black; }
    &:hover, &:focus { color: white; }
  `,
  transparent: css`
    background: transparent;
    border-color: white;
    color: white;

    &:before { background-color: white; }
    &:hover, &:focus { color: black!important; }
  `,
  borderless: css`
    background: transparent;
    border-color: transparent;
    color: black;

    &:before { background-color: black; }
    &:hover, &:focus { color: white!important; }
  `
};

const CTA = styled.a`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background: ${ () => UI.Theme.colors.brand };
  color: white;
  font-family: Roboto, sans-serif, sans-serif;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  min-width: 170px;
  min-height: 35px;
  cursor: pointer;
  font-size: 18px;
  border: 1px solid ${ () => UI.Theme.colors.brand };
  overflow: hidden;
  z-index: 1;
  transition: 0.25s ease-in-out;

  // Prevent transition on touch devices
  @media (hover: none), (-moz-touch-enabled: 1) {
    transition: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -1%;
    background-color: white;
    transform: translateX(-100%);
    z-index: -1;
    transition: 0.25s ease-in-out;

    /* Prevent transition on touch devices*/
    @media (hover: none), (-moz-touch-enabled: 1) {
      transition: none;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${ () => UI.Theme.colors.brand };
    outline: 0;

    &:before {
      /* display: block; */
      right: 0;
      transform: translateX(0);
      transition: 0.25s ease-in-out;

      /* Prevent transition on touch devices */
      @media (hover: none), (-moz-touch-enabled: 1) {
        transition: none;
      }
    }
  }

  ${ ({ color }) => color && Colors[color] }

  ${ ({ disabled }) => disabled && css`
    opacity: 0.5;
    pointer-events: none;
    &:before { transition: none; }
  ` }
`;

const possibleDestinations = ({...props}) => {
  const allDestinations = {
    link: 'Link',
    modal: 'Modal',
    pricespider: 'PriceSpider',
    share: 'Share',
    document: 'Document',
    locationModal: 'Open Location Modal'
  };

  if (window.PB_TYPE === 'Product::Enclosure') {
    allDestinations.zipFile = 'Document Zip File'
  }

  return allDestinations
};

const clickAction = (onClick, destination, modal, form, modalSource, reusable, shareLink, shareTitle) => {
  if (onClick) {
    return onClick
  } else if (destination === 'modal' && !form) {
    form = {
      'PinkNextGenSampleRequest': {
        form_uid: 'next_gen_sample2'
      },
      'VidawoolEmailCollection': {
        form_uid: 'vidawool_email_collection'
      }
    }[modal]
  }
  if (destination === 'modal' && modalSource === 'form') {
    return () => Board.modal.open({
      body: <Form { ...form } />
    })
  }
  if (destination === 'modal' && modalSource === 'reusable') {
    return () => Board.modal.open({
      ...(reusable.title && { title: reusable.title }),
      body: ui`Modules/Page/Reusable|view`({ value: reusable.reusable, gutterlessChild: true })
    })
  }
  if (destination === 'share') {
    if (navigator.share) {
      navigator.share({
        title: shareTitle,
        url: shareLink
      }).then(() => {
        console.log('Successful share');
      })
      .catch(error => {
        console.log('Error sharing:', error);
      });
    } else {
      return () => Board.modal.open({
        body: ui`Modules/Page/Reusable|view`({ value: {name: 'ShareBanner'}, gutterlessChild: true })
      })
    }
  }
}

export default define`CTA`('0.0.1')({
  view: ({ contents, color, disabled, text, url, modal, form, destination, onClick, pricespider_upc, modalSource, reusable, shareLink, shareTitle, document, ...props }) =>{ 
    return (
    destination === 'pricespider' ?
      <PriceSpider pricespider_upc={ pricespider_upc } /> :
      <CTA
        href={ (destination !== 'modal' && destination != 'share') && url }
        onClick={ clickAction(onClick, destination, modal, form, modalSource, reusable, shareLink, shareTitle) }
        color={ color }
        disabled={ disabled }
        { ...props }
      >
        { contents ? contents : text }
      </CTA>
  )},
  edit: ui`Form`.of({
    destination: ui`Choices`.of(possibleDestinations())({
      label: 'CTA Destination',
      default: 'link'
    }),
    [s._]: ui`Tip`.of('Zip file will only be available for published content.')({
      visible: when`../destination`.is.equal.to('zipFile'),
    }),
    url: ui`Text`({
      label: 'CTA URL',
      visible: when`../destination`.is.equal.to('link'),
      default: ''
    }),
    modalSource: ui`Choices`.of({
      form: 'Form',
      reusable: 'Reusable'
    })({
      label: 'Modal Content',
      default: 'form',
      visible: when`../destination`.is.equal.to('modal')
    }),
    form: ui`Modules/Page/Form`({
      label: 'Form',
      visible: when`../destination`.is.equal.to('modal').and.when`../modalSource`.is.equal.to('form'),
    }),
    reusable: ui`Form`.of({
      title: ui`Text`({
        label: 'Modal Title',
        default: 'Title'
      }),
      reusable: ui`Modules/Page/Reusable`,
    })({
      label: 'Reusable',
      visible: when`../destination`.is.equal.to('modal').and.when`../modalSource`.is.equal.to('reusable')
    }),
    pricespider_upc: ui`Text`({
      label: 'PriceSpider UPC(s)',
      visible: when`../destination`.is.equal.to('pricespider')
    }),
    pricespider_tip: ui`Tip`.of('The product must be included in the spreadsheet sent to PriceSpider for this feature to work.')({
      visible: when`../destination`.is.equal.to('pricespider'),
    }),
    document: ui`Form`.of({
      dataset: ui`Data/Choices`.of('CtaDocumentSet'),
      parameters: ui`Data/Parameters`.of('CtaDocumentSet')({ dataset: select`../dataset` })
    })({
      label: 'Select Details',
      visible: when`../destination`.is.equal.to('document')
    }),
    text: ui`Text`({
      label: 'CTA Text',
      default: 'Learn More',
      visible: when`../destination`.isnt.equal.to('pricespider'),
    }),
    [s._]: ui`Tip`.of('Succinct, action oriented CTAs perform best, e.g. “Download Document” or “Explore SureNail®”')({
      visible: when`../destination`.isnt.equal.to('pricespider'),
    }),
    shareLink: ui`Text`({
      label: 'Share URL',
      visible: when`../destination`.is.equal.to('share'),
      default: transform`~build`((build) => [window.location.origin, build?.language, build?.path].join('/'))
    }),
    shareTitle: ui`Text`({
      label: 'Share Title',
      visible: when`../destination`.is.equal.to('share'),
      default: transform`~build/name`((name) => name)
    }),
    color: ui`Choices`.of({
      standard: 'Standard',
      borderless: 'Borderless',
      black: 'Black',
      white: 'White'
    })({
      label: 'Style',
      default: 'standard',
      visible: when`../destination`.isnt.equal.to('pricespider'),
    }),
  })({
    label: 'CTA'
  })
});

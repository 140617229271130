import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../themes/index.js';

import { store } from '../Store'
import { Provider } from 'react-redux';
import { connectLocation } from './LocationConnectors';
import { isLocaleChild } from './locales';

import LocationModalController from "./Popup/Modal";
import Icon from '../OC/oc-icon.jsx';
import flagGlobe from 'assets/images/components/language-picker/globe.png';

const SwitcherContainer = styled.div`
  background: ${props => props.error ? '#FFF1E5' : 'transparent'};
  display: flex;
  padding: ${props => props.error ? '16px' : '0'};

  .error {
    color: #D65900;
    font-weight: 700;
    margin-right: 16px;
    padding: 0;
  }
`;

const localeButtonCSS = css`
  align-items: center;
  -webkit-appearance: button;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin-left: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-transform: none;

  &:hover {
    color: ${Theme.colors.brand};
  }

  > span {
    font-size: 12px;
    font-weight: bold;
    margin: 0 5px;
  }

  .locale-language-code {
    font-weight: 600;
    text-transform: uppercase;
  }

  i {
    font-size: 10px;
  }
`;

const SwitcherButtonWithFlag = connectLocation(({ className, zip, locale: { flag, countryName, languageName, code }, openLocationModal, showZip }) => {
  const flagIcon = code.match(/(NA|EU)$/) ? flagGlobe : flag;
  const zipOrRegion = code.match(/(US|CA)$/) ? (zip||countryName) : countryName;

  return (
    <button onClick={openLocationModal} className={className} data-track="open-modal" data-track-modal-name="Region Selector">
      <img src={flagIcon} width="20" alt=""/>
      <span className="sr-only">{showZip && "zipcode, "}language</span>
      <span key={languageName + zipOrRegion}>{showZip && zipOrRegion && `${zipOrRegion} -`} {languageName}</span>
      <i aria-hidden="true" className="fa fa-chevron-down" />
    </button>
  );
});

const SwitcherButtonWithoutFlag = connectLocation(({ className, locale: { languageName, code }, openLocationModal }) => {
  const [languageCode, _] = code.split('-');

  return (
    <button onClick={openLocationModal} className={className} data-track="open-modal" data-track-modal-name="Region Selector">
      <span className="locale-language-code">{languageCode}</span>
      <span key={languageCode}>{languageName}</span>
      <i aria-hidden="true" className="fa fa-chevron-down" />
    </button>
  );
});

const LocationSwitcher = connectLocation(({ skipWrapper,
                                            showLocale,
                                            userLocale: { code: userLocaleCode },
                                            locale: { code },
                                            postalDesired = true,
                                            postalRequired,
                                            forceReload = false,
                                            hideFlag = false,
                                            translationPrefix = null }) => {
  const SwitcherButton = hideFlag ? SwitcherButtonWithoutFlag : SwitcherButtonWithFlag;
  const showZipInput = postalRequired || postalDesired;

  const matched = userLocaleCode === code || isLocaleChild(code, userLocaleCode);
  const mismatched = !matched;

  return (
    <SwitcherContainer className={ skipWrapper ? '' : 'oc-region-selector flags' } error={mismatched} >
      {mismatched && <span className="error"><Icon type="exclamation-triangle" /></span>}
      <LocationModalController mismatched={mismatched}
                               showLocale={showLocale}
                               postalRequired={postalRequired}
                               postalDesired={postalDesired}
                               forceReload={forceReload}
                               hideFlag={hideFlag}
                               translationPrefix={translationPrefix} />
      <SwitcherButton css={localeButtonCSS} showZip={showZipInput} />
    </SwitcherContainer>
  );
});

const ProvidedLocationSwitcher = (props) => (
  <Provider store={store}>
    <LocationSwitcher {...props} />
  </Provider>
)

export default ProvidedLocationSwitcher;

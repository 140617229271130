import { jsx } from '@emotion/react';
import styled from "@emotion/styled";
import { connectLocation } from "../../location/LocationConnectors";
import Image from '../oc-image';
import { H3 } from '../text-elements/oc-h';
import ResultBlock from './oc-result-block';

const Container = styled.a`
  width: 100%;
  margin: 16px 0;
  text-decoration: none;
  :hover > *{
    color: initial;
  }
  :hover > h3 {
    color: rgb(241, 59, 161);
    text-decoration: underline;
  }
  :focus {
    outline: none;
  }
`;

const Heading = styled(H3)`
  margin-bottom: 0;
`
const PostHeading = styled.p`
  margin: 4px 0 0;
`;

const DocumentDetails = styled.span`
  font-size: 12px;
`;

const ColumnedSection = styled.div`
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    gap: 16px;
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 100%;

  @media(min-width: 768px) {
    width: 50%;
  }
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  display: block;

  strong {
    margin-left: 8px;
  }
`;

const SDSResultBlock = connectLocation(({ t, result, style }) => {

  return (
    <ResultBlock {...( style && {style : style})}>
      <Container href={result.docUrl}
                 data-track="file-download"
                 data-track-file-name={result.docName}
                 data-track-element-location="search results"
                 data-track-doc-type={result.docType}>

        <Heading font="body">{result.name}</Heading>

        <PostHeading>{result.postheading}</PostHeading>

        <DocumentDetails>
          <strong>{result.docName}</strong> {(result.docFormat || result.docSize) && `| ${result.docFormat} ${result.docSize}`}
        </DocumentDetails>

        <ColumnedSection>
          <Column>
            {result.docType && <Info>{t(`sds.fields.category`)} <strong>{result.docType}</strong></Info>}
            {result.languageName && <Info>{t(`sds.fields.language_iso_code`)} <strong>{result.languageName}</strong></Info>}
            {result.supplierName && <Info>{t(`sds.fields.supplier`)} <strong>{result.supplierName}</strong></Info>}
          </Column>
          <Column>
            {result.regionName && <Info>{t(`sds.fields.region`)} <strong>{result.regionName}</strong></Info>}
            {result.date && <Info>{t(`sds.fields.document_date`)} <strong>{result.date}</strong></Info>}
          </Column>
        </ColumnedSection>
      </Container>
    </ResultBlock>
  )
});

export default SDSResultBlock;

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import RJSFForm from '@rjsf/core';
import PropTypes from 'prop-types';
import React, { Component, isValidElement } from 'react';
import ReactDOM from 'react-dom';
import OCFields from './OCFields';
import Api from '../../mdms_api';
import { DefaultTextInput } from './OCInputs';
import { basicFormCSS } from "./Styles";
import Checkbox from '../OC/oc-checkbox';
import RichText from '../OC/oc-rich-text';
import Text from '../OC/oc-text';
import SignaturePad from './fields/signaturePad';
import RadioGroup from './fields/RadioGroup';
import SplitUsPhone from './fields/SplitUsPhone';
import CheckboxesWidget from './fields/CheckboxesWidget';
import RoofingClaimsNextSteps from './RoofingClaimsNextSteps';
import AjaxOverlay from './ajax_overlay';
import { H2, H3 } from '../OC/oc-h';
import LabelWrapper from './LabelWrapper';
import validator from '@rjsf/validator-ajv6';
import { getRequiredSuffix } from '../../helpers/form_libs';
import Modal from '../Modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { connectLocation } from '../location/LocationConnectors';
import SelectWidget from './SelectWidget';
import loadable from '@loadable/component';
import _ from 'lodash';
import Cookies from 'js-cookie';

const PRESERVED_RESPONSE_COOKIE_NAME = 'preserved_form_responses';

const getSavedResponses = () => {
  const value = Cookies.get(PRESERVED_RESPONSE_COOKIE_NAME)
  if (value) {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  } else {
    return {}
  }
}

const setSavedResponses = (form_data, propNames) => {
  const savedData = {};
  propNames.forEach(prop => {
    const value = _.get(form_data, prop);
    _.set(savedData, prop, value);
  })
  if (form_data.preserve_responses) {
    Cookies.set(PRESERVED_RESPONSE_COOKIE_NAME, JSON.stringify(savedData))
  } else {
    Cookies.remove(PRESERVED_RESPONSE_COOKIE_NAME)
  }
}

const SectionRenderer = ({ UI, value }) => {
  return (
    <div>
      {(value || []).map(module => <ModuleRenderer UI={ UI } value={ module } />)}
    </div>
  );
};

const ModuleRenderer = ({ UI, value }) => {
  const definition = UI.Modules?.Page?.[value.type];
  const Component = definition?.modes?.view || (() => <div></div>);
  return <div style={{ paddingTop: '48px' }}>
    <Component value={ value?.data } />
  </div>
};

// can remove "NonLibrary" ones once we get rid of server rendering and/or non PB page
const NonLibrarySectionRenderer = ({ value }) => {
  return (
    <div>
      {(value || []).map(module => <NonLibraryModuleRenderer value={ module } />)}
    </div>
  );
};

const NonLibraryModuleRenderer = ({ value }) => {
  const Component = loadable(props => import(`../OC/PageBuilder/${value.type}`));
  return <div style={{ paddingTop: '48px' }}>
    <Component { ...(value?.data || {}) } />
  </div>
};


const Consent = () => (
  <Checkbox>Yes</Checkbox>
)
const CheckboxWidget = ({ schema, id, value, required, disabled, label, autofocus, onChange }) => (
  <Checkbox
    id={ id }
    checked={ typeof value === "undefined" ? false : value }
    required={ required }
    disabled={ disabled }
    autoFocus={ autofocus }
    onChange={ (event) => onChange((!event.target.checked && required) ? undefined : event.target.checked) }
  >
    <RichText content={ schema?.label || label } inline />
  </Checkbox>
);

export const Recaptcha = connectLocation(
  ({ options, onChange, locale = {} }) => {
    const { code = 'en-US' } = locale;
    return (
      <div style={{ width: 'auto' }}>
        <ReCAPTCHA
          sitekey={options.recaptchaSiteKey}
          onChange={onChange}
          hl={code}
        />
      </div>
    );
  }
);

const PreserveResponsesCheckbox = (props) => (
  <CheckboxWidget { ...props } />
)

const Widgets = {
  BaseInput: DefaultTextInput,
  Consent: Consent,
  Recaptcha: Recaptcha,
  CheckboxWidget: CheckboxWidget,
  SignaturePad: SignaturePad,
  RadioGroup: RadioGroup,
  SplitUsPhone: SplitUsPhone,
  SelectWidget: SelectWidget,
  CheckboxesWidget: CheckboxesWidget,
  PreserveResponsesCheckbox: PreserveResponsesCheckbox,
  RoofingClaimsNextSteps: RoofingClaimsNextSteps
}

const ErrorP = styled.p`
  color: #940420;
  font-size: 12px;
  font-weight: bold;
`;

const LinkError = styled.p`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  align-items: center;
  padding: 16px 24px;
  gap: 9px;
  width: 100%;
  height: 56px;
  background: #F8F8F8;

  @media(min-width: 1200px) {
    width: 34rem;
  }
`;

const RichElementWrapper = styled.div`
  margin-bottom: ${ props => props.margin ? '32px' : '0' }
`;

const RichElement = ({ prop, name, margin }) => (
  <>
    { prop && prop.props[name] && isValidElement(prop) && <RichElementWrapper margin={ margin }><RichText content={ prop.props[name] } singular /></RichElementWrapper> }
    { prop && prop.props[name] && !isValidElement(prop) && <RichElementWrapper margin={ margin }>{ prop.props[name] }</RichElementWrapper> }
  </>
)

const CustomFieldTemplate = connectLocation((props) => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    uiSchema,
    schema,
    description,
    errors,
    rawErrors,
    children,
    hidden,
    formContext,
    t
  } = props;

  const showLabel = label && label.trim() && !uiSchema['ui:options']?.['hideLabel']
  const isObject = (schema.type || 'object') === 'object';
  const LabelTag = isObject ? (id === 'root' ? H2 : H3) : LabelWrapper;
  const optionalFocused = formContext?.optionalFocused;

  return hidden ? children : (
    <div className={classNames}>
      {
        showLabel && (
          <LabelTag htmlFor={ id } prehead={schema?.prehead}>
            <Text content={ label.trim() } />{getRequiredSuffix(optionalFocused, required, schema.type)}
          </LabelTag>
        )
      }
      <RichElement prop={ description } name="description" margin={isObject} />
      { children }
      {/* {errors} */}
      { rawErrors?.map(error => {
        let errorMessage = t('errors.forms.is_required');
        if (error.includes('required')) {
          errorMessage ||= schema?.messages?.required;
        } else if (error.includes('pattern')) {
          errorMessage ||= schema?.messages?.pattern;
        } else if (error.includes('should NOT be longer than')) {
          errorMessage ||= schema?.messages?.maxLength;
        } else {
          errorMessage ||= schema?.messages?.format;
        }

        return (
          <ErrorP aria-describedby={ id } aria-errormessage className="error" role="alert">
            {!!error ? errorMessage : null}
          </ErrorP>
        )
      }) }
      <RichElement prop={ help } name="help" />
    </div>
  );
});

const arrayRemoveButton = css`
  width: 2rem;
  height: 3.45rem;
  background: none;
  cursor: pointer;
  margin-left: auto;
`;

const arrayAddButton = css`
  border-color: #46b8da;
  border-radius: 4px;
  border: 1px solid transparent;
  vertical-align: middle;
  text-align: center;
  line-height: 1.42857143;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  padding: 6px 12px;
`;

const arrayInputBox = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const arrayFieldTemplate = css`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
`;

const hrMedium = css`
  display: block;
  border: 1px solid #ccc;
  border-width: 0 0 1px;
  margin: 30px 0;
`

function ArrayFieldTemplate(props) {
  return (
    <div css={ arrayFieldTemplate }>
      {
        props.items.map((element, index) => {
          return (
            <React.Fragment key={ element.key }>
              <div css={ arrayInputBox }>
                {
                  element.hasRemove &&
                    <button className="fa fa-trash" type="button" css={ arrayRemoveButton } onClick={ element.onDropIndexClick(index) } />
                }
                { element.children }
              </div>
              {
                !(props.items.length === 1 || index === props.items.length - 1) &&
                  <hr css={ hrMedium } />
              }
            </React.Fragment>
          )
        })
      }
      {
        props.canAdd && <button type="button" css={ arrayAddButton } onClick={ props.onAddClick }>+ Add</button>
      }
    </div>
  );
}

function ObjectFieldTemplate(props) {
  return (
    <div>
      { props.properties.map(element => <div key={ element.name }>{ element.content }</div>) }
    </div>
  );
}

const defaultUiSchema = {
  "email": {
    "ui:options": {
      "inputType": "email"
    }
  }
}

class SchemaForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
      schema: props.schema,
      uiSchema: props.uiSchema,
      renderSubmit: true,
      formData: props.formData,
      showError: false,
      serverError: null,
      firstError: null,
      success: !!props.success,
      successContent: props.successContent
    }
  }

  changeShowError = () => {
    this.setState({ showError: true, serverError: null });
  }

  changeFirstError = (selector) => {
    this.setState({ firstError: selector, serverError: null });
  }

  scrollToErrorInput = (errorId) => {
    const input = document.getElementById(errorId)
    input.scrollIntoView({ inline: "start", block: "center", behavior: "smooth" });
  }

  focusInputError = () => {
    const initialId = this.state.firstError
    const secondId = this.state.firstError.split('_').pop()
    const thirdId = `root_${ secondId }`

    if (document.getElementById(initialId)) {
      this.scrollToErrorInput(initialId);
    } else if(document.getElementById(secondId)) {
      this.scrollToErrorInput(secondId);
    } else {
      if (document.getElementById(thirdId)) {
        this.scrollToErrorInput(thirdId);
      } else {
        console.log("Couldn't find an input")
      }
    }
  }

  handleKeypress = e => {
    if (e.key === "Enter") {
      this.focusInputError();
    }
  }

  get formUrl() {
    return this.props.formUrl || (this.props.uid ? `/api/v2/forms/${ this.props.uid }${ this.props.search || '' }` : null)      // <= this.props.search must render blank when undefined.
  }

  get postUrl() {
    return this.props.postUrl || this.formUrl;
  }

  fetchSchema = () => {
    if (this.requestController) this.requestController.abort();
    if (this.formUrl) {
      this.requestController = new AbortController();
      Api.get(this.formUrl, { signal: this.requestController.signal })
        .then((response) => response.json())
        .then(result => {
          let schema = result?.schema;
          let prepopulate_default = schema?.meta?.prepopulate_default;
          // if schemas set a default value for preserve_responses, use that
          if (prepopulate_default != undefined) {
            schema.properties.preserve_responses.default = prepopulate_default
          }
          if (schema?.meta?.prepopulate && schema.meta.prepopulate.length > 0) {
            // set the prepopulated from cookie
            const saved_responses = getSavedResponses();
            schema.meta.prepopulate.forEach(prop => {
              schema = this.setSchemaFromPath(schema, prop, 'default', _.get(saved_responses, prop))
            });
          }
          this.setState({ schema: result.schema, uiSchema: result.ui_schema, successContent: result.success_content }, () => {
            this.setAdditionalAttributes();
          });
        })
        .catch(e => {
          if (!this.requestController.signal.aborted) {
            throw e;
          }
        })
    } else if (!this.props.schema) {
      this.setState({ schema: {}, uiSchema: {} });
    }
  }

  getSchemaFromPath = (key) => {
    const path = key.replace(/\./g, '.properties.').replace(/^\./,'');
    return _.get(this.state.schema, path);
  }

  setSchemaFromPath = (schema, key, field, value) => {
    const path = key.replace(/\./g, '.properties.').replace(/^\./,'');
    return _.set(schema, ['properties', path, field].join('.'), value);
  }

  setAdditionalAttributes = () => {
    const dataTrackFormName = this.state.schema?.meta?.['data-track-form-name'] || this.props.uid
    const additionalAttributes = {
      'data-track': 'form-submit',
      ...(dataTrackFormName ? { 'data-track-form-name': dataTrackFormName } : {}),
      ...(this.props.additionalAttributes || {}),
      ...(this.state.schema?.meta?.formAttributes)
    };

    const element = ReactDOM.findDOMNode(this.refs.rjsfform);
    if (element) {
      element.setAttribute('novalidate', 'novalidate');
      Object.keys(additionalAttributes).forEach(additionalAttribute => {
        element.setAttribute(additionalAttribute, additionalAttributes[additionalAttribute])
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.schema !== prevProps.schema ||
      this.props.uiSchema !== prevProps.uiSchema ||
      JSON.stringify(this.props.successContent||{}) !== JSON.stringify(prevProps.successContent||{})
    ) {
      this.setState({ schema: this.props.schema, uiSchema: this.props.uiSchema, successContent: this.props.successContent, success: this.props.success });
    } else if (this.props.uid !== prevProps.uid || this.props.formUrl !== prevProps.formUrl) {
      this.fetchSchema();
    }
  }

  componentWillUnmount() {
    if (this.requestController) this.requestController.abort();
  }

  componentDidMount() {
    this.fetchSchema();
  }

  successUrl = () => {
    if (this.props.success_content?.type === 'url') {
      return this.props.success_content.url;
    } else if (this.state.successContent?.type === 'url') {
      return this.state.successContent.url;
    }

    return this.state.schema.meta?.["redirectOnSuccess"];
  }

  afterSubmit = (form_submit) => {
    if (this.state.schema?.meta?.prepopulate && this.state.schema?.meta?.prepopulate.length > 0) {
      // set the cookie from form data
      setSavedResponses(form_submit.data_json, this.state.schema?.meta?.prepopulate)
    }
    const redirectUrl = this.successUrl()
    if (redirectUrl) {
      if (!!this.props.sandbox) {
        alert(`User would be taken to ${ redirectUrl }`)
      } else {
        window.location.href = redirectUrl;
      }
      return
    }
    const isModalOpen = !!this.state.schema.meta?.successInModal || (this.state.successContent?.type === 'content' && !!this.state.successContent.modal);
    this.setState({ success: true, isSubmitting: false, showError: false, serverError: null, firstError: null, isModalOpen });
    this.props.afterSave && this.props.afterSave(form_submit);
  }

  sandboxFormSubmit = (formData) => {
    setTimeout(() => {
      // mock data for sandbox
      this.afterSubmit({
        id: 1,
        form_id: 1,
        data: JSON.stringify(formData),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        source: null,
        source_created_at: null,
        data_json: formData,
        user_agent: null,
        request_ip: '127.0.0.1',
        ums_user_id: null,
        ums_user_email: null,
        ums_user_name: null,
        metadata: null,
        status: 'new',
        adobe_id: null,
        retries: 0,
        state_log: null,
        status_log: null
      })
    }, 1000);
  }

  submit = ({ formData }, _e) => {
    if (this.props.dupeFn && this.props.dupeFn(formData)) {
      this.props.afterSave && this.props.afterSave();
    } else {
      if (this.state.schema.meta?.["require-user"]) {
        this.postFormData(Api.secure_post, formData);
      } else if (this.state.schema.meta?.["hideSubmitIf"]) {
        const { properties } = this.state.schema.meta.hideSubmitIf;
        if(!this.hasPropertiesWithExpectedValues(properties, formData)) {
          this.postFormData(Api.post, formData);
        }
      } else {
        this.postFormData(Api.post, formData);
      }
    }

    return false;
  }

  postFormData(method, formData) {
    this.setState({ isSubmitting: true })
    if (!!this.props.sandbox) {
      this.sandboxFormSubmit(formData)
    } else {
      method(this.postUrl, formData)
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            return result.json();
          } else {
            return result.text().then(response => {
              try {
                console.error({ response: JSON.parse(response) })
              } catch {
                console.error(response)
              }
              throw new Error("There was a server related issue submitting your form.  Please try again later.")
            })
          }
        })
        .then(json => {
          this.afterSubmit(json.result?.form_submit)
        })
        .catch(exception => {
          this.setState({ success: false, isSubmitting: false, showError: true, serverError: exception.message, firstError: null })
        });
    }
  };

  renderResult() {
    if (this.props.UI) {
      return this.state.successContent?.type === 'content' && this.state.successContent?.content ? (
        <SectionRenderer UI={ this.props.UI } value={ this.state.successContent.content }/>
      ) : null
    } else {
      // can remove this case once we get rid of server rendering and/or non PB page
      return this.state.successContent?.type === 'content' && this.state.successContent?.content ? (
        <NonLibrarySectionRenderer value={ this.state.successContent.content }/>
      ) : null
    }
  }

  hasPropertiesWithExpectedValues(properties, formData = {}) {
    if (Object.keys(properties).length === 0 || Object.keys(formData).length == 0)
      return false;

    return Object.keys(properties).every( (property) => {
      if (typeof properties[property] == Object) {
        return hasPropertiesWithExpectedValues(properties[property], formData[property])
      } else {
        return formData[property] === properties[property]
      }
    });
  }

  shouldRenderSubmitButton(formData) {
    if (this.state.schema.meta?.hideSubmitIf && formData) {
      const { properties } = this.state.schema.meta.hideSubmitIf;
      if (this.hasPropertiesWithExpectedValues(properties, formData)) {
        return false;
      }
    }

    return true;
  }

  change = ({ formData }, _e) => {
    this.setState({
      formData: formData,
      renderSubmit: this.shouldRenderSubmitButton(formData)
    });
  }

  onError = (errors) => {
    this.changeShowError();
    const error = errors[0].property.replace(/\./g, "_")
    this.changeFirstError(`root${ error }`);
  }

  closeModal = () => {
    window.location.reload(true);
  }

  render() {
    if (!this.state.schema)
      return null;
    const { children } = this.props;

    if (this.state.success && !this.state.isModalOpen) {
      return this.renderResult();
    }
    return (
      <AjaxOverlay fetching={ this.state.isSubmitting }>
        <RJSFForm
          ref="rjsfform"
          className="rjsfform"
          css={ this.props.css || basicFormCSS }
          fields={ OCFields }
          formContext={ this.state.schema.formContext || {} }
          FieldTemplate={ CustomFieldTemplate }
          ObjectFieldTemplate={ ObjectFieldTemplate }
          ArrayFieldTemplate={ ArrayFieldTemplate }
          onSubmit={ this.submit }
          schema={ this.state.schema }
          showErrorList={ false }
          uiSchema={ this.state.uiSchema || defaultUiSchema }
          formData={ this.state.formData }
          widgets={  Widgets  }
          onChange={ this.change }
          onError={ this.onError }
          validator={ validator }
          state={ this.state }
          focusInputError={ this.focusInputError }
          handleKeypress={ this.handleKeypress }
          noHtml5Validate
        >
          { this.state.renderSubmit && children }
          { this.state.showError &&

            <div>
              <br />
              {
                this.state.firstError &&
                  <LinkError tabIndex={ 0 } onKeyPress={ this.handleKeypress }>
                    <i style={{ color: '#940420', marginRight: '10px' }} class="fa fa-exclamation-triangle fa-lg" />
                    {this.props.t('errors.forms.has_error')}
                    <a style={{ cursor: 'pointer', color: '#940420' }} onClick={ this.focusInputError }>
                      {this.props.t('errors.forms.go_to_error')}
                    </a>
                  </LinkError>
              }
              {
                this.state.serverError &&
                <LinkError tabIndex={ 0 }>
                  <i style={{ color: '#940420', marginRight: '10px' }} class="fa fa-exclamation-triangle fa-lg" />
                  { this.state.serverError }
                </LinkError>
              }
            </div>
          }
          { this.state.schema?.meta?.showContactUsText &&
            <div style={{ marginTop: "15px" }}>
              <RichText content={ this.state.schema?.meta?.showContactUsText } />
            </div>
          }
          { !this.state.schema?.meta?.hidePrivacyPolicyLink &&
            <div style={{ marginTop: "15px" }}>
              { this.props.t('privacyPolicyPrefix') } <a href="/privacy-policy" target="_blank">{ this.props.t('privacyPolicy') }</a>
            </div>
          }
          { this.state.schema?.meta?.footerHtml &&
            <div style={{ marginTop: "25px", fontSize: "11px" }}>
              <RichText content={ this.state.schema?.meta?.footerHtml } />
            </div>
          }
          <Modal open={ this.state.isModalOpen } onClose={ this.closeModal } >
            { this.renderResult() }
          </Modal>
        </RJSFForm>
      </AjaxOverlay>
    )
  }
}
SchemaForm.propTypes = {
  uid: PropTypes.string,
  json: PropTypes.string,
  formUrl: PropTypes.string,
  postUrl: PropTypes.string,
  css: PropTypes.object,
  // if true, only fakes a submission (for WYSIWYG builder or test mode)
  sandbox: PropTypes.bool,
  // override to show success state of form, typically not passed to let form control its own state
  success: PropTypes.bool
}

SchemaForm = connectLocation(SchemaForm)

export { SchemaForm };

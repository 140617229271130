import { useRef } from 'react';
import { useAccessibleInteractivity } from '../../../hooks';
import styled from '@emotion/styled';
import Text from '../../OC/oc-text';

const ChildItem = styled.li`
  margin-bottom: 10px;
  color: ${props => props.disabled ? '#666' : 'black' };
  ${props => props.nested ? (props.rootShown ? 'margin-left: 16px;' : 'margin-left: 32px;') : ''}
  opacity: ${props => props.disabled ? '.3' : '1' };

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer' };
  }

  &:focus {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : '#d40f7d solid 2px' };
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : '#d40f7d solid 2px' };
  }

  a {
    text-decoration: none;
    font-weight: ${ props => props.selected ? '700' : '500' };
    color: ${ props => props.selected ? '#d40f7d' : '#333333' };
  }
`;

const ChildListItem = ({ count, disabled, name, onClick, selected, nested, rootShown, slug, title }) => {
  const linkRef = useRef();
  useAccessibleInteractivity(linkRef, onClick);

  return (
    <ChildItem key={slug} rootShown={rootShown} disabled={disabled} selected={selected} nested={ nested }>
      <a
        data-track={selected ? "remove-filter" : "filter"}
        data-track-filter-name={title}
        data-track-filter-option={name}
        ref={linkRef}
      >
        <span><Text content={ name } />{ typeof(count) !== 'undefined' ? <>&nbsp;{`(${ count })`}</> : null }</span>
      </a>
    </ChildItem>
  )
}

export default ChildListItem;

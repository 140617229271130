import styled from '@emotion/styled';

const FilterPaneButtonsContainer = styled.div`
	display: ${props => props.visible ? 'flex' : 'none'};
	background: white;
	width: 100%;
	border-top: 1px solid #D1D1D1;
	position: absolute;
	left: 0;
	bottom: 0;
	@media(min-width: 1200px) {
    display: none;
  }
`;

const Button = styled.button`
	font-size: 18px;
	line-height: 21px;
	font-weight: 700;
	width: 50%;
	background-color: white;
	border: none;
	padding: 16px 8px;
	text-align: center;

	&:hover {
		cursor: pointer;
		background-color: #d40f7d;
		color: white;
	}
`;

const ApplyButton = styled(Button)`
	background-color: #d40f7d;
	color: white;
`

const FilterPaneButtons = ({filterReset, apply, visible, count}) => {
	return(
		<FilterPaneButtonsContainer visible={visible}>
			<Button onClick={filterReset} data-track="reset">Reset</Button>
			<ApplyButton 
				onClick={apply}
				aria-label="Close Modal"
        data-track="close-modal"
        data-track-modal-name="Filter Overlay"
			>
				Apply ({count})
			</ApplyButton>
		</FilterPaneButtonsContainer>
	);
};

export default FilterPaneButtons;
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Theme from '../../../themes/index';
import TextInput from '../input-elements/oc-text-input';
import ContentGroupDropdown from '../oc-content-group-dropdown';

const SearchBar = styled.div`
  margin-right: 0;
  align-self: center;
  display: flex;

  input {
    display: block;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-right: none;
    font: inherit;
    -webkit-appearance: none;
    margin-right: -2px;
  }

  input::placeholder {
    text-transform: uppercase;
    padding-top: 5px;
    font-size: 13px;
    color: #65656a;
  }

  .search-button {
    display: block;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    background: transparent;
    cursor: pointer;

    &:focus,
    &:hover {
      outline: 2px solid #65656a;
      outline-offset: -2px;
    }
  }

  input:focus + .search-button {
    border-color: ${props => props.themeColor};
    outline: none;
    cursor: pointer;
  }
`;

function ClearButton({ onClick }) {
  return (
    <button
      className="search-button"
      type="submit"
      aria-label="Clear Search"
      onClick={onClick}
    >
      <span aria-hidden="true" className="fa fa-times fa-md" />
    </button>
  );
}

function SearchButton({ onClick, disabled }) {
  return (
    <button
      className="search-button"
      type="submit"
      aria-label="Submit Search"
      onClick={onClick}
      disabled={disabled}
      aria-disabled="true"
    >
      <span aria-hidden="true" className="fa fa-search fa-md" />
    </button>
  );
}

function TextSearchFilter({
  onChange,
  value,
  filterAttribute,
  title,
  filterOnDebounce = true, // the onChange will fire shortly after the user stops typing
  filterOnEnter = false, // pressing enter will fire the onChange
  filterOnBlur = false, // the onChange will fire when the input loses focus
}) {
  const [text, setText] = useState(value);
  // keep the debounce function between renders, or else we can't cancel/refresh it
  const debounceUpdate = useMemo(() => debounce(onChange, 300), []);
  // upon rendering with a new text value, kick off a debounce to update the filter
  useEffect(() => {
    if (filterOnDebounce) {
      if (value !== text) {
        debounceUpdate(filterAttribute, text);
      } else {
        debounceUpdate.cancel();
      }
    }
  }, [text]);
  const onTextChange = e => {
    setText(e.target.value);
  };
  const clearSearch = () => {
    onChange(filterAttribute, '');
  };
  // push the text input value to the filter
  const updateFilter = () => {
    if (value !== text) {
      onChange(filterAttribute, text);
    }
  };
  // use a form wrapper, so that pressing enter will find the submit button
  const Wrapper = filterOnEnter ? 'form' : 'div';
  // for debounce search, only show the search button when the text is blank
  // otherwise, show search when the text doesn't match the value (or is blank)
  const showSearchButton = filterOnDebounce ? !text : !text || value !== text;
  return (
    <Wrapper>
      <ContentGroupDropdown label={title} collapsible={false}>
        <SearchBar themeColor={Theme.colors.brand}>
          <TextInput
            onChange={onTextChange}
            value={text}
            onBlur={filterOnBlur ? updateFilter : null}
          />
          {showSearchButton ? (
            <SearchButton onClick={updateFilter} disabled={value === text} />
          ) : (
            <ClearButton onClick={clearSearch} />
          )}
        </SearchBar>
      </ContentGroupDropdown>
    </Wrapper>
  );
}

TextSearchFilter.filterValueType = 'singular';
TextSearchFilter.getChipInfo = (_filter, filterValue) => {
  if (!filterValue || filterValue.length === 0) {
    return [];
  }

  return [
    {
      value: filterValue,
      displayName: `"${filterValue}"`,
    },
  ];
};

export default TextSearchFilter;

import _ from 'lodash';
import qs from 'qs';

import api from '../../../mdms_api';
import cms_api from '../../../cms_api';
import { digChoicesFromData } from '../../../components/Builder/Library/AttributeSet';
import { unwrapRef } from '../../helpers';

const dataProviderV1 = async (ref) => {
  const url = `/api/v1/cms/sites/${PB_SITE}/data`;
  const queryHash = _.cloneDeep(ref);
  if (typeof(window) !== 'undefined' && typeof(window.PB_ZONE) !== 'undefined') {
    queryHash.zone = window.PB_ZONE;
  }
  const query = qs.stringify(queryHash, { arrayFormat: 'brackets', encodeValuesOnly: true })

  const response = await api.get(`${ url }?${ query }`)
  if (response.status === 200) {
    return await response.json();
  }
  if (response.status === 204) {
    return Promise.resolve({ meta: { parameters: ref.parameters }, data: null });
  }

  console.error(`Error retrieving data: ${response.status}`, response)
  return Promise.resolve({ meta: { parameters: ref.parameters }, data: null });
};

const getBaseQuery = (dataset, language) => {
  const type = {
    document_builder: 'Document',
    insulation_applications_blog: 'Article::Applications',
    insulation_industrial_blog: 'Article::InsulationIndustrial',
    insulation_residential_blog: 'Article::InsulationResidential',
    iss: 'Article::InsulationSolutionsSuite',
    lumber_blog: 'Article::Lumber',
    paroc_articles: 'Article::Paroc',
    products_insulation: 'Product::Insulation',
    products_paroc: 'Product::Paroc',
    products_roofing: 'Product::Roofing',
    roofing_blog: 'Article::Roofing',
  }[dataset];
  return {
    filter: {
      type: `Cms::Content::${type}`,
      language_iso_code: language,
      'metadata.redirect': 'null',
    },
    fields: { '*': 'contents,metadata,language_iso_code,content_uuid,route' },
  };
};

const getArticleQuery = (dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast) =>
  _.merge(
    getBaseQuery(dataset, language),
    {
      filter: {
        ...(excludeEmptyPublishDates ? { 'metadata.settings.details.information.published': '\x00' } : {})
      },
      sort: '-metadata.settings.details.information.published',
      ...(nullPubDatesSortLast ? { nulls_last: true } : {}),
    }
  );

const getDocumentQuery = (dataset, language) =>
  _.merge(
    _.omit(getBaseQuery(dataset, language), 'filter.language_iso_code'),
    {
      page: { number: 1, size: 1000 }
    }
  );

const getProductQuery = (dataset, language) =>
  _.merge(
    getBaseQuery(dataset, language),
    {
      filter: {
        metadata: {
          'settings.availability.status': ['active', 'external'],
          'settings.availability.visibility': 'listed',
        },
      },
    }
  );

const filterAggPromise = async (prop, query) => {
  const data = await cms_api.get_single_for_language(query, Board.build.language)
  const hasFilters = !!data;
  return hasFilters ? await digChoicesFromData(prop, data) : null;
};

const remapOptionByValue = (remappedByValue, option, parent, depth) => {
  remappedByValue[option.value] = { ...option, depth, parent };
  if (option.children) {
    option.children.forEach(c => remapOptionByValue(remappedByValue, c, remappedByValue[option.value], depth+1))
  }
}

// create a lookup of value to option, including a parent traversal
const remapOptionsByValue = (options) => {
  const remappedByValue = {};
  options?.forEach(c => remapOptionByValue(remappedByValue, c, null, 1));
  return remappedByValue;
}

const dataProviderV2 = async (ref) => {
  //var startTime = performance.now()

  const { dataset, language, parameters = {} } = ref;
  const { excludeEmptyPublishDates, nullPubDatesSortLast } = parameters;

  const query = {
    document_builder: getDocumentQuery(dataset, language),
    insulation_applications_blog: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    insulation_industrial_blog: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    insulation_residential_blog: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    iss: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    lumber_blog: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    paroc_articles: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
    products_insulation: getProductQuery(dataset, language),
    products_paroc: getProductQuery(dataset, language),
    products_roofing: getProductQuery(dataset, language),
    roofing_blog: getArticleQuery(dataset, language, excludeEmptyPublishDates, nullPubDatesSortLast),
  }[dataset];

  const source = parameters.source || parameters.productSource
  const promises = [];
  switch (source) {
    case 'specific': {
      const { items = [] } = parameters;
      promises.push(Promise.all(items.filter(item => item?.product || item?.item).map(async item => {
        // TODO: restructure helpers so we can use expandRef instead
        const [type, params, data] = unwrapRef(item.product || item.item);
        if (data) { return Promise.resolve(data); }
        return cms_api.get_single_for_language({
          filter: {
            content_uuid: params.id,
            ...(params.type ? { type: `Cms::Content::${params.type}` } : {}),
          },
          fields: {
            '*': 'content_uuid,name,contents,metadata,language_iso_code,route'
          },
        }, Board.build.language);
      })))
      break;
    }
    case 'all':
    case 'filtered': {
      const { limit = false, limitCount = 10 } = parameters;
      if (limit) {
        query.page = { number: 1, size: limitCount };
      }
      if (source === 'filtered') {
        const { filters } = parameters;
        Object.keys(filters).forEach(filter => {
          query.filter[filter] = filters[filter];
        });
      }
      promises.push(cms_api.get(query));
    }
  }

  const { showFilters, enabledFilters } = parameters;
  if (showFilters) {
    const attributeSetInfo = ({
      document_builder: {
        prop: 'metadata.settings.attributes',
        name: 'Document Attributes',
        route: '/',
      },
      insulation_applications_blog: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Applications Attributes',
        route: '/insulation',
      },
      insulation_industrial_blog: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Blog - Foamglas',
        route: '/insulation',
      },
      insulation_residential_blog: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Blog - Insulation Homeowner',
        route: '/insulation',
      },
      iss: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'ISS Article Attributes',
        route: '/insulation/insulation-solutions-suite/article',
      },
      lumber_blog: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Blog - Lumber',
        route: '/composites/lumber',
      },
      paroc_articles: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Article Attributes',
        route: '/',
      },
      products_insulation: {
        prop: 'metadata.settings.attributes',
        name: 'Insulation PDP Attributes',
        route: '/insulation',
      },
      products_paroc: {
        prop: 'metadata.settings.attributes',
        name: 'Product Attributes',
        route: '/',
      },
      products_roofing: {
        prop: 'metadata.settings.attributes',
        name: 'Roofing PDP Attributes',
        route: '/roofing',
      },
      roofing_blog: {
        prop: 'metadata.settings.details.taxonomies',
        name: 'Roofing Article Attributes',
        route: '/roofing/blog',
      },
    }[dataset])
    const { prop, ...attributeSet } = attributeSetInfo;
    const filterQuery = {
      filter: {
        type: 'Cms::Content::AttributeSet',
        ...attributeSet,
      },
      fields: {
        '*': 'contents,metadata',
      },
    };
    promises.push(filterAggPromise(prop, filterQuery));
  } else {
    promises.push(Promise.resolve(null));
  }

  if (dataset === 'document_builder') {
    const labelQuery = {
      filter: {
        type: 'Cms::Content::Attribute',
        name: [
          'Language',
          'Country Availability',
          'Document Type'
        ],
        route: '/',
      },
      fields: {
        '*': 'name,contents,language_iso_code',
      },
    }
    promises.push(cms_api.get(labelQuery));
  }

  const results = await Promise.all(promises);
  const meta = { parameters };
  if (showFilters) {
    // insulation_applications_blog is actually a PDP we treat like article
    const pickedFilters = dataset === 'insulation_applications_blog' ? enabledFilters.enabledFilters.map(f => f.replace(/metadata\.settings\.attributes/, 'metadata.settings.details.taxonomies')) : enabledFilters.enabledFilters
    meta.filters = _.pick(results[1], pickedFilters);
  }
  if (dataset === 'document_builder') {
    meta.labels =
      _.chain(results[2])
        .groupBy('name')
        .mapValues(v =>
          _.chain(v).groupBy('language_iso_code').mapValues(v => {
            const { items, ...rest } = v[0].contents || {};
            return { ...rest, items: remapOptionsByValue(items) };
          }).value()
        )
        .value()
  }

  //var endTime = performance.now()
  //console.log(`Call to dataProviderV2 took ${endTime - startTime} milliseconds`)

  return { meta, data: results[0] };
};

export default async (ref) => {
  const { dataset } = ref;
  switch (dataset) {
    case 'document_builder':
    case 'insulation_applications_blog':
    case 'insulation_industrial_blog':
    case 'insulation_residential_blog':
    case 'iss':
    case 'lumber_blog':
    case 'paroc_articles':
    case 'products_insulation':
    case 'products_paroc':
    case 'products_roofing':
    case 'roofing_blog':
      return dataProviderV2(ref);
    default:
      return dataProviderV1(ref);
  }
};

import { useState, useCallback } from 'react';

import { jsx, css } from '@emotion/react';

import { Grouped } from '../../PageBuilder/interface/vessels/Grouping';
import Labels from '../Labels';
import RichText from '../../OC/oc-rich-text';
import { getRequiredSuffix } from "../../../helpers/form_libs";

const errorAlert = css`
  color: #940420;
  font-size: 12px;
  font-weight: bold;
`

const ErrorDisplay = ({schema, field, errorSchema}) =>
((schema.required || []).includes(field) && errorSchema[field]) ?
<p aria-describedby={field} aria-errormessage css={errorAlert} role="alert">
  {schema.properties[field].title?.trim?.()} is required
</p> : null

const Field = ({ id, field, name, required, theme, value, onChange, uiSchema, errorSchema, schema, optionalFocused, elementType }) => (
  <div css={ Grouped.Theme.get(theme, 'Editor') } key={ field } >
    <Labels css={ Grouped.Theme.get(theme, 'Labels') } >
      <label htmlFor={ field } css={ Grouped.Theme.get(theme, 'Label') } >
        { name?.trim?.() }{getRequiredSuffix(optionalFocused, required, elementType)}
      </label>
    </Labels>
    <div css={ Grouped.Theme.get(theme, 'Wrapper') } >
      <input
        id={id}
        className="form-control"
        type="text"
        onChange={ onChange }
        name={ field }
        value={ value }
        required={ required }
      />
      { uiSchema?.['ui:help'] && <RichText content={ uiSchema?.['ui:help'] } /> }
      <ErrorDisplay schema={schema} field={field} errorSchema={errorSchema} />
    </div>
  </div>
);


const Title = ({ formData, onChange, schema, uiSchema, errorSchema, formContext }) => {
  const theme = Grouped.Theme.Standard;
  const optionalFocused = formContext?.optionalFocused;
  return (
    <div css={ Grouped.Theme.get(theme, [ 'Container', 'Horizontal' ]) } >
      <Field
        schema={schema}
        errorSchema={errorSchema}
        uiSchema={ uiSchema?.first }
        field="first"
        id="first"
        theme={ theme }
        required={ (schema.required||[]).includes('first') }
        name={ schema.properties.first.title }
        value={ formData.first }
        onChange={ (e) => onChange({...formData, first: e.target.value}) }
        optionalFocused={optionalFocused}
        elementType={schema.properties.first.type}
      />

      <Field
        schema={schema}
        errorSchema={errorSchema}
        uiSchema={ uiSchema?.last }
        field="last"
        id="last"
        theme={ theme }
        required={ (schema.required||[]).includes('last') }
        name={ schema.properties.last.title }
        value={ formData.last }
        onChange={ (e) => onChange({...formData, last: e.target.value}) }
        optionalFocused={optionalFocused}
        elementType={schema.properties.last.type} />
    </div>
  );
};

export default Title;

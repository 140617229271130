
import { jsx } from '@emotion/react';
import css from './styles';
import {connectLocation} from '../location/LocationConnectors';


export const Loader = connectLocation(({t, className, hideText}) => (
  <div className={className} css={css.loading}>
    <i className="fa fa-spinner"></i> {!hideText && "Loading..."}
  </div>
))

import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Components
import RichText from './RichText';
import CtaList from './CtaList';
import AlertMessage from './AlertMessage';

// Heroes
import HeroT3 from '../oc-hero';
import HeroT2 from '../oc-hero-tier-2';
import HeroT1 from '../oc-hero-tier-1';
import HeroBlog from '../oc-hero-blog';
import CommercialInteractiveHero from '../../Insulation/Commercial/CommercialInteractiveHero';
import ResidentialInteractiveHero from '../../Insulation/Residential/ResidentialInteractiveHero';
import RoofingComponentsInteractiveHero from '../../Insulation/Roofing/RoofingComponentsInteractiveHero';

// Utilities
import Viewport from "../../PageBuilder/editing/Viewport";

// Styled Components
const HeroAlert = styled(AlertMessage)`
  margin-bottom: 24px;
`;

export const Presets = {
  roofing_t1: {
    type: 'tier_1',
    grid: {
      mobile: {
        dim: [2,3],
        columns: 'repeat(3, 1fr)',
        rows: 'repeat(2, auto)'
      },
      tablet: {
        dim: [14,20],
        columns: '1fr 1fr 1fr repeat(8, 64px) 1fr 1fr 1fr',
        rows: 'repeat(20, 20px)'
      },
      desktop: {
        dim: [14, 16],
        columns: '1fr repeat(12, 100px) 1fr',
        ms_columns: '1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr',
        rows: 'repeat(16, 35px)',
        ms_rows: '35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px'
      },
      fill: {
        desktop: {
          ltf: [0,2,0,1],
          mf: [3,7, 0,15],
          lf: [2,0, 2,3],
          rf: [11,0, 6,6],
          rbf: [11,2, 13,2]
        },
        tablet: {
          ltf: [0,2, 0,3],
          mf: [3,7, 0,19],
          lf: [2,0, 4,4],
          rf: [11,0, 9,6],
          rbf: [11,2, 16,3]
        }
      },
      main: {
        mobile: {
          column: [1,3],
          row: [1,0],
          css: "padding: 0 15px;"
        },
        tablet: {
          column: [3,9],
          row: [1, 8]
        },
        desktop: {
          column: [4,8],
          row: [1, 10]
        }
      },
      panther: {
        mobile: {
          column: [1,0],
          row: [1,0],
          size: [96, 111],
          css: "left: 15px;"
        },
        tablet: {
          column: [4,3],
          row: [11,7],
          size: [121, 140]
        },
        desktop: {
          column: [4,3],
          row: [7,8],
          size: [220, 255],
          css: "left: 30px;"
        }
      },
      textContent: {
        mobile: {
          column: [1,3],
          row: [2,0],
          css: 'margin-top: 16px; padding: 0 15px;'
        },
        tablet: {
          column: [7,5],
          row: [8,12],
          css: ".button-wrapper {flex-direction: column;}; .button-wrapper a { margin-bottom: 16px;};"
        },
        desktop: {
          column: [7,5],
          row: [11,5],
          css: ".button-wrapper {flex-direction: row;}; .button-wrapper a { margin-bottom: 0;}; "
        }
      }
    },
    pantherImg: 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1628595461/use-everywhere/panther/hero-panther-artist.png',
    bgVideoWebM: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1621524398/roofing/roofing-hero-video-webm.webm',
    bgVideoMp4: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1621524251/roofing/roofing-hero-video-mp4.mp4'
  },
  insulation_t1: {
    type: 'tier_1',
    grid: {
      mobile: {
        dim: [3,2],
        columns: 'repeat(3, 1fr)',
        rows: 'repeat(2, auto)'
      },
      tablet: {
        dim: [14,14],
        columns: '1fr repeat(12, 64px) 1fr',
        rows: 'repeat(14, 35px)'
      },
      desktop: {
        dim: [14, 20],
        columns: '1fr repeat(12, 100px) 1fr',
        ms_columns: '1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr',
        rows: 'repeat(20, 35px)',
        ms_rows: '35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px'
      },
      fill: {
        desktop: {
          ltf: [0,3, 0,4],
          mf: [4,6, 0,19],
          lf: [2,1, 5,11],
          rf: [11,0, 8,11],
          bf: [11,2, 12,7]
        },
        tablet: {
          ltf: [0,3, 0,2],
          mf: [4,6, 0,13],
          lf: [2,1, 3,8],
          rf: [11,0, 6,7],
          bf: [12,1, 9,4]
        }
      },
      main: {
        mobile: {
          column: [1,3],
          row: [1,1],
          css: "padding: 0 15px;"
        },
        tablet: {
          column: [4,8],
          row: [1, 8]
        },
        desktop: {
          column: [4,8],
          row: [1, 8]
        }
      },
      panther: {
        mobile: {
          column: [1,0],
          row: [2, 0],
          css: "left: 20px;"
        },
        tablet: {
          column: [3,3],
          row: [5,6],
          size: [155, 180],
          css: "left: 19px; top: 0; background-position: left top;"
        },
        desktop: {
          column: [3,3],
          row: [7,9],
          size: [200, 232],
          css: "left: 14px; top: 0;"
        }
      },
      textContent: {
        mobile: {
          column: [2,2],
          row: [2,0],
          css: 'margin-top: 16px; padding: 0 15px;'
        },
        tablet: {
          column: [5,8],
          row: [9,4],
          css: 'padding: 0 15px;'
        },
        desktop: {
          column: [6,6],
          row: [14,4]
        }
      }
    },
    pantherImg: 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1628532171/use-everywhere/panther/dsbuf6mo5if6ka1nsxza.png',
    bgVideoWebM: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1628102317/insulation/hero-video-webm.webm',
    bgVideoMp4: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1628102312/insulation/hero-video-mp4.mp4'
  },
  composites_t1: {
    type: 'tier_1',
    grid: {
      mobile: {
        dim: [2,3],
        columns: 'repeat(3, 1fr)',
        rows: 'repeat(2, auto)'
      },
      tablet: {
        dim: [14,13],
        columns: '1fr repeat(12, 64px) 1fr',
        rows: 'repeat(11, 40px) repeat(2, 20px)'
      },
      desktop: {
        dim: [14, 20],
        columns: '1fr repeat(12, 100px) 1fr',
        ms_columns: '1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr',
        rows: 'repeat(20, 35px)',
        ms_rows: '35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px'
      },
      fill: {
        desktop: {
          ltf: [0,3,0,7],
          mf: [4,6, 0,19],
          lf: [3,0, 8,5],
          rf: [11,0, 8,11],
          lbf: [0,1, 16,3],
          bf: [2,1, 19,0]
        },
        tablet: {
          ltf: [0,2, 0,3],
          mf: [4,6, 0,12],
          lf: [3,0, 4,2],
          rf: [11,0, 4,8],
          lbf: [0,1, 9,3],
          bf: [2,1, 12,0]
        }
      },
      main: {
        mobile: {
          column: [1,10],
          row: [1,4],
          css: "padding: 0 15px;"
        },
        tablet: {
          column: [2,10],
          row: [1, 4]
        },
        desktop: {
          column: [2,10],
          row: [1, 8]
        }
      },
      panther: {
        mobile: {
          column: [1,0],
          row: [6, 11],
          css: "left: 20px;"
        },
        tablet: {
          column: [10,3],
          row: [5,9],
          size: [160, 186],
          css: "left: 15px; top: 75px; background-position: 0px 20px;"
        },
        desktop: {
          column: [10,3],
          row: [9,10],
          size: [231, 269],
          css: "left: 20px; top: 0; background-position: right 12px top 50px;"
        }
      },
      textContent: {
        mobile: {
          column: [2,5],
          row: [5,11],
          css: 'margin-top: 16px; padding: 0 15px;'
        },
        tablet: {
          column: [5,5],
          row: [5,11],
          css: 'padding-left: 90px;'
        },
        desktop: {
          column: [6,4],
          row: [8,7]
        }
      }
    },
    pantherImg: 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1575633961/use-everywhere/panther/hero-panther-point-left.png',
    bgVideoWebM: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1628102515/composites/hero-video-webm.webm',
    bgVideoMp4: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1628102511/composites/hero-video-mp4.mp4'
  },
  home_t1: {
    type: 'tier_1',
    grid: {
      desktop: {
        dim: [14,11],
        columns: '1fr 1fr 1fr repeat(8, 100px) 1fr 1fr 1fr',
        ms_columns: '1fr 1fr 1fr 1fr 100px 100px 100px 100px 100px 100px 100px 100px 1fr 1fr 1fr 1fr',
        rows: '35px 35px repeat(9, 70px)',
        ms_rows: '35px 35px 70px 70px 70px 70px 70px 70px 70px 70px 70px'
      },
      tablet: {
        dim: [14,15],
        columns: ' 1fr 1fr 1fr repeat(8, 64px) 1fr 1fr 1fr',
        rows: '20px 20px repeat(13, 40px)'
      },
      mobile: {
        dim: [3,2],
        columns: 'repeat(3, 1fr)',
        rows: 'repeat(2, auto)',
        css: 'grid-gap: 24px;'
      },
      fill: {
        desktop: {
          tf: [0,4, 0,0],
          lf: [3,0, 5,4],
          mf: [4,6, 0,9],
          bf: [0,9, 10,0]
        },
        tablet: {
          tf: [0,3, 0,0],
          mf: [4,6, 0,11],
          lf: [3,0, 5,6],
          bf: [0,9, 12,1]
        }
      },
      main: {
        desktop: {
          column: [6,6],
          row: [1,7]
        },
        tablet: {
          column: [6,6],
          row: [1,8]
        },
        mobile: {
          column: [1,2],
          row: [1,0]
        }
      },
      panther: {
        desktop: {
          column: [4,3],
          row: [6,5],
          size: [186, 216],
          css: 'left: 15px; background-position: left 30px;'
        },
        tablet: {
          column: [4,3],
          row: [6,7],
          size: [115, 133],
          css: 'background-position: left 22px top 35px; left: 0;'
        },
        mobile: {
          column: [3,0],
          row: [1,0],
          css: 'left: 0; top: 8px;'
        }
      },
      textContent: {
        desktop: {
          column: [5,7],
          row: [8,3],
          css: 'padding-top: 0; padding-left: 0; padding-right: 30px; .body-text {margin: 0 0 30px;} .button-wrapper {display: flex; padding: 0; flex-direction: row;}',
          textCss: 'padding-left: 230px; margin-bottom: 29px;'
        },
        tablet: {
          column: [7,5],
          row: [8,8],
          css: 'padding-top: 14px; padding-right: 0; .body-text {margin-left: 0; margin-right: 30px;} .button-wrapper {display: flex; width: 100%; padding: 0 15px 0 0; flex-direction: column;}'
        },
        mobile: {
          column: [1,3],
          row: [2,0],
          css: '.button-wrapper {display: flex;}'
        }
      }
    },
    pantherImg: 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1628532171/use-everywhere/panther/dsbuf6mo5if6ka1nsxza.png',
    bgVideoWebM: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1612196942/home/videos/home-video-webm.webm',
    bgVideoMp4: 'https://imagecdn.owenscorning.com/ocimages/video/upload/v1612196949/home/videos/home-video-mp4.mp4'
  },
  com_insulation_hero: {
    type: 'com_hotspot'
  },
  res_insulation_hero: {
    type: 'res_hotspot'
  },
  roofing_components_hero: {
    type: 'roofing_components_hotspot'
  },
};

const Heros = {
  tier_3: ({ copy, images, imgAlt, cta, ctas, showHeroImage, contentOrder, imageUrl, ...props }) => (
    <HeroT3
      showHeroImage={ showHeroImage }
      contentOrder={ contentOrder }
      imgDesktop={ images?.bgDesktop }
      imgTablet={ images?.bgTablet }
      imgAlt={ imgAlt }
      imageUrl={ imageUrl }
      prehead={ copy?.prehead }
      h1={ copy?.mainHeading }
      posthead={ copy?.posthead }
      body={ copy?.body }
      ctas={ ctas }
    />
  ),
  tier_2: ({ copy, images, logo, ctas, cutawayImage, ...props }) => (
    <HeroT2
      imgDesktop={ images?.bgDesktop }
      imgTablet={ images?.bgTablet }
      prehead={ copy?.prehead }
      h1={ <RichText content={ copy?.mainHeading } inline /> }
      body={ <RichText content={ copy?.body } /> }
      logoSize={ logo?.logoSize }
      logo={ logo?.logo }
      imgAlt={ logo?.imgAlt }
      ctas={ <CtaList ctas={ ctas } /> }
      logoPosition={ logo?.logoPosition }
      cutawayImage={ cutawayImage?.image }
      cutawayImageSize={ cutawayImage?.size }
      cutawayImageAlt={ cutawayImage?.imgAlt }
    />
  ),
  tier_1: ({ copy, ctas, panther, grid, background, bgVideoMp4, bgVideoWebM, pantherImg, ...props }) => (
    <HeroT1
      imgDesktop={ background?.desktop }
      imgTablet={ background?.tablet }
      videoWebm={ background?.webm ? background?.webm : bgVideoWebM }
      videoMp4={ background?.mp4 ? background?.mp4 : bgVideoMp4 }
      mediaType={ background?.mediaType }
      prehead={ copy?.prehead }
      heading={ <RichText content={ copy?.mainHeading } inline /> }
      body={ <RichText content={ copy?.body } /> }
      ctas={ <CtaList ctas={ ctas } /> }
      panther={ panther?.image?.file ? panther?.image?.file : pantherImg }
      grid={ grid }
    />
  ),
  tier_4: ({ copy, images, mobileAspectRatio, tags, author, published, ...props }) => (
    <HeroBlog
      imgDesktop={ images?.bgDesktop }
      imgTablet={ images?.bgTablet }
      imgMobile={ images?.bgMobile }
      mobileAspectRatio={mobileAspectRatio}
      prehead={ copy?.prehead }
      h1={ <RichText content={ copy?.mainHeading } inline /> }
      tags={ tags }
      author={ author }
      publishDate={ published }
    />
  ),
  res_hotspot: () => (
    <ResidentialInteractiveHero />
  ),
  com_hotspot: () => (
    <CommercialInteractiveHero />
  ),
  roofing_components_hotspot: () => (
    <RoofingComponentsInteractiveHero />
  )
};

const CMSHero = ({ alertMessage = {}, onClick, selected, preset, type, ...props }) => {
  preset = preset || 'custom';
  type = type || props[preset]?.type;

  if (type in Presets) preset = type;

  const Preset = Presets[ preset ];
  if (Preset) type = Preset.type;
  if (!Object.keys(Heros).includes(type)) type = 'tier_3';

  const Hero = Heros[ type ];
  const heroProps = _.mergeWith({}, props, Preset, (newVal, srcVal) => newVal || srcVal);

  return (
    <div css={css`
      transition: all 0.2s;

      ${selected && css`
        outline: 3px solid rgba(212,15,125,0);
        outline-color: #D40F7D;
        outline-offset: 14px;

        @media (max-width: ${Viewport.Screens[Viewport.Device.Desktop] - 1}px) {
          outline-offset: 11px;
        }
      `}

      & > div {
        @media (max-width: 1199px) {
          background-size: cover;
        }
      }
    `}
     onClick={ onClick }
    >
      {alertMessage.display &&
        <HeroAlert
          dismissable={alertMessage.dismissable}
          message={alertMessage.message}
          title={alertMessage.title}
          type={alertMessage.type}
        />
      }

      <Hero { ...heroProps } />
    </div>
  );
};

export default CMSHero;

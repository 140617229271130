import { jsx, css } from '@emotion/react';
import Picture from './oc-picture';

const imgStyles = css`
  outline: 1px dashed red;
`

const Image = ({ src, alt, transform=null, ...props }) => {
  if (!src) {
    return null;
  }
  const transformedSrc = Picture.Transform(src, transform);
  if (process.env.NODE_ENV === 'development' && (!alt || src?.indexOf('localhost') > -1)) {
    return (
      <img css={imgStyles} src={transformedSrc} alt={alt} {...props} />
    )
  } else {
    return (
      <img src={transformedSrc} alt={alt} {...props} />
    )
  }
}

export default Image;

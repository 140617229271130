import { define, ui, s, when } from '@owenscorning/pcb.alpha';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import RichText from './RichText';

const Container = styled.div`
  display: grid;
  padding-right: 8px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
    column-gap: 5px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 970px;
    margin: auto;
    padding-right: 0px;
  }
`;
const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

const Image = styled.img`
  width: 90px;
  height: min-content;
  padding-left: 12px;
  @media screen and (min-width: 767px) {
    width: 105px;
  }

  @media screen and (min-width: 1200px) {
    width: 144px;
  }
`;
const renderCss = {
  section: () => css`
    background-color: black;
    margin-bottom: 10px;
  `,
  h4: () => css`
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: white;
    line-height: 26px;
    @media screen and (min-width: 1200px) {
      font-size: 28px;
      line-height: 30px;
    }
  `,
  labelTextContainer: () => css`
    padding-left: 12px;
    font-size: 10px;
    color: white;
    overflow-wrap: break-word;
    max-width: 260px;
    @media screen and (min-width: 767px) {
      font-size: 14px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 16px;
      max-width: 341px;
    }
    p {
      padding-bottom: 0;
      color: white;
    }
  `,
};

export default function BannerPole({ settings = {}, content = [], image={} }) {
  return (
    <div css={renderCss?.section}>
      <Container>
        {content?.map((portion, i) => (
          <SubContainer key={i}>
            <Image
              src={
                image?.file ||
                'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto/v1579701307/roofing/nail-zone/9outof10.png'
              }
              alt={image?.alt}
            />
            
              <div css={renderCss?.labelTextContainer}>
                <h4 css={renderCss?.h4}>{portion?.title}</h4>
                {settings?.textStyling === 'custom' && (
                  <p>
                    <RichText content={portion?.content} />
                  </p>
                )}
                {settings?.textStyling === 'default' && (
                  <p>
                    {portion?.content?.ops?.[0]?.insert}
                  </p>
                )}
              </div>
            
          </SubContainer>
        ))}
        ,
      </Container>
    </div>
  );
}

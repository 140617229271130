import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import { H3 } from './oc-h';
import Theme from '../../../themes/index';

export const FilterSectionHeading = styled(H3)`
  font-size: 28px !important;
`;

export const FilterWrapper = styled.div`
  display: inline-block;
  padding-bottom: 2em;
  padding-top: 2em;
  border-top: 1px solid;
  position: relative;
  width: 100%;

  &.no-top {
    border: 0;
    padding-top: 0;
  }
`;

export const FilterHeading = styled.h4`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
  }
`;

export const FilterSubHeading = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 5px 0;
`;

export const FilterContent = styled.div`
  display: ${props => (props.collapsed ? 'none' : 'inline-block')};
  padding-top: 1em;
  position: relative;
  width: 100%;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
`;

import { css } from '@emotion/react';
import Theme from '../../../themes';

const Device = ({ type, selected, onClick }) => (
  <div
    onClick={ () => { onClick(type) } }
    css={css`
      color: ${ selected === type ? Theme.colors.brand : '#959595' };
      cursor: pointer;
      user-select: none;
      margin-left: 21px;
      height: 24px;
      ${
        {
          [UI.Viewport.Device.Desktop]: 'font-size: 25px; line-height: 26px;',
          [UI.Viewport.Device.Tablet]: 'font-size: 30px; line-height: 0px; position: relative; top: -1px;',
          [UI.Viewport.Device.Mobile]: 'font-size: 33px; line-height: 0px; position: relative; top: -4px; margin-right: 6px;'
        }[type]
      }
    `}
  >
    <UI.Icon
      type={ {
        [UI.Viewport.Device.Desktop]: 'desktop',
        [UI.Viewport.Device.Tablet]: 'tablet',
        [UI.Viewport.Device.Mobile]: 'mobile'
      }[type] }
    />
  </div>
);

export default ({ back, text = "BACK" }) => (
  <div css={css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 24px;
    font-size: 18px;
    background-color: rgb(248, 248, 248);
  `} >
    {
      back && <a href={ back } css={ css` text-decoration: none; ` }>
        <UI.Header.Action icon={ UI.Images.ChevronBlackLeft } styling={ css` margin: 0; line-height: 0px; ` } text={text} />
      </a>
    }
    <div css={css` display: flex; align-items: center; margin-left: auto; `} >
      {
        _.map(UI.Viewport.Device, (type) => (
          <Device
            key={ type }
            type={ type }
            selected={ Board.viewport.device || UI.Viewport.Default }
            onClick={ Board( () => Board.viewport.device = type ) }
          />
        ))
      }
    </div>
  </div>
);

import { useState, useMemo, useEffect } from 'react';
import _ from 'lodash'

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DesignEyeQInstructions,
  DesignInspireInstructions,
  TotalProtectionRoofingInstructions,
  WarrantyInstructions
} from './oc-content-instructions';
import { inVisibilityGroupList } from '../../../PageBuilder/helpers/content';

const Spinner = styled.div`
  padding: 10px;
  height: 150px;
  margin: auto;
  width: 25%;
`;

const RoofingWidgetsContainer = styled.div`
display: flex;
padding: 0 0 0;
flex-direction: ${props => props.columns === 'two' && 'column'};
flex-direction: ${props => props.columns === 'one' && 'column'};
flex-direction: ${props => props.contentOrder === 'bottom' && 'column-reverse'};
gap: 24px;
object-fit: contain;
height: fit-content;
width: 100%;
justify-content: space-between;
overflow-wrap: break-word;

.content {
  flex-basis: 100%;
}

.preview {
  flex-basis: 100%;
  width: 100%;
}

@media (min-width: 767px) {
  flex-direction: ${props => props.columns === 'two' && 'column'};
}

@media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  flex-direction: ${props => props.columns === 'two' && 'row'};
  flex-direction: ${props => props.contentOrder === 'right' && 'row-reverse'};
  object-fit: contain;

}

.content {
  width: 100%;
}

@media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .preview {
    max-width: 500px;
  }
}

${props => props.columns == 'one' && css`


.preview {
  width: 100%;
}

.widget {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: contain;
  overflow: hidden;
}

@media (min-width: 767px) {
  .preview {
    max-width: 100vw;
    height: auto;
  }
}
`};
`;

const RoofingWidgets = ({ widgetOption, columns, contentOrder, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [checked, setChecked] = useState([
    {
      label: 'Standard Product Limited Warranty',
      checked: true,
      value: '&standard=true',
      url: 'data-standard-coverage',
    },
    {
      label: 'System Protection Limited Warranty',
      checked: true,
      value: '&systemProtection=true',
      url: 'data-system-protection',
      showFor: ['contractor:occn_us:2', 'contractor:occn_us:3']
    },
    {
      label: 'Preferred Protection Limited Warranty',
      checked: true,
      value: '&preferredProtection=true',
      url: 'data-preferred-protection',
      showFor: ['contractor:occn_us:2', 'contractor:occn_us:3']
    },
    {
      label: 'Platinum Protection Limited Warranty',
      checked: true,
      value: '&platinumProtection=true',
      url: 'data-platinum-protection',
      showFor: ['contractor:occn_us:3']
    }
  ]);

  useEffect(()=> {
    reloadWidget()
  }, [checked]);  

  const handleClick = (index) => {
    const arrayCopy = [...checked]
    arrayCopy[index].checked = !arrayCopy[index].checked;
    setChecked(arrayCopy)

  }

  let filteredList = checked.filter(item => {
    if (!item.showFor || inVisibilityGroupList(item.showFor)) {
      return item
    }
    else return item == checked[0]
  })

  const renderWarranties = useMemo(() => {
    let checkedList = filteredList.filter(item => item.checked == true)
    const finalList = checkedList.map(item => {
      return item.value
    })
    return finalList.join('')
  }, [filteredList])

  const fetchFilteredWarranties = filteredList.filter(item => item.checked == true).reduce((object, item) => ({
    ...object,
    [item.url]: true
  }), {});

  const reloadWidget = () => {
    setFetching(true);
    fetch(`${window.location.origin}/en-us/widgets/public-widgets.js`)
      .then(response => response.text())
      .then(data => {
        // remove existing public widget script otherwise we will have multiple script tags
        const widget_script = document.getElementById('widget');
        if(widget_script)
          document.body.removeChild(widget_script);

        // reset the oc_widget_loaded lock
        window.oc_widget_loaded = false;
        // remove existing iframe
        var oc_warranty = document.getElementsByClassName('oc_warranty');
        if(oc_warranty && oc_warranty.length > 0) {
          oc_warranty[0].innerHTML = ''
        }
        const script = document.createElement('script');
        script.setAttribute("id", "widget");
        script.text = data;
        document.body.appendChild(script);
        setFetching(false);
      })
      .catch(error => {
        console.error('Error fetching widget:', error);
      });
  }

  const HtmlForWidget = ({widgetOption}) => {
    if (widgetOption == 'totalProtectionRoofingSys') {
      return <div className="total_protection_roofing_system"></div>;
    } else if (widgetOption == 'warranty') {
      return <div {...fetchFilteredWarranties} className="oc_warranty"></div>;
    } else if (widgetOption == 'designEyeQ') {
      return <div id="visualizer" data-zip="90210"></div>;
    } else {
      return <div className="oc_design_and_inspire"></div>;
    }
  };

const renderInstructions = () => {
  if (widgetOption == 'designEyeQ') {
    return <DesignEyeQInstructions widgetOption={widgetOption} />
  }
  if (widgetOption == 'totalProtectionRoofingSys') {
    return <TotalProtectionRoofingInstructions widgetOption={widgetOption} />
  }
  if (widgetOption == 'warranty') {
    return <WarrantyInstructions widgetOption={widgetOption} checked={filteredList} setChecked={setChecked} handleClick={handleClick} />
  }
  else return <DesignInspireInstructions widgetOption={widgetOption} />
}

return (
  <RoofingWidgetsContainer contentOrder={contentOrder} columns={columns} widgetOption={widgetOption}>
    <div className="content">
      {renderInstructions()}
    </div>
    <div className="preview">
      { fetching && <Spinner><UI.Icon type="spinner" spin large style={{ fontSize: "9em", color: "#d40f7d" }} /></Spinner> }
      <HtmlForWidget widgetOption={widgetOption} />
    </div>
  </RoofingWidgetsContainer>
)
}

export default RoofingWidgets;



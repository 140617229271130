import { Component } from 'react';

import Contents from './Contents';
import { connectFeatureFlag } from '../../FeatureFlag/FeatureFlagConnectors';
import Modal from '../../Modal';
import { connectLocation } from '../LocationConnectors';
import { makeT, getZipInfo } from '../locales';

const LocationModalView = connectFeatureFlag(
  ({
    locationModalOpen = false,
    closeLocationModal,
    enabledFeatureFlags,
    ...props
  }) => (
    <Modal
      className="location-modal"
      name="Region Selector"
      open={locationModalOpen}
      onClose={closeLocationModal}
      size="sm"
    >
      <Contents {...props} />
    </Modal>
  )
);

class LocationModalController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      zip: props.zip,
      locale: props.locale,
    };
  }

  componentDidMount() {
    const { forceReload, forceReloadRedirectPath } = this.props;
    const locationChangeShouldForceReload = forceReload;

    this.props.setLocationModalForceReload({ locationChangeShouldForceReload, forceReloadRedirectPath });
    // If locale selection is disabled, force first available locale
    if(!this.props.showLocale && this.props.availableLocales.length > 0) {
      this.updateLocation({ locale: this.props.availableLocales[0] })
    }
  }

  onSuccess(locale, zip) {
    this.setState({ error: null });
    this.props.changeLocation({ locale, zip });
    this.props.closeLocationModal();
  };

  onSubmit() {
    const { locale, zip } = this.state;
    const { postalRequired, postalDesired, showZip } = this.props;
    let shouldShowZip = showZip || postalRequired || postalDesired;
    const zipInfo = getZipInfo(locale.code)

    // normalize options
    // if no zip format, can't show zip, even if we want to
    if (!zipInfo) {
      shouldShowZip = false;
    }

    if (shouldShowZip) {
      if (!zipInfo.validator(zip, postalRequired, postalDesired)) {
        this.setState({ error: true });
        return;
      } else {
        fetch(MDMS_URL + '/api/v1/validzip?zip=' + zip + '&c_code=' + locale.code)
            .then(result => result.json())
            .then(data => {
              if (data.valid) {
                this.onSuccess(locale, data.zip_code || zip)
              } else {
                this.setState({ error: true });
              }
            })
            .catch(error => console.error('Error:', error))
      }
    } else {
      this.onSuccess(locale, zip)
    }
  }

  updateLocation(attributes) {
    this.setState(({ zip, locale }) => ({ zip, locale, ...attributes }))
  }

  render() {
    const { locationModalOpen, closeLocationModal, userLocale, showLocale, postalRequired, postalDesired, hideFlag, translationPrefix, availableLocales, mismatched, showZip } = this.props;
    const shouldShowZip = showZip || postalRequired || postalDesired;
    const t = makeT(this.state.locale.code);

    return (
      <LocationModalView
        onChangeZip={zip => this.updateLocation({ zip: zip.toUpperCase() })}
        onSubmit={() => this.onSubmit()}
        showLocale={showLocale}
        showZip={shouldShowZip}
        hideFlag={hideFlag}
        translationPrefix={translationPrefix}
        setLocaleCode={localeCode => this.updateLocation({ locale: availableLocales.find(l => l.code === localeCode) })}
        locationModalOpen={locationModalOpen}
        closeLocationModal={closeLocationModal}
        availableLocales={availableLocales}
        mismatched={mismatched}
        userLocale={ userLocale }
        t={t}
        {...this.state}
      />
    );
  }
};

export default connectLocation(LocationModalController);

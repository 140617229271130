import _ from 'lodash';

import Cookies, {
  USER_ZIP_COOKIE,
  USER_LOCALE_COOKIE,
} from './PageBuilder/helpers/cookies';

export default (store) => {
  if (typeof(window) !== 'undefined') {
    const samesite = typeof (COOKIE_DOMAIN) !== "undefined" && location.protocol === 'https:' ? `;SameSite=None;Secure` : '';

    let currentZip = null;
    let currentLocale = null;
    let currentFeatureFlags = [];

    // ZIP or locale change
    return store.subscribe(() => {
      let previousZip = currentZip;
      let previousLocale = currentLocale;
      let previousFeatureFlags = currentFeatureFlags;
      currentZip = _.get(store.getState(), 'location.zip');
      currentLocale = _.get(store.getState(), 'location.locale.code');
      currentFeatureFlags = _.get(store.getState(), 'featureFlag.previewFeatureFlags');
      const forceReload = _.get(store.getState(), 'location.locationChangeShouldForceReload');
      const forceReloadRedirectPath = _.get(store.getState(), 'location.forceReloadRedirectPath');
      const filtersToReset = _.get(store.getState(), 'location.filtersToReset', []);
      const availableLocales = _.get(store.getState(), 'location.availableLocales', []);
      let zipChanged = previousZip != currentZip;
      let localeChanged = previousLocale !== null && previousLocale != currentLocale;
      let featureFlagsChanged = (!(_.isEqual(previousFeatureFlags, currentFeatureFlags)) && !(_.isEqual(previousFeatureFlags, [])));

      if (zipChanged) {
        document.cookie = `${USER_ZIP_COOKIE}=ZipCode=${currentZip || ''};max-age=${60 * 60 * 24 * 365};path=/${samesite}`;

        if (previousZip !== null) {
          let token = window.location.pathname.match("^(/[^/]+/roofing/contractors)/[^/]+/[^/]+$");

          if (token !== null) {
            window.location.replace(token[1]);
            return;
          }
        }
      }

      if (localeChanged) {
        document.cookie = `${USER_LOCALE_COOKIE}=${currentLocale};max-age=${60 * 60 * 24 * 365};path=/${samesite}`;
      }

      if (featureFlagsChanged) {
        const newCookiedFlags = Cookies.getFeatureFlags()?.enabled?.filter(x => !previousFeatureFlags.includes(x) || currentFeatureFlags.includes(x)) || []
        const features = window.escape(`{"enabled":[${newCookiedFlags.map(x => `\"${x}\"`)}]}`);
        document.cookie = `feature_flags_v2=${features};max-age=${60 * 60 * 24 * 365};path=/`;
        window.location.reload();
      }

      if ((forceReload && zipChanged) || (localeChanged && previousLocale !== null)) {
        const currentPath = window.location.pathname + window.location.search;
        const previousLocalePathSegment = _.toString(previousLocale).toLowerCase();
        const currentLocalePathSegment = _.toString(currentLocale).toLowerCase();
        let newPath = currentPath;

        if (availableLocales.length > 1) {
          const regex = RegExp(`^/(${previousLocalePathSegment}/?)?`, 'i');
          const isRoot = currentPath === `/${previousLocalePathSegment}`
          newPath = isRoot ? `/${currentLocalePathSegment}` : currentPath.replace(regex, `/${currentLocalePathSegment}/`);
        }

        if (forceReload && forceReloadRedirectPath) {
          newPath = `/${currentLocalePathSegment}${forceReloadRedirectPath}`;
        }

        if (filtersToReset.length > 0) {
          const searchParams = new URLSearchParams(window.location.search);
          filtersToReset.forEach(filter => {
            if (searchParams.has(filter)) {
              searchParams.delete(filter)
            }
          });
          newPath = `${newPath.split('?')[0]}?${searchParams.toString()}`
        }

        if (newPath !== null && newPath !== currentPath) {
          window.location.replace(newPath);
        } else {
          window.location.reload();
        }
      }
    });
  }
}




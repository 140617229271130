import { useState, useEffect, isValidElement } from 'react';
import { css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import ThemeStyle from '../../../../themes';

const Themes = {};

Themes.Standard = {
  Wrapper: styled.div`
    ${ ({ fullWidth }) => fullWidth ? 'width: 100%;' : '' }
    display: flex;
    padding-top: ${ ({ standalone }) => standalone ? 0 : 32 }px;
    padding-bottom: 24px;
    &:last-child { padding-bottom: 0px; }
  `,
  Container: styled.div`
    background-color: #FFF;
    border: 1px solid #959595;
    border-radius: 8px;
    padding: 16px;
    flex-grow: 1;
    min-width: 0;
  `,
  Body: styled.div`
    display: ${ ({ open }) => open ? 'block' : 'none' };
    padding-top: ${ ({ subtitle }) => subtitle ? 16 : 10 }px;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
  `,
  Toggle: styled.div`
    ${ ({ dropdown, showChildren }) => dropdown && showChildren && css` cursor: pointer; ` }
    text-decoration: none;
    user-select: none;
    font-size: 22px;
    width: 28px;
    height: 20px;
    line-height: 0px;
    flex-shrink: 0;
  `,
  Heading: {
    Container: styled.div`
      flex-grow: 1;
      user-select: none;
      ${ ({ dropdown, showChildren }) => dropdown && showChildren && css` cursor: pointer; ` }
    `,
    Bookmark: styled.div`
      transition: all 0.3s;
      font-size: ${ ({ title }) => title ? 14 : 22 }px;
      font-weight: ${ ({ title }) => title ? 'normal' : 'bold' };
      color: ${ ({ title }) => title ? '#585858' : '#000' };
      padding: ${ ({ title }) => title ? '0' : '6px 0' };
    `,
    Title: styled.div`
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      opacity: ${ ({ title }) => title ? 1.0 : 0.0 };
      transition: opacity 0.3s;
    `
  },
  Tools: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 60px;
    gap: 8px;
  `
};

Themes.Minimal = {
  Wrapper: styled.div`
    display: flex;
  `,
  Container: styled.div`
    flex-grow: 1;
  `,
  Body: styled.div`
    display: none;
  `,
  Header: styled.div`
    min-height: 40px;
    display: flex;
  `,
  Toggle: styled.div`
    display: none;
  `,
  Heading: {
    Container: styled.div`
      flex-grow: 1;
      padding-top: 10px;

      user-select: none;
      cursor: pointer;

      &:hover {
        color: ${ThemeStyle.colors.brand};
        text-decoration: underline;
      }
    `,
    Bookmark: styled.div`
      display: ${ ({ title }) => title ? 'none' : 'block' };
    `,
    Title: styled.div``
  },
  Tools: Themes.Standard.Tools
};

const Item = ({ title, subtitle, actions, tools, reference, minimal, unwrapped, standalone, showChildren=true, children, dropdown=true, startsOpen=false, hideable=false, value, onChange, opened, onOpen, onClose, onClick, fullWidth=false, id, setOpenController }) => {
  const controlled = opened != undefined;
  const [ open, setOpen ] = useState(dropdown ? startsOpen : true);
  if(setOpenController){
    setOpenController(setOpen);
  }

  useEffect(() => { !controlled && ( open ? onOpen?.() : onClose?.() ); }, [ open ]);
  const toggle = () => dropdown && ( controlled ? ( opened ? onClose() : onOpen() ) : setOpen(!open) );

  const Item = minimal ? Themes.Minimal : Themes.Standard;
  const showBody = dropdown ? (controlled ? opened : open) : true

  return ((body) => unwrapped ? body : <Item.Wrapper fullWidth={ fullWidth } standalone={ standalone }>{ body }</Item.Wrapper>)(
    <Item.Container id={id}>
      <Item.Header>
        {dropdown &&
          <Item.Toggle onClick={ toggle }>
            { showChildren && <UI.Icon type={ ( controlled ? opened : open ) ? 'minus' : 'plus' } /> }
          </Item.Toggle>
        }
        <Item.Heading.Container dropdown={ dropdown } onClick={ () => onClick ? onClick(reference) : toggle() }>
          <Item.Heading.Bookmark title={ title }>{ subtitle }</Item.Heading.Bookmark>
          <Item.Heading.Title title={ title }>
            <UI.Text.Rich inline singular content={ title } />
          </Item.Heading.Title>
        </Item.Heading.Container>
        <Item.Tools>
          { hideable && <UI.List.Visibility value={ value } onChange={ onChange } /> }
          { actions ? <UI.Dots actions={ actions } reference={ reference } />
                    : isValidElement(tools) ? tools : <Subschema>{ tools }</Subschema> }
        </Item.Tools>
      </Item.Header>
      {showChildren && showBody &&
        <Item.Body subtitle={ subtitle } open={ showBody }>
          { children ? children : <Contents /> }
        </Item.Body>
      }
    </Item.Container>
  );
};

Item.displayName = 'List.Item';

export default Item;

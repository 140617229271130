import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useAccessibleInteractivity } from '../../../hooks';
import Text from '../../OC/oc-text';

const ParentItem = styled.li`
  margin-bottom: 10px;
  font-weight: ${ props => props.selected ? '700' : '500' };

  a {
    color: ${ props => (props.selected) ? '#d40f7d' : '#333333' };
    text-decoration: none;

    ${ props => props.selected ? 'padding-left: 15px;' : '' }

    .hidden {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const ParentListItem = ({ count, disableBackArrow, lastInteractionType, name, onClick, selected, title, toFocusForAccessibility }) => {
  const linkRef = useRef();
  useAccessibleInteractivity(linkRef, onClick);

  useEffect(() => {
    if (lastInteractionType === 'keydown') {
      toFocusForAccessibility && toFocusForAccessibility.current && toFocusForAccessibility.current.focus();
    }
  }, [])

  return (
    <ParentItem key={`${ title }-${ name }`} selected={selected}>
      <a
        data-track={selected ? 'remove-filter' : 'filter'}
        data-track-filter-name={title}
        data-track-filter-option={name}
        ref={linkRef}
      >
        <i className={`icon icon-chevron-left ${ (selected || disableBackArrow) && 'hidden' }`} /> <span><Text content={ name } />{ typeof(count) !== 'undefined' ? <>&nbsp;{`(${ count })`}</> : null }</span>
      </a>
    </ParentItem>
  )
}

export default ParentListItem;

import styled from '@emotion/styled';
import { useState, useRef } from 'react';

import { FilterHeading } from './text-elements/filters';
import { H2 } from './text-elements/oc-h';
import { useAccessibleInteractivity } from '../../hooks';

const ContentGroupContainer = styled.div`
  border-top: 1px solid #000000;
  display: inline-block;
  padding-bottom: 2em;
  padding-top: 2em;
  position: relative;
  width: 100%;
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  position: relative;

  &:hover {
    cursor: ${props => props.collapsible ? 'pointer' : 'default'};
  }

  &:focus {
    outline: ${props => props.collapsible ? '#d40f7d solid 2px' : '0'};
    outline-offset: 0;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: ${props => props.collapsible ? '#d40f7d solid 2px' : '0'};
    outline-offset: 0;
  }
`;

const Chevron = styled.span`
  display: inline-block;
  position: relative;
  min-width: 1.125em;
  line-height: 1;
  min-height: 0.75em;

  &:before {
    content: "\\e608";
    font-family: "icomoon";
    font-size: 1.125em;
    left: 3px;
    line-height: 1;
    position: absolute;
    top: -3px;
    transform: ${props => props.collapsed ? 'rotate(90deg)' : 'rotate(-90deg)' };
  }
`;

const Content = styled.div`
  display: ${props => props.collapsed ? 'none' : 'inline-block'};
  padding-top: 1em;
  position: relative;
  width: 100%;
`;

const ContentGroupDropdown = ({collapsible=true, collapsed=false, labelSizeLg=false, label, children}) => {
  const contentDropdown = useRef();
  const [expanded, setExpanded] = useState(collapsed !== true);

  const toggleDropdown = () => setExpanded(!expanded);

  if (collapsible) {
    useAccessibleInteractivity(contentDropdown, toggleDropdown);
  }

  return (
    <ContentGroupContainer>
      <Label
        collapsible={collapsible}
        ref={contentDropdown}
        tabIndex={collapsible ? 0 : -1}
        role="button"
        aria-expanded={expanded ? 'true' : 'false'}
      >
        {labelSizeLg ? (
          <H2 font="body">{label}</H2>
        ) : (
          <FilterHeading>{label}</FilterHeading>
        )}

        {collapsible && <Chevron collapsed={expanded === false ? collapsed = true : null} />}
      </Label>

      <Content collapsed={expanded === false ? collapsed = true : null} >
        {children}
      </Content>
    </ContentGroupContainer>
  );
};

export default ContentGroupDropdown;

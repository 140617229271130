import Video from "./oc-video";
import Image from "./oc-image";
import CloudinaryVideo from "./oc-cloudinary-video";

const Media = ({img, alt, video, className }) => {
  if (!video) {
    return <Image src={img} alt={alt} className={className} />;
  }

  const videoType = video.indexOf('cloudinary') !== -1 ? 'cloudinary' : 'youtube';

  if (videoType === 'cloudinary') {
    return <CloudinaryVideo src={video} className={className} />
  } else {
    return <Video src={video} className={className} />
  }
}

export default Media;

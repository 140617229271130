import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Media from "./oc-media";
import PropTypes from 'prop-types';
import placeholder_url from '../../placeholder';


const ContentAndImageContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.contentOrderMobile == 'first' ? 'column' : 'column-reverse'};
  padding: 0 0 0;
  margin: ${props => props.noMargin ? '0 auto' : '0 auto 20px'};

  @media (min-width: 768px) {
    flex-direction: ${props => props.contentOrder == 'last' ? 'row-reverse' : 'row'};
    max-width: 768px;
    margin: ${props => props.noMargin ? '0 auto' : '0 auto 20px'};
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    max-width: 1170px;
    padding: 0 0 0;
    margin: ${props => props.noMargin ? '0 auto' : '0 auto 70px'};
  }
`;

const Content = styled.div`
  @media (max-width: 767px) {
    margin: ${props => props.contentOrderMobile === 'first' && '0 0 20px'};
  }

  @media (min-width: 768px) {
    margin-right: ${props => props.contentOrder === 'first' && '30px'};
  }

  ${props => props.ratio == '1-2' && css`
    @media (min-width: 768px) {
      flex: 1;
    }
  `};

  ${props => props.ratio == '1-3' && css`
    flex-basis: 25%;
  `};

  ${props => props.ratio == '1-1' && css`
    flex-basis: 50%;
  `};

  ${props => props.ratio == '2-1' && css`
    @media (min-width: 768px) {
      flex: 1;
    }
  `};

  ${props => props.ratio == '3-1' && css`
    flex-basis: 75%;
  `};
`;

const MediaContainer = styled.div`
  margin: ${props => props.contentOrderMobile === 'last' && '0 0 20px'};

  @media (min-width: 768px) {
    margin-right: ${props => props.contentOrder === 'last' && '30px'};
  }

  ${props => props.ratio == '1-2' && css`
    @media (min-width: 768px) {
      width: 482px;
    }

    @media (min-width: 1200px) {
      width: 770px;
    }
  `};

  ${props => props.ratio == '1-3' && css`
    flex-basis: 75%;
  `};

  ${props => props.ratio == '1-1' && css`
    flex-basis: 50%;
  `};

  ${props => props.ratio == '2-1' && css`
    @media (min-width: 768px) {
      width: 226px;
    }

    @media (min-width: 1200px) {
      width: 370px;
    }
  `};

  ${props => props.ratio == '3-1' && css`
    flex-basis: 25%;
  `};

  img, video {
    max-width: 100%;
  }

  .caption {
    margin-top: 5px;
    font-size: 12px;
  }
`;

// Placeholder images that display pixel dimensions
const imgPlaceholder = {
  "1-2": placeholder_url(770,400),
  "1-1": placeholder_url(570,320),
  "2-1": placeholder_url(370,400)
}

/*
* contentOrder - [first (default), last]
* contentOrderMobile - [first, last (default)]
*/
const ContentAndImage = ({ img, video, alt, children, contentOrder = 'first', contentOrderMobile = 'last', ratio = '1-2', noMargin, ...props }) => {
  let image = img ? img : imgPlaceholder[ratio];

  return (
    <ContentAndImageContainer noMargin={noMargin} contentOrder={contentOrder} contentOrderMobile={contentOrderMobile} >
      <Content ratio={ratio} contentOrder={contentOrder} contentOrderMobile={contentOrderMobile} >
        {children}
      </Content>
      <MediaContainer ratio={ratio} contentOrder={contentOrder} contentOrderMobile={contentOrderMobile} >
        <Media alt={alt} img={image} video={video} />
      </MediaContainer>
    </ContentAndImageContainer>
  )
}

export default ContentAndImage;

ContentAndImage.propTypes = {
  /** The image you want to use */
  img: PropTypes.string,
  /** The alt text for the image */
  alt: PropTypes.string,
  /** If you want to use a video rather than an image */
  video: PropTypes.string,
  /** This is where you put the content like heading and text */
  children: PropTypes.node,
  /** If you want the content before or after the image. It defaults to before. Options are first and last */
  contentOrder: PropTypes.string,
  /** If you want the content before or after the image on mobile. It defaults to after. Options are first and last */
  contentOrderMobile: PropTypes.string,
  /** the ratio of content to image. The default is 1-2. The options are 1-2, 1-3, 1-1, 2-1, 3-1 */
  ratio: PropTypes.string,
  /** set this to true to remove the bottom margin */
  noMargin: PropTypes.bool,
}



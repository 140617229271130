import { store } from '../../Store';
import { Provider } from "react-redux";
import { connectLocation } from '../../location/LocationConnectors';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Figure from '../../OC/oc-figure';
import Card from '../../OC/oc-card-new.jsx';
import CTAButton from '../../CTAButton';
import ColorPalette from './ColorPalette'
import SocialMediaShareBanner from '../../OC/oc-social-media-share-banner';
import RichText from '../../OC/PageBuilder/RichText';
import useReference from '../../../hooks/use_reference';
import Text from '../../OC/PageBuilder/utilities/Text';
import Picture from '../../OC/oc-picture';
import Grid from "../../OC/PageBuilder/Grid";
import Disclaimers from "../../OC/oc-disclaimers";
import ContentAndImage from '../../ComponentLibrary/oc-content-and-image';
import { H2 } from '../../OC/oc-h';
import Reusable from '../../OC/PageBuilder/Reusable';

const colorPairingCSS = css`
  .title {
    margin-bottom: 32px;
  }

  .other-shingle-colors {
    font-family: 'OCRoboto',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-transform: none !important;
  }

  .about-section {
    display: flex;
    margin-top: 64px;
  }
  @media (max-width: 1199px) and (min-width: 768px) {primary
    .shingle-img-container { height: 162px !important; }
    .columns.mobile-columns,
    .primary-color-vertical-container,
    .secondary-color-vertical-container {
      height: 100% !important;
    }
    .secondary-color-top-vertical-container, .secondary-color-middle-vertical-container { height: 30% !important; }
    .secondary-color-bottom-vertical-container { height: 31% !important; }
  }
  @media (max-width: 767px) {
    .shingle-img-container { height: 400px !important; }
    .columns.mobile-columns,
    .primary-color-vertical-container,
    .secondary-color-vertical-container {
      height: 100% !important;
    }
    .secondary-color-top-vertical-container, .secondary-color-middle-vertical-container,
    .secondary-color-bottom-vertical-container { height: 32% !important; }
    .tier3 {
      padding-top: 40px;
    }
    .about-section {
      flex-direction: column-reverse;
    }
  }
  .shingle-img {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .reference-guide-img-container {
    margin: 32px 0;
  }

  .reference-guide-info-container {
    padding-left: 16px;
  }

  .see-on-my-house-btn {
    margin-top: 16px;
  }

  .hr-line {
    display:  block;
    margin: 60px 0;
  }

  .explore-all-styles-btn {
    margin-bottom: 64px;
  }

  .other-pairings-container {
    margin: 0px;
  }

  .other-shingle-heigh {
    height: 100%;
  }

  .shingle-name-prehead{
    margin: 8px 0;
    > span {
      padding-right: 4px;
    }
  }

  .shingle-name-heading{
    margin-bottom: 0px;
  }
`

const FullSection = styled.section`
  margin-left: 0;
  margin-right: 0;
  max-width: none;
`;

const CTAContainer = styled.div`
  display: inline-block;
  padding-top: 1.5em;
  position: relative;
  width: 100%;
`;

function OtherShingleColor(props) {
  const shingleOSC = props?.shingle?.data;
  const shingleColorDataOSC = shingleOSC?.shingle_colors?.[0];
  return (
    <Card img={ shingleColorDataOSC?.swatch_768x768_url }
          alt={ shingleColorDataOSC?.swatch_alt_en }
          aspectRatio={{ selection: "1.0" }} >
      <p className="shingle-name-prehead"><Text content={ shingleOSC?.full_proper_name } /></p>
      <h3 className="h4 body-font"><Text content={ shingleColorDataOSC?.name } /></h3>
    </Card>
  )
}

const ConnectedColorPairing = connectLocation(({
  t,
  colorName,
  colorPalette,
  description,
  exampleImg,
  featured,
  file,
  heroImage,
  locale: { code: localeCode },
  otherPairings,
  referenceGuide,
  otherShingleColors,
  shingleColor,
  subtitle
}) => {
  const otherShingleColorsResolved = (otherShingleColors||[]).map(other => useReference(other?.color))
  const { results, error, loading } = useReference(shingleColor?.color);
  const shingle = results?.data;
  const shingleColorData = shingle?.shingle_colors?.[0];

  const filteredOtherPairings = [otherPairings?.one, otherPairings?.two].filter(x => x);

  const mappedOtherShingleColors = otherShingleColorsResolved.filter(({ results }) => results?.meta?.status === 'ok')

  return (
    <>
      <section css={colorPairingCSS}>
        <div>
          <span className="posthead"><Text content={subtitle} /></span>
          <h1 className="tier3 title"><Text content={`${colorName} & ${shingleColorData?.name}`} /></h1>
          <Figure
            className="mobile-only"
            size="xxl"
            img={heroImage?.mobile?.file}
            alt={heroImage?.mobile?.alt} />
          <Figure
            className="tablet-only"
            size="xxl"
            img={heroImage?.tablet?.file}
            alt={heroImage?.tablet?.alt} />
          <Figure
            className="desktop-only"
            size="full-bleed"
            img={heroImage?.desktop?.file}
            alt={heroImage?.desktop?.alt} />
        </div>
        {
          mappedOtherShingleColors?.length > 0 &&
            <div>
              <h2 className='other-shingle-colors'>{t('design_inspire.other_shingle_colors')}</h2>
              <Grid selectedSize="4">
                { mappedOtherShingleColors.map(({ results, i }) => <OtherShingleColor key={ i } shingle={ results } />) }
              </Grid>
            </div>
        }

        <SocialMediaShareBanner
          text={t('design_inspire.share_this_pairing')}
          mode="dark"
          facebook="true"
          linkedin="true"
          pinterest="true"
            shareMessage="Check out this awesome MoodBoard featuring @OCRoofingLLC #Duration Designer roofing shingles!"
            />

        <div className="columns ratio-1-1 about-section">
          <div className="col">
            <h1 className="tier3">{t('design_inspire.about_this_pairing')}</h1>
            <RichText content={description} />
            <div className="columns ratio-1-1">
              <div className="col">
                <div className="shingle-img-container">
                  <img
                    className="shingle-img"
                    src={shingleColorData?.swatch_768x768_url}
                    alt={shingleColorData?.swatch_alt_en} />
                </div>
                <p className="shingle-name-prehead"><Text content={shingle?.trudefinition ? 'TruDefinition® ' : null} /><Text content={shingle?.proper_name} /></p>
                <div className="col shingle-name-heading"><h2 className="h4 body-font"><Text content={shingleColorData?.name} /></h2></div>
              </div>
              <div className="col">
                <div className="shingle-img-container">
                  <ColorPalette colorPalette={colorPalette}
                                mode="vertical"
                                heading={t('design_inspire.color_palette')}
                                prehead={colorName}
                                height="270px"
                                tabletHeight="170px" />
                </div>
              </div>
            </div>
            {
              referenceGuide && file &&
                <div className="columns ratio-1-3 reference-guide-img-container">
                  <div className="col">
                    <img src={referenceGuide?.img_path}
                         alt={referenceGuide?.img_alt}
                         width="100%"/>
                  </div>
                  <div className="col reference-guide-container">
                    <h4>{t('design_inspire.color_palette_reference_guide')}</h4>
                    <p>{t('design_inspire.color_palette_learn_more')}</p>
                    <a target="_blank" href={file?.url} data-track="file-download" data-track-doc-type=""
                       data-track-file-name="summary" data-track-file-type="pdf">
                        <i className="fa fa-file-pdf-o" aria-hidden="true" />
                       <span> {t('design_inspire.shingle_reference_guide_pdf')}</span> 
                    </a>
                  </div>
                </div>
            }
            <CTAButton href={`/en-us/roofing/shingles/${shingle?.uid}?color=${shingleColorData?.uid}`}>{t('design_inspire.view_product_details')}</CTAButton>
            <br/>
            <CTAButton href="/en-us/roofing/roof-visualizer" className="oc-cta-button transparent oc-icon favorites see-on-my-house-btn">{t('design_inspire.see_it_on_my_house')}</CTAButton>
          </div>
          <div className="col">
            <Picture
              width="100%"
              transform={{
                height: 570,
                width: 570,
                crop: "fill",
                quality: 80
              }}
              source={exampleImg?.file}
              alt={exampleImg?.alt}
            />
          </div>
        </div>
        {
          filteredOtherPairings.length > 0 &&
          <>
            <hr className="hr-line"/>
            <div className="other-pairings-container">
              <h1 className="tier3">{t('design_inspire.other_color_pairings')}</h1>
              <p>{t('design_inspire.other_color_pairings_desc')}</p>
            </div>

            <Grid selectedSize="2">
              {
                filteredOtherPairings.map((other, i) => (
                  <Card key={i} img={other.img_path} alt={other.img_alt} url={other.url} analytics={{track: 'learn-more', destination: other.img_alt}}>
                    <h3 className="h4 body-font">{other.caption}</h3>
                  </Card>
                ))
              }
            </Grid>

            <CTAContainer>
              <CTAButton href="/en-us/roofing/design-inspire/colors-of-the-season">{t('design_inspire.explore_all_styleboards')}</CTAButton>
            </CTAContainer>

            <hr className="hr-line"/>

            <Reusable language={localeCode} name="Build Your Own Style Board" path="roofing" />
          </>
        }
      </section>

      <FullSection>
        <Reusable language={localeCode} name="Color Samples Promo Block" path="roofing" />
      </FullSection>

      <Reusable language={localeCode} name="Color Disclaimer" path="roofing" />
    </>
  )
});
const ColorPairing = (props) => (
  <Provider store={store}>
    <ConnectedColorPairing {...props} />
  </Provider>
);

export default ColorPairing;

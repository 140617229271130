import React from 'react';
import itemListResponse from './helpers/item_list_response';
import { ui, when, s, request, select } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';
import getExtension from '../../../OC/utilities/file-extension';
import DocumentResult from '../../../ComponentLibrary/filter-result-blocks/oc-sds-result-block';
import getFileSize from '../../../helpers/get_file_size';
import moment from 'moment';
import { connectLocation } from '../../../location/LocationConnectors';
import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import _ from 'lodash';
import qs from 'qs';


const FILETYPES = {
  '.pdf': 'PDF',
  '.doc': 'DOC',
  '.docx': 'DOC',
  '.xls': 'XLS',
  '.xlsx': 'XLS',
  '.zip': 'ZIP',
  'pdf': 'PDF',
  '.document': 'DOC',
}

const showFiltersFn = (showFilters, filters)=>{
  if(showFilters === 'false'){
    return null;
  }
  return filters;
}
const taxonomies = {
  document_category: 'Document Categories',
  product_category: 'Product Categories',
  businesses: 'Businesses',
  product_lines: 'Product Lines',
  language_iso_code: 'Languages'
}

const getEnabledFilters = (enabledFilters = [], filters = {}) => {
  if (!enabledFilters || _.isArray(enabledFilters)) return filters
  return enabledFilters?.enabledFilters?.map((x) => filters[x] && { [x]: filters[x] }).reduce((accumulator, item) => {
    return { ...accumulator, ...item };
  }, {});
}

const getFilteredAttr = (filterDataOpt = {}, filterSet) => {
  if (_.isEmpty(filterDataOpt) || !filterDataOpt) return filterSet

  const listWSelections = Object.keys(filterDataOpt).filter((x) => !_.isEmpty(filterDataOpt[x]))
  const store = listWSelections.reduce((acc, current) => {
    if (_.isEmpty(filterDataOpt[current])) return acc
    let obj = { ...filterSet[current] }
    obj.options = obj?.options?.filter((currOpt) => filterDataOpt[current].includes(currOpt?.value))
    return { ...acc, [current]: obj }
  }, {})

  return _.merge(_.omit(filterSet, listWSelections), store)
}

const getUrl = (source, publication_id, url) => (
  source == 'dms2' ? `https://www.owenscorning.com/dms/${encodeURIComponent(publication_id)}` : url
)

const getDocFormat = (source, publication_id, url, mime_type) => (
  getExtension(getUrl(source, publication_id, url)) || mime_type?.split("/")[1] || new Blob([getUrl(source, publication_id, url)])?.type
)
const getUniqArray = (array) => _.uniqBy(array, JSON.stringify)
const getGroupsValue = (fieldName, data = []) => {
  const res = data.reduce((result, item) => {
    result.push({
      value: item[fieldName] || 'undefined',
      displayName: item[fieldName] || 'undefined'
    });
    return result;
  }, []);
  return _.sortBy(res, obj => obj?.displayName)
};

const getArrayGroups = (name, data) => {
  const group = []
  data?.map((item) => {
    item[name]?.forEach((val) => {
      return group.push({
        value: val,
        displayName: val
      })
    })
  })
  return _.sortBy(group, obj => obj?.displayName)
}

const getFromPath = (Board, path) => {
  const selectedFilter = _.get(Board.Value, _.initial(path).concat(['enabledSectionFilters', 'enabledFilters']))
  const parameters = _.get(Board.Value, _.take(path, 11))

  return {
    selectedFilter,
    productCategory: parameters?.productCategory,
    queryHash: qs.stringify({ type: 'ItemList', dataset: 'document_set', parameters, language: Board.build.language, zone: "all" },
      { arrayFormat: 'brackets', encodeValuesOnly: true })
  }
}

const filterSets = (data) => ({
  document_category: {
    component: CheckboxSelector,
    displayName: 'Categories',
    options: getUniqArray(getGroupsValue('document_category', data)),
    valueType: 'singular',
    displayLimit: 5,
  },
  product_category: {
    component: CheckboxSelector,
    displayName: 'Product Categories',
    options: getUniqArray(getGroupsValue('product_category', data)),
    valueType: 'singular',
    displayLimit: 5,
  },
  businesses: {
    component: CheckboxSelector,
    displayName: 'Businesses',
    options: getUniqArray(getArrayGroups('businesses', data)),
    valueType: 'multiple',
    displayLimit: 5,
  },
  product_lines: {
    component: CheckboxSelector,
    displayName: 'Product Lines',
    options: getUniqArray(getArrayGroups('product_lines', data)),
    valueType: 'multiple',
    displayLimit: 5,
  },
  language_iso_code: {
    component: CheckboxSelector,
    displayName: 'Language',
    options: getUniqArray(getGroupsValue('language_iso_code', data)),
    valueType: 'singular',
    displayLimit: 5,
  },
})
const getFields = {
  document: ({
    short_description,
    keywords,
    title,
    source,
    url,
    publication_id,
    size_bytes,
    category,
    language_iso_code,
    manufacturer,
    region,
    sds_update_date,
    created_at,
    mime_type,
    dms_publish_date,
    document_category,
    dms_update_date,
    updated_at
  }, t) => (
    {
      name: title || short_description,
      postheading: short_description || keywords,
      docUrl: getUrl(source, publication_id, url),
      docName: `PUB# ${publication_id}`,
      docSize: getFileSize(size_bytes || new Blob([getUrl(source, publication_id, url)]).size),
      docFormat: FILETYPES?.[getDocFormat(source, publication_id, url, mime_type)] || '',
      docType: document_category,
      languageName: language_iso_code ? t(`languages.${language_iso_code}`) : '',
      language: language_iso_code ? t(`languages.${language_iso_code}`) : '',
      supplierName: manufacturer || '',
      supplier: manufacturer,
      regionName: region,
      region: region ? t(`sds.regions.${region}`) : '',
      date: moment(dms_update_date || dms_publish_date || updated_at || created_at).format("MM-DD-YYYY"),
      sds_update_date: sds_update_date || updated_at || created_at,
      document_type: document_category
    }
  )
}

const searchFields = [
  'document_category',
  'keywords',
  'manufacturer',
  'name',
  'product_category',
  'product_lines',
  'product_name',
  'publication_id',
  'short_description',
  'title',
  'value',
];

const FileComponent = connectLocation(({ t, document }) => <DocumentResult
  result={{ ...(getFields['document'](document, t)) }}
  style={{ borderBottom: 'none', width: '100%', padding: 0 }}
/>)


const ContentHeader = (props) => {
  const { count } = props
  return (
    <>
      {count >= 0 && <h2>
        <span> {count} </span> Document(s)
      </h2>
      }
    </>
  )
};

export default {
  ItemList: {
    availableIn: ['www.owenscorning.com'],
    name: 'Product Documents',
    meta: {
      productCategory: ui`Choices`.of({
        insulation: 'Insulation and Enclosure Solutions',
        roofing: 'Roofing Components and Roofing Shingles',
        composites: 'Composites',
      })({
        label: 'Choose documents for product types',
        default: 'roofing',
        mode: ui`Choices/Mode/Dropdown`
      }),
      [s._]: ui`Tip`.of('Option to add document sets per category')({
        visible: when`../productSource`.is.equal.to('filtered').then(true).otherwise(false),
      }),
      productSource: ui`Choices`.of({
        'all': 'All Items',
        'filtered': 'Filtered',
      })({
        label: 'Select Structure',
        default: 'all',
        mode: ui`Choices/Mode/Dropdown`
      }),
      showFilters: ui`Switch`({
        label: 'Filter Pane',
        default: true
      }),
      [s._]: ui`Tip`.of('If the item list you wish to display is to be very long, we recommend turning on the Filter Side Pane to allow users to filter down the results to their needs.'),
      enabledSectionFilters: ui`ChoicesDraggable`.of(taxonomies)({
        label: 'Filter Sections',
        visible: when`../productSource`.is.equal.to('filtered').then(true).otherwise(false),
        default: Object.keys(taxonomies),
      }),
      [s._]: ui`Tip`.of('changes to the above are global and will mirror in Insulation, Roofing and Composites')({
        visible: when`../productSource`.is.equal.to('filtered').then(true).otherwise(false),
      }),
      dataFilter: ui`List/Item`.of((x) => {
        const { selectedFilter, queryHash } = getFromPath(Board, x?.path)
        return (<Subschema>{[
          ui`Form`(_.assign(_.omit(x, ['Board', 'Contents', 'Subschema', 'UI', 'contents']))),
          request(`/api/v1/cms/sites/${PB_SITE}/data?${queryHash}`)
            .during({ disabled: true, placeholder: 'Loading options...' })
            .failure((error) => ({ disabled: true, placeholder: 'Error loading! Please try again' }))
            .success(({ data }) => (
              {
                contents: _.mapValues(_.pick(filterSets(data?.data?.results), selectedFilter ?? Object.keys(taxonomies)), (x) => {
                  const t = x?.options?.map((op) => op.value)
                  return ui`Choices`.of(x?.options?.map((y) => _.merge(y, { label: y?.displayName })))({
                    label: x?.displayName,
                    multiple: true,
                    mode: ui`Choices/Mode/Vertical`,
                    format: ui`Choices/Format/Array`,
                  })
                })
              }
            )),
        ]}</Subschema>)
      }
      )({
        standalone: true,
        title: 'Document Attributes',
        label: 'Filter Datas',
        visible: when`../productSource`.is.equal.to('filtered').then(true).otherwise(false),
      }),
      [s._]: ui`Tip`.of('Filter each product types per attributes')({
        visible: when`../productSource`.is.equal.to('filtered').then(true).otherwise(false),
      }),
    },
    view: (data = null) => {
      const applyFiltersfn = _.flowRight(
        _.partial(showFiltersFn, data?.meta?.parameters?.showFilters),
        _.partial(getEnabledFilters, data?.meta?.parameters?.enabledSectionFilters),
        _.partial(getFilteredAttr, data?.meta?.parameters?.dataFilter),
        filterSets
      )

      const applyAll = _.flowRight(
        _.partial(showFiltersFn, data?.meta?.parameters?.showFilters),
        filterSets
      )

      return itemListResponse(
        {
          contentHeader: { component: ContentHeader, props: data?.meta },
          items: data?.data?.results,
          filters: data?.meta?.parameters?.productSource === 'filtered'
            ? applyFiltersfn(data?.data?.results)
            : applyAll(data?.data?.results),
          Component: (props) => <FileComponent document={props} />,
          searchFields,
          enableSearch: true,
        }
    )

    }
  }
}

import _ from 'lodash';
import ProductItem from './helpers/ProductItem';
import ProductItemList from './helpers/ProductItemList';
import { connectLocation } from '../../../location/LocationConnectors';
import formatNumericRange from '../../../helpers/format_numeric_range';
import { useEffect, useState } from 'react';
import cms_api from '../../../../cms_api';
import retrieveImage from './helpers/retrieveImage';
import pathToContent from './helpers/path_to_content';

const getInsulatingMaterialsData = _.memoize(
  async () => {
    const response = await cms_api.get_single_for_language_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        route: '/insulation',
        name: 'Insulating Material',
      },
      fields: {
        '*': 'contents',
      },
    }, Board.build.language);
    return _.keyBy(response?.items, r => r.value);
  }
);

const ProductListItem = connectLocation(({ aspectRatio, locale: { code }, t, ...product }) => {
  const features = [];

  const r_value_min = _.get(product, 'metadata.settings.attributes.r_value_min');
  const r_value_max = _.get(product, 'metadata.settings.attributes.r_value_max');
  if (r_value_min || r_value_max) {
    features.push(t('product_list.item_feature.r_value_range', { range: formatNumericRange(r_value_min, r_value_max) }));
  }

  const thickness_min = _.get(product, 'metadata.settings.attributes.thickness_min');
  const thickness_max = _.get(product, 'metadata.settings.attributes.thickness_max');
  if (thickness_min || thickness_max) {
    features.push(t('product_list.item_feature.thickness_range', { range: formatNumericRange(thickness_min, thickness_max) }));
  }

  const category = _.get(product, 'metadata.settings.attributes.insulating_material[0]');
  const product_thumb = retrieveImage(product);
  const generalContent = product.metadata?.settings?.general;
  const teaser_copy = generalContent?.long_description || generalContent?.short_description;
  const product_url = product.metadata?.availability?.status === 'external' ? product.metadata?.availability?.externalUrl : pathToContent(product);

  const [lookup, setLookup] = useState({});
  useEffect(() => {
    getInsulatingMaterialsData().then(lookup => setLookup(lookup))
  }, [])

  if (!lookup) {
    return null;
  }

  return (
    <ProductItem
      aspectRatio={ aspectRatio }
      key={ product.uid }
      preheading={ lookup[category]?.label }
      heading={ generalContent?.proper_name }
      img={ product_thumb }
      text={ teaser_copy }
      url={ product_url }
      features={ features }
    />
  );
})

export default ProductItemList({
  common: {
    type: 'Product::Insulation'
  },
  search: {
    name: 'Insulation Products',
    preview: result => ({
      preheading: _.get(result, 'categories[0].proper_name'),
      heading: result.metadata?.settings?.general?.proper_name || result.name
    })
  },
  list: {
    availableIn: ['www.owenscorning.com'],
    name: 'Products - Insulation',
    attributeSet: {
      name: 'Insulation PDP Attributes',
      path: 'insulation'
    },
    dataset: 'products_insulation',
    view: ProductListItem,
    enableSearch: true,
  }
})

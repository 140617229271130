import { ui, select, transform, when, s, always } from '@owenscorning/pcb.alpha';
import { Global, css } from '@emotion/react';

import _ from 'lodash';
import Content from './Content';
import { useState, useEffect } from 'react';
import { expandRef, unwrapRef, wrapRef } from '../../../../data';
import useReference from '../../../../hooks/use_reference';
import { filterEntity, isItemVisible } from '../../../PageBuilder/helpers/content';
import units from '../../units';

const MAX_DEPTH = 5;

const attributeMeta = (depth) => ({
  type: ui`Choices`.of({
    choices: 'Choices',
    boolean: 'Toggle Switch',
    text: 'Text',
    text_rich: 'Rich Text',
    number: 'Number',
    form: 'Attribute Set',
    ...(depth > 0 ? { list: 'List' } : {})
  })({
    label: 'Attribute Type'
  }),
  choices: ui`Form`.of({
    component: always`Choices`,
    of: ui`Search`({
      startOpen: when`../of`.isnt.present.then(true).otherwise(false),
      label: 'Attribute',
      dataset: 'attributes',
      set: (value, path) => {
        // TODO: handle empty/null value?
        const ref = wrapRef('Cms::Content', { type: 'Attribute', id: value.content_uuid });
        return ref;
      },
      get: (value) => {
        const { results, error, loading } = useReference(value);
        if (loading) {
          return <i>Loading...</i>
        } else if (error) {
          return <span>{ error }</span>
        }
        return results;
      }
    }),
    parameters: ui`Form`.of({
      multiple: ui`Switch`({
        label: 'Allow Multiple Values'
      }),
      mode: ui`Choices`.of(_.invert(UI.Choices.Mode))({
        label: 'Mode',
        default: ui`Choices/Mode/Dropdown`
      }),
      defaultValue: ui`Choices`.of(`${MDMS_API_CDN_HOST_AND_PROTOCOL}/api/v2/cms/sites/${PB_SITE}/contents`)({
        responseHandler: data => {
          return data?.data?.[0]?.contents?.items || []
        },
        query: {
          filter: {
            type: 'Cms::Content::Attribute',
            language_iso_code: select`~build/language`,
            id: transform`../../of`(of => {
              if (of) {
                const [type, value] = unwrapRef(of);
                return value.id;
              } else {
                return -1;
              }
            }),
            published: true,
          },
          fields: {
            '*': 'contents'
          },
          page: {
            number: 1,
            size: 1,
          }
        },
        label: 'Default',
        mode: ui`Choices/Mode/Dropdown`,
        multiple: select`../multiple`
      })
    }),
  })({
    label: 'Choices',
    visible: when`../type`.is.equal.to('choices')
  }),
  boolean: ui`Form`.of({
    component: always`Switch`,
    parameters: ui`Form`.of({
      defaultValue: ui`Switch`({
        label: 'Default'
      }),
    })
  })({
    label: 'Toggle Switch',
    visible: when`../type`.is.equal.to('boolean')
  }),
  text: ui`Form`.of({
    component: always`Text`,
    parameters: ui`Form`.of({
      multiline: ui`Switch`({
        label: 'Multiline'
      }),
      maxLength: ui`Number`({
        label: 'Maximum Length'
      }),
      placeholder: ui`Text`({
        label: 'Placeholder'
      }),
      defaultValue: ui`Text`({
        label: 'Default'
      }),
    })
  })({
    label: 'Text',
    visible: when`../type`.is.equal.to('text')
  }),
  text_rich: ui`Form`.of({
    component: always`Text/Rich`,
    parameters: ui`Form`.of({
      maxLength: ui`Number`({
        label: 'Maximum Length'
      }),
      placeholder: ui`Text`({
        label: 'Placeholder'
      }),
      defaultValue: ui`Text`({
        label: 'Default'
      }),
    })
  })({
    label: 'Rich Text',
    visible: when`../type`.is.equal.to('text_rich')
  }),
  number: ui`Form`.of({
    component: always`Number`,
    parameters: ui`Form`.of({
      min: ui`Number`({
        label: 'Minimum'
      }),
      max: ui`Number`({
        label: 'Maximum'
      }),
      step: ui`Number`({
        label: 'Step'
      }),
      precision: ui`Number`({
        label: 'Precision'
      }),
      placeholder: ui`Text`({
        label: 'Placeholder'
      }),
      // see https://github.com/datasets/unece-units-of-measure/blob/master/data/units-of-measure.csv
      // would like to have this in a usable dataset/ws here
      unit: ui`Choices`.of(_.mapValues(units, 'name'))({
        label: 'Unit',
        mode: ui`Choices/Mode/Dropdown`
      }),
      defaultValue: ui`Text`({
        label: 'Default'
      }),
    })
  })({
    label: 'Number',
    visible: when`../type`.is.equal.to('number')
  }),
  form: ui`Form`.of({
    component: always`Form`,
    of: ui`Search`({
      startOpen: when`../of`.isnt.present.then(true).otherwise(false),
      label: 'Attribute Set',
      dataset: 'attribute_sets',
      set: (value, path) => {
        // TODO: handle empty/null value?
        const ref = wrapRef('Cms::Content', { type: 'AttributeSet', id: value.content_uuid });
        return ref;
      },
      get: (value) => {
        const { results, error, loading } = useReference(value);
        if (loading) {
          return <i>Loading...</i>
        } else if (error) {
          return <span>{ error }</span>
        }
        return results;
      }
    }),
  })({
    label: 'Attribute Set',
    visible: when`../type`.is.equal.to('form')
  }),
  ...(depth > 0 ? {
    list: ui`Form`.of({
      component: always`List`,
      parameters: ui`Form`.of({
        min: ui`Number`({
          label: 'Min',
          min: 0,
          step: 1,
        }),
        max: ui`Number`({
          label: 'Max',
          min: 1,
          step: 1,
        }),
        singular: ui`Text`({
          label: 'Singular Item Name'
        }),
        title: ui`Text`({
          label: 'Title Field'
        }),
      }),
      of: ui`Form`.of(attributeMeta(depth-1)),
    })({
      label: 'List',
      visible: when`../type`.is.equal.to('list')
    })
  } : {})
})

// as of this writing only Roofing PDP Attributes has this (for vent nfvas)
const migrateListOfs = (object = {}) => {
  const { items = [] } = object;
  return {
    items: items.map(i => migrateListOf(i))
  }
}

const migrateListOf = (i) => {
  if (i.type === 'list' && i.list.of?.__ref) {
    const { list: { of, ...rest } } = i
    return {
      ...i,
      list: {
        ...rest,
        of: {
          type: 'form',
          form: { of }
        }
      }
    }
  } else {
    return i
  }
}

const AttributeSet = Content(
  'AttributeSet',
  (UI) => ({
    version: '0.1',
    displayName: 'Attribute Set',
    usage: 'name',
    read: ({ contents, metadata }) => {
      return { contents: migrateListOfs(contents), metadata }
    },
    write: ({ contents, metadata }) => {
      const tokens = {};
      const readAndGenerateTokens = (list, prefix) => {
        list.forEach((item, index) => {
          if (!item.field) {
            // generate a token
            throw `Attribute ${index + 1} missing Field Name`
            //item.field = [prefix, _.snakeCase(item.label)].filter(_.identity).join('/')
          }
          const originalItemToken = item.field;
          let suffix = 1;
          if (tokens[item.field]) {
          //while (tokens[item.field]) {
            // fix collision
            throw `Attribute ${index + 1} Field Name not unique`
            //item.field = `${ originalItemToken }-${ suffix++ }`;
          }
          tokens[item.field] = item.field;
        })
      }
      readAndGenerateTokens((contents?.items||[]), null)
      return { contents, metadata }
    },
    sidebar: {
      builder: ui`Form`.of({
        items: ui`List`.of(ui`Form`.of({
          field: ui`Text`({
            label: 'Content Field Name',
            pattern: '[a-z]+(?:_[a-z]+)*'
          }),
          [s._]: ui`Tip`.of('Field Name must be unique across all items across all attributes.<br/>' +
            'It will be assigned in content data and used for filtering, so changing it will mean ' +
            're-assigning content items.<br/>'),
          label: ui`Text`({
            label: 'Attribute Label',
            controlled: 'value',
          }),
          sublabel: ui`Text`({
            label: 'Attribute Sub-label',
          }),
          ...attributeMeta(MAX_DEPTH)
        }))({
          label: 'Attributes',
          singular: 'Attribute',
          title: 'field',
        })
      })
    },
    view: AttributeSet.Renderer(UI),
  })
);

const resolveOf = async (i) => {
  const { type, ...rest } = i;
  if (i[type].of) {
    if (type === 'list') {
      i = migrateListOf(i)
      i[type].ofLabel = i.label;
      i[type].of = await resolveOf(i[type].of)
    } else {
      i[type].of = (await expandRef(i[type].of))?.contents;
      if (type === 'form') {
        i[type].ofLabel = i.label;
        i[type].of = await deepResolveOfs(i[type].of?.items);
      } else if (type === 'choices') {
        i[type].ofLabel = i.label;
        i[type].ofPlural = i[type].of?.plural;
        i[type].ofSingular = i[type].of?.singular;
        i[type].of = i[type].of?.items;
      } else {
        throw new Error("Unknown component with 'of'")
      }
    }
    return i;
  } else {
    return Promise.resolve(i)
  }
}

export const deepResolveOfs = (items) => {
  return Promise.all((items||[]).filter(i => i.type && i[i.type]).map(async (i) => {
    return await resolveOf(i)
  }))
}

export const constructField = (filter) => {
  let component = ui(filter[filter.type].component);
  const { defaultValue, maxLength, ...parameters } = filter[filter.type]?.parameters || {};
  if (filter.type === 'choices') {
    component = component.of(filter[filter.type].of)
  }
  if (filter.type === 'list') {
    const { type } = filter[filter.type].of || {};
    if (typeof(type) === 'undefined') {
      return component;
    }
    if (type === 'form') {
      component = component.of(Object.fromEntries((filter[filter.type].of[type].of || []).map(filter => ([filter.field, constructField(filter)]))))
    } else {
      component = component.of(ui`Form`.of({ value: constructField(filter[filter.type].of) }))
      if (parameters) {
        parameters.title = parameters.title || 'value';
      }
    }
  }
  if (filter.type === 'form') {
    component = component.of(Object.fromEntries((filter[filter.type].of || []).map(filter => ([filter.field, constructField(filter)]))))
  }

  if (maxLength) {
    parameters.flags = { Right: [`${maxLength} character limit`] }
  }

  component = component({
    label: filter.label,
    sublabel: filter.sublabel,
    default: defaultValue,
    maxLength: maxLength,
    ...parameters
  })

  if (filter.type === 'form') {
    // by default we wrap a form in a list item to wrap it up
    component = ui`List/Item`.of(component)({
      title: filter.label,
      unwrapped: true
    })
  }

  return component;
}

AttributeSet.Renderer = (UI, parameters={}) => ({
  contents: ({ value = {} }) => {
    const [filterState, setFilterState] = useState({});
    const { items, __testData } = filterEntity(value, isItemVisible);

    useEffect(() => {
      deepResolveOfs(_.cloneDeep(items)).then((filters) => {
        setFilterState(
          Object.fromEntries(
            filters.map(filter =>
              ([
                filter.field || _.snakeCase(filter.label),
                constructField(filter)
              ])
            )
          )
        )
      })
    }, [JSON.stringify(items)])

    return (
      <div>
        <Global styles={css`
          body {
            background-color: #f8f8f8
          }
          `} />
        <br />
        <div style={{ width: '300px', justifyContent: 'center', backgroundColor: 'white', padding: '8px' }}>
          <Subschema>{{
            __testData: ui`Form`.of(filterState),
          }}</Subschema>
        </div>
      </div>
    )
  }
});

export default AttributeSet;

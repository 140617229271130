import _ from 'lodash';
import React, { useEffect } from 'react';

import Tabs from './Tabs';
import { fetchLocations, fetchDistance, MAX_DISTANCE } from './api';
import LocatorContext from './context';
import { getFilterComponents } from './filters';
import { LOCATORS } from './locators';
import { SUCCESS, LOADING, NOT_STARTED } from '../../../../hooks/use_mdms_api';
import useValidZip from '../../../../hooks/use_valid_zip';
import { connectURLSearchParams } from '../../../../redux/connectors/URLSearchParamConnector';
import { connectLocation } from '../../../location/LocationConnectors';

export const NO_NEARBY_LOCATIONS = 'NO_NEARBY_LOCATIONS';
export const CANNOT_LOCALIZE = 'CANNOT_LOCALIZE';
export const INVALID_ZIP = 'INVALID_ZIP';

const makeLists = (groups, locations = [], groupCounts = {}) => {
  const groupHeaderInfos = groups.map(
    ({ groupId, title, ...groupHeaderInfo }) => ({
      name: title,
      groupId,
      headerData: {
        label: title,
        badge: groupHeaderInfo.thumbnail?.file,
        alt: groupHeaderInfo.thumbnail?.alt,
        ctaLabel: groupHeaderInfo.hasLearnMore && 'Learn More', // TODO translate "learn more"
        text: groupHeaderInfo.hasLearnMore && groupHeaderInfo.learnMoreContent,
        hideHeading: groupHeaderInfo.hideHeading,
        collapsible: groupHeaderInfo.hasLearnMore,
      },
    })
  );
  const lists = _.filter(groupHeaderInfos, Boolean);
  lists.forEach((list, index) => {
    lists[index].data = locations.filter(
      location =>
        list.groupId === 'all' ||
        groupCounts[list.groupId]?.includes(location.id)
    );
    // set 'type' so the heirarch filter works out of the box
    lists[index].data.forEach((_location, iindex) => {
      const location = _.clone(lists[index].data[iindex]);
      location.location_type = list.groupId;
      lists[index].data[iindex] = location;
    });
  });

  return lists;
};

const getDistanceBreakPoints = (distanceBreakpointObjects, urlDistance) =>
  _.uniq(
    distanceBreakpointObjects
      ?.map(bp => bp.distance)
      .concat(urlDistance)
      .filter(Boolean)
      .map(Number)
      .sort((a, b) => a - b)
      .map(String)
  );

const getStatus = ({
  validZipStatus,
  distanceStatus,
  locationStatus,
  valid,
  distance,
  locations,
}) => {
  let error = null;
  const status = // all success? => success, else loading
    validZipStatus === SUCCESS &&
    distanceStatus === SUCCESS &&
    locationStatus === SUCCESS
      ? SUCCESS
      : LOADING;

  // all success, but no locations? => no nearby locations
  if (status === SUCCESS && distance === MAX_DISTANCE && !locations?.length) {
    error = NO_NEARBY_LOCATIONS;
  }
  // zip (lat/lng) lookup won't begin unless we have a zip and a locale
  if (validZipStatus === NOT_STARTED) {
    error = CANNOT_LOCALIZE;
  }
  if (!valid && validZipStatus === SUCCESS) {
    error = INVALID_ZIP;
  }
  return [status, error];
};

const adjustDistanceParam = ({
  urlParams,
  setUrlParam,
  minDistance,
  distanceBreakPoints = [],
}) => {
  let paramAdjustment = false;
  const distance = urlParams?.distance || distanceBreakPoints[0];
  const find_nearest = urlParams?.find_nearest;

  if (urlParams && minDistance && distanceBreakPoints.length) {
    const lastDistanceBreakPoint =
      distanceBreakPoints[distanceBreakPoints.length - 1];

    if (distance < minDistance) {
      if (find_nearest) {
        paramAdjustment = () => {
          setUrlParam('find_nearest', null);
          setUrlParam('distance', Math.ceil(minDistance));
        };
      } else {
        // auto-expand up to max breakpoint
        const nextLargestBreakPoint = distanceBreakPoints.filter(
          bp => bp >= minDistance
        )[0];
        if (nextLargestBreakPoint) {
          // expand to breakpoint just above minDistance
          paramAdjustment = () => {
            setUrlParam('distance', nextLargestBreakPoint);
          };
        } else if (distance !== lastDistanceBreakPoint) {
          paramAdjustment = () => {
            setUrlParam('distance', lastDistanceBreakPoint);
          };
        }
      }
    }
  }

  useEffect(() => {
    if (paramAdjustment) {
      paramAdjustment();
    }
  }, [distance, find_nearest, minDistance]);

  return paramAdjustment ? null : distance;
};

const LocatorProvider = connectURLSearchParams(
  connectLocation(props => {
    // props,connects
    // PB props
    const {
      locatorUid,
      defaultView,
      noResultsContent,
      groups,
      filterInformation,
      resultsHeading,
      showLogo,
      underFilterContent = {},
    } = props;
    const locatorData = LOCATORS[locatorUid];
    if (!locatorData) {
      throw `no locator definition found for locatorUid = ${locatorUid}`;
    }
    const { resultComponentOptions = {} } = locatorData;
    const tabs = Tabs(defaultView);

    // location props
    const {
      zip,
      locale: { code },
      t,
    } = props;

    // url param props
    const { urlParams, setUrlParam, setUrlParams } = props;
    const {
      distanceBreakpoints: distanceBreakpointObjects,
    } = filterInformation;

    // sort them, unique them, and make them strings
    const distanceBreakPoints = getDistanceBreakPoints(
      distanceBreakpointObjects,
      urlParams?.distance
    );

    // fetch zip code once we have zip and c_code
    const [
      validZipStatus,
      { valid, city, region, latitude: lat, longitude: lng },
    ] = useValidZip(zip, code);
    const userLocation = lat && lng && [Number(lat), Number(lng)];
    const units = _.last(code.split('-')) === 'US' ? 'mi' : 'km';

    // fetch location min distance after zip resolves to a lat/lng
    const [distanceStatus, distanceData] = fetchDistance(
      {
        uid: locatorData.uid,
        lat,
        lng,
        units,
      },
      userLocation
    ); // TODO pass filters to this and fetchLocations, HANDLE 404

    const minDistance = distanceData?.radius;
    const distance = adjustDistanceParam({
      urlParams,
      setUrlParam,
      minDistance,
      distanceBreakPoints,
    });

    // fetch locations when any of these params change, after distance is set
    const [locationStatus, locationData] = fetchLocations(
      { uid: locatorData.uid, lat, lng, distance, units },
      distance && userLocation && distanceStatus === SUCCESS
    );

    const {
      meta: { groups: groupCounts },
      data: locations,
    } = locationData || { meta: { groups: {} }, data: [] };

    const lists = makeLists(groups, locations, groupCounts);
    const [status, error] = getStatus({
      validZipStatus,
      distanceStatus,
      locationStatus,
      valid,
      distance,
      locations,
    });

    // dirty hack to clear out url params when a user changes zip codes
    // can't easily tie into zip code change event
    // because it kicks off a navigate event before we can respond and clear the params
    // intercepting this way seems to work. It's a one-off case anyway..
    // how often does a user change zip codes?
    useEffect(() => {
      if (status === SUCCESS) {
        const navigation = window?.navigation;
        if (navigation) {
          navigation.addEventListener('navigate', event => {
            if (event.navigationType !== 'replace') {
              const url = new URL(event.destination.url);
              url.search = '';
              event.intercept({
                handler() {
                  navigation.navigate(url);
                },
              });
            }
          });
        }
      }
    }, [status]);

    const filters = getFilterComponents(filterInformation, distanceBreakPoints, units);
    const features = props?.features?.features; // it's nested feature.feature. not ideal but its fine

    const value = {
      locatorUid,
      defaultView,
      locatorData,
      noResultsContent,
      resultsHeading,
      status,
      error,
      features,
      resultComponentOptions,
      filterInformation,
      filters,
      zip,
      code,
      t,
      urlParams,
      setUrlParam,
      setUrlParams,
      distance,
      valid,
      city,
      region,
      groups,
      lists,
      userLocation,
      units,
      tabs,
      showLogo,
      underFilterContent,
    };

    const Wrapper = locatorData.wrapperComponent || React.Fragment;
    return (
      <LocatorContext.Provider value={value}>
        <Wrapper>{props.children}</Wrapper>
      </LocatorContext.Provider>
    );
  })
);

export default LocatorProvider;

import { Component } from "react";

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import { get, merge } from 'lodash';

import getYouTubeID from 'get-youtube-id';

import { connectLocation } from '../location/LocationConnectors';

import IconExclamationTriangle from './icons/icon-exclamation-triangle';
import IconSpinner from './icons/icon-spinner';
import Animations from './oc-animations.jsx';
import Query from 'qs';

const STYLES = {
  CONTAINER: {
    BASE: css`
      width: 100%;
      position: relative;
      transition: opacity 0.4s;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
        background-color: #E1E1E1;
      }
    `,
    LOADING: css`
      iframe {
        pointer-events: none;
        opacity: 0.4;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -8px;
        margin-top: -8px;
        z-index: 10;
        animation: ${ Animations.spin } 1s linear infinite;
      }
    `
  },
  FRAME: css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  WARNING: css`
    background-color: #FFF1E5;
    width: 100%;
    padding: 12px 16px;
    margin-top: 8px;
    font-size: 15px;
    line-height: 20px;
    color: #7E3900;
  `
};

class Video extends Component {
  constructor(props) {
    super(props);

    let parts = props.src.split('?');

    this.state = {
      identifier: parts[0].includes('youtube.com') ? getYouTubeID(parts[0]) : parts[0],
      parameters: props.parameters ? props.parameters : Query.parse(parts[1], {ignoreQueryPrefix: true}),
      meta: { loading: true, data: false }
    };

    this.loadMeta = this.loadMeta.bind(this);
    this.loadError = this.loadError.bind(this);
  }

  componentDidMount() {
    this.loadMeta();
  }

  loadMeta() {
    this.setState({ meta: { loading: true, data: false } });

    fetch(`/${this.props.locale?.code?.toLowerCase?.() || 'en-us'}/video/metadata/${ this.state.identifier }`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json().then(reply => {
            this.setState({
              meta: {
                loading: false,
                data: reply
              }
            })
          });
        } else {
          this.loadError(response);
        }
      })
      .catch(exception => {
        this.loadError(exception);
      });
  }

  loadError(message) {
    console.error("[VIDEO META] Error loading video meta:", message);
    this.setState({ meta: { loading: false, data: false } });
  }

  render() {
    let { src, t, className, css, ...props } = this.props;
    let divProps = { className, css };
    return (
      <div {...divProps} >
        <div css={ [ STYLES.CONTAINER.BASE, this.state.meta.loading ? STYLES.CONTAINER.LOADING : null ] }>
          { this.state.meta.loading ? <IconSpinner width="15px" /> : null }
          <iframe
            css={ STYLES.FRAME }
            src={ `https://www.youtube.com/embed/${ this.state.identifier }?${ Query.stringify(this.state.parameters) }` }
            title={ get(this.state.meta.data, 'title', 'loading video data...') }
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            data-track="view-video"
          ></iframe>
        </div>
        {
          !this.state.meta.loading && get(this.state.meta.data, 'seizure_warning', true) ?
            <div css={ STYLES.WARNING } >
              <b><IconExclamationTriangle fill="#FFF1E5" width="15px" /> { t('owenscorning.components.video.warning.preface') }</b> { t('owenscorning.components.video.warning.copy') }
            </div>
          : null
        }
      </div>
    );
  }
}

export default connectLocation(Video);
